// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import sortdown from 'assets/icons/sort-down.png';
import sortup from 'assets/icons/sort-up.png';
import cx from "classnames";
import Accordion from "components/Accordion/Accordion.jsx";
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Pagination from "components/Pagination/Pagination.jsx";
import strings from 'constants/strings';
import moment from "moment";
import React from "react";
import Gallery from 'react-grid-gallery';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import timelineStyle from "./TimelineHistoryStyle.jsx";
import TrackingMap from './TrackingMap';

class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stories: [],
      pages: [],
      fullStories: [],
      totalPages: 10,
      pageActive: 1,
      video: "",
      color: "success",
      videoStatus: false,
      dataTracking: [],
      trackingState: [],
      showMapState: 0
    }
    this.onClickTx = this.onClickTx.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleVideo = this.handleVideo.bind(this);
  }
  async  handleChange(page) {
    if (page !== "Mùa vụ") {
      const tempPages = this.state.pages;
      tempPages[page].active = true;
      tempPages[this.state.pageActive].active = false;
      await this.setState({
        pages: tempPages,
        pageActive: page,
        stories: this.state.fullStories[page - 1].reverse()
      })
    }
  }
  handleChangeNext() {

  }
  handleChangePrev() {

  }
  componentDidMount() {
    console.log(this.props.diary)
    if (this.props.diary) {
      if (this.props.diary.stories !== 'loading' &&  this.props.diary.stories.length > 0) {
        
        this.setState({
          stories: this.props.diary.stories[0].reverse(),
          fullStories: this.props.diary.stories,
          pages: this.props.diary.pages,
          video: this.props.diary.video,
          dataTracking: this.props.diary.tracking ? this.props.diary.tracking.length > 0 ? this.props.diary.tracking : [] : [],
          trackingState: this.props.diary.tracking ? this.props.diary.tracking.length > 0 ? this.props.diary.tracking[0] : [] : [],
        })
      }
    }


    if (this.props.overview) {
      if(this.props.overview.data){
        if(this.props.overview.data.setting){
          this.setState({
            showMapState: this.props.overview.data.setting.showMapState || 0
          })
        }
      }
    }

  }
  componentWillReceiveProps(preProps) {
    if (preProps.diary !== this.props.diary) {
      if (preProps.diary.stories.length > 0) {
        
        this.setState({
          stories: preProps.diary.stories[0].reverse(),
          fullStories: preProps.diary.stories,
          pages: preProps.diary.pages,
          video: preProps.diary.video,
          dataTracking: preProps.diary.tracking.length > 0 ? preProps.diary.tracking : [],
          trackingState: preProps.diary.tracking.length > 0 ? preProps.diary.tracking[0] : [],
        })
      }
    }
  }

  onClickTx = (tx) => {
    this.props.onConfirm(tx);
  };
  handleVideo = () => {
    if (this.state.videoStatus === false) {
      document.getElementById('videocontent').style = 'display: none';
      document.getElementById('imagevideo').src = sortdown;
      this.setState({
        videoStatus: true
      })
    } else {
      document.getElementById('videocontent').style = 'display: flex; justify-content: center';
      document.getElementById('imagevideo').src = sortup;
      this.setState({
        videoStatus: false
      })
    }
  };


  render() {
    const { classes, simple } = this.props;
    const timelineClass =
      classes.timeline +
      " " +
      cx({
        [classes.timelineSimple]: simple
      });
    

    
    return (
      <div className="flexvideo">
        {(this.state.trackingState.length > 0 && this.state.showMapState === 1)? 
      <div className="flexcenter">
      <GridItem xs={12} sm={12} md={10} >
        <Accordion
          video={true}
          collapses={[
            {
              title: strings.map_trace,
              content: (
                <div id="map" style={{width: "100%"}}>
                <GridItem xs={12} sm={12} md={12} style={{ display: 'block' }}>
                  	<TrackingMap stories={this.state.trackingState} />
                </GridItem>
                </div>
              )
            }
          ]}
        />
      </GridItem>
    </div>
        :null}
        {this.state.video ? (
          <div className="flexcenter">
            <GridItem xs={12} sm={12} md={10} >
              <Accordion
                video={true}
                collapses={[
                  {
                    title: strings.production_process,
                    content: (
                      <GridItem xs={12} sm={12} md={12} className="flexcenter">
                        <ReactPlayer url={this.state.video} controls={true} playing={true} light={true}/>
                      </GridItem>
                    )
                  }
                ]}
              />
            </GridItem>
          </div>
        ) : null}
        <ul className={timelineClass}>
          <div className="flexcenter">
            {this.state.stories.length > 0 ? <GridItem xs={12} sm={12} md={10}>
              {/* <Typography className={classes.xuatxutag}>{strings.tracerbility_journey}</Typography>
              <hr className="LineBreakDes"></hr> */}
            </GridItem> : null}
          </div>
          {this.state.stories.map((prop, key) => {
           
            const timelineBadgeClasses =
              classes.timelineBadge +
              " " +
              classes[prop.badgeColor] +
              " " +
              cx({
                [classes.timelineSimpleBadge]: simple
              });
            return (
              <li className={classes.item} key={key}>
                {prop.badgeIcon ? (
                  <div className={timelineBadgeClasses}>
                    <img alt="icon"className={classes.badgeIcon} src={require("../../../../assets/icons/" + prop.badgeIcon)} />
                  </div>
                ) : null}
                <GridItem xs={12} sm={12} md={10} className="flexend">
                  <GridItem xs={11} sm={9} md={11} className={classes.noPaddingContent}>
                    {prop.title ? (
                      <div className={classes.timelineHeading}>
                        <p className={classes.textAction}>{prop.title}</p>
                      </div>
                    ) : null}
                    {prop.description ? <div className={classes.timelineBody}>{prop.description}</div> : null}
                    {prop.defaultImage ? prop.defaultImage.length > 0 ?
                      <GridContainer className="diarycontentdetail">
                      <p className="tagmanufact">{strings.images}: </p>
                      <div style={{ color: 'red' }}>
                        <Gallery style={{ display: 'flex' }} images={prop.defaultImage} rowHeight={70} margin={3} />
                      </div>
                    </GridContainer> :null
                    :null}
                    <hr className="LineBreakDes" style={{ display: 'flex' }}></hr>
                    {prop.firstElement.when ?
                      <GridContainer className="diarycontent">
                        <p className="tagmanufact">{strings.when}: </p>
                        <p className="tagmanufactdes">{moment(prop.firstElement.when).format("DD/MM/YYYY HH:mm:ss")}</p>
                      </GridContainer>
                      : null}
                    <GridContainer className="diarycontent">
                      <p className="tagmanufact">{strings.who}: </p>
                      <p className="tagmanufactdes">{prop.firstElement.who}</p>
                    </GridContainer>
                    {prop.firstElement.step_attribute ? (
                      <GridContainer className="diarycontentdetail">
                        <p className="tagmanufact">{strings.details}: </p>
                        {prop.firstElement.step_attribute.split('\n').map((item, i) => {
                          return <p className="tagmanufactdesdetail">{item}</p>;
                        })}
                      </GridContainer>
                    ) : (
                        null
                      )}
                    {(prop.firstElement.image && prop.firstElement.image.length !== 0) ? (
                      <GridContainer className="diarycontentdetail">
                        <p className="tagmanufact">{strings.images}: </p>
                        <div style={{ color: 'red' }}>
                          <Gallery style={{ display: 'flex' }} images={prop.firstElement.image} rowHeight={70} margin={3} />
                        </div>
                      </GridContainer>
                    ) : (
                        null
                      )}
                    <GridContainer style={{ marginLeft: '0px', display: "flex", flexFlow: "column" }}>
                      <p className="tagmanufact">{strings.proof}:</p>
                      <p className="tagmanufactdestimeline">
                        <a onClick={() => this.onClickTx(prop.firstElement.txID)} href="# ">
                          {prop.firstElement.txID}
                        </a>
                      </p>
                    </GridContainer>
                    {prop.listElement.length > 0 ? (
                      <Accordion
                        active={0}
                        collapses={[
                          {
                            title: strings.view_detail,
                            content: (
                              <div style={{ width: "100%" }}>
                                {prop.listElement.map((element, index) => (
                                  <div>
                                    {element.when ? 
                                     <GridContainer className="diarycontent">
                                     <p className="tagmanufact">{strings.when}: </p>
                                     <p className="tagmanufactdes">{moment(element.when).format("DD/MM/YYYY HH:mm:ss")}</p>
                                   </GridContainer> : null}
                                   
                                    <GridContainer className="diarycontent">
                                      <p className="tagmanufact">{strings.who}: </p>
                                      <p className="tagmanufactdes">{element.who}</p>
                                    </GridContainer>
                                    {element.step_attribute ? (
                                      <GridContainer className="diarycontentdetail">
                                        <p className="tagmanufact">{strings.details}: </p>
                                        {element.step_attribute.split('\n').map((itemx, i) => {
                                          return <p className="tagmanufactdesdetail">{itemx}</p>;
                                        })}
                                      </GridContainer>
                                    ) : (
                                        <div></div>
                                      )}
                                    {(element.image && element.image.length !== 0) ? (
                                      <GridContainer className="diarycontentdetail">
                                        <p className="tagmanufact">{strings.images}: </p>
                                        <div style={{ color: 'red' }}>
                                          <Gallery style={{ display: 'flex' }} images={element.image} rowHeight={70} margin={3} />
                                        </div>
                                      </GridContainer>
                                    ) : (
                                        <div></div>
                                      )}
                                    <GridContainer style={{ marginLeft: '0px', display: "flex", flexFlow: "column" }}>
                                      <p className="tagmanufact">{strings.proof}:</p>
                                      <p className="tagmanufactdestimeline">
                                        <a onClick={() => this.onClickTx(element.txID)} href="# ">
                                          {element.txID}
                                        </a>
                                      </p>
                                    </GridContainer>
                                    <hr className={classes.linebreak} />
                                  </div>

                                ))}
                                
                                {/* <Button size="sm" color="success" onClick={()=>this.collap(key)}> <Up />Thu gọn</Button> */}
                              </div>
                            )
                          }
                        ]} />
                    ) : null}
                  </GridItem>
                </GridItem>
              </li>
            );
          })}
        </ul>
        {this.state.pages.length > 2 ? <div className="flexcenter">
          <GridItem xs={12} sm={12} md={10}>
            <Pagination pages={this.state.pages} color={this.state.color} onClick={this.handleChange} />
          </GridItem>
        </div> : null}
        <div className="styleNoteUser"><i>{strings.noteUser}</i></div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    diary: state.diary
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
  }
}
export default withStyles(timelineStyle)(connect(mapStateToProps, mapDispatchToProps)(Timeline));
