import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from "components/Grid/GridItem.jsx";
import React from 'react';
import { connect } from 'react-redux';
import ImageSlider from "../../components/ImageSlider/index.jsx";
import "../../components/ImageSlider/stylecss.css";
import "../../components/ImageSlider/stylescss.scss";
// import GridItem from 'components/Grid/GridItem.jsx';
import styles from './infoStyles';


class Certificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certificates: [],
      checkSlider: false
    }
  }
  componentDidMount(){
    if(this.props.certificates.data){
      if(this.props.certificates.data.length > 0){
        this.setState({
          certificates: this.props.certificates.data,
          checkSlider: this.props.certificates.slider
        })
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
    <div>
      {this.state.certificates.length > 0 ? (
        this.state.checkSlider === true ? (
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }} className={classes.noPadding}>
          <GridContainer
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ImageSlider items={this.state.certificates}
              style={{width: "100%"}}
              showFullscreenButton={false}
              showThumbnails={true}
              lazyLoad={true}
              useBrowserFullscreen={true}
              showPlayButton={false}
              infinite={true}
              autoPlay={true}
              disableArrowKeys={true} />
          </GridContainer>
        </GridItem>
        ) : (
          this.state.certificates.map( (item, i) =>  (
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: "30px" }} key={i} >
                <div >
                  <Typography className="xuatxutag" style={{ marginLeft: "10%" }}>{item.title}</Typography>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <hr className="LineBreakDesCert"></hr>
                  </div>
                </div>
                <div className={classes.textLight} style={{ textAlign: "center" }}>
                  <img alt="text" style={{ width: "400px" }} src={item.image} />
                </div>
              </GridItem>
          ))
        )
      ) : (
            <div className = "sectionone" style = {{ marginBottom: 20, textAlign: "center",flexFlow: "column", display: "flex", justifyContent: "center", alignItems:"center", width: "100%" }}>
              <img className={classes.imgNo} alt="noCertificate" src="https://res.cloudinary.com/agridential/image/upload/v1584604659/images/undraw_certification_aif8_xemphr.svg"/>
							<Typography className="tagmanufact" style={{marginTop: "20px", marginBottom: "5px"}}> Chưa có hoạt động</Typography>
      </div>
      )}
    </div>
    )
      }
}
const mapStateToProps = (state) => {
	return {
    overview: state.overview,
    certificates: state.certificate
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Certificates));