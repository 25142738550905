const initialState = { check: "", data: []};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DISTRIBUTOR':
            return {
                ...state,
            };
        case 'GET_DISTRIBUTOR_SUCCESS':
           // console.log(action)
            return {
                ...state,
                check: action.check,
                data: action.data
            };
        case 'GET_DISTRIBUTOR_FALURE':
            return state
        default:
            return state;
    }
}
export default reducer;