import { dangerColor, grayColor, successColor, warningColor } from "assets/jss/material-dashboard-pro-react.jsx";

const style =  theme => ({
    xuatxutag:{
        fontFamily: "Muli",
        fontSize: "25px",
        fontWeight: "700",
        [theme.breakpoints.down("sm")]: {
          fontSize: "23px"
        },
      },
      noData:{
        fontFamily: "Muli",
        fontSize: "20px",
        fontWeight: "600",
        textTransform: "uppercase",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px"
          },
      },
      paddingTaste:{
        marginBottom: "30px",
        padding: "0px 17px",
        marginTop: "40px"
      },
      textStart:{
        textAlign: "start !important"
      },
      detailtitle:{
        fontSize: "16px",
        fontFamily: "Muli",
        fontWeight: "700",
        [theme.breakpoints.down("sm")]: {
          fontSize: "13px",
        },
      },
      paddingEmoji:{
        height: "50px",
        width: "50px",
        [theme.breakpoints.down("sm")]: {
          height: "40px",
          width: "40px",
        },
      },
      button: {
        minHeight: "auto",
        minWidth: "auto",
        backgroundColor: grayColor,
        color: "#FFFFFF",
        boxShadow:
          "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
        border: "none",
        borderRadius: "3px",
        position: "relative",
        padding: "12px 30px",
        margin: ".3125rem 1px",
        fontSize: "12px",
        fontWeight: "400",
        textTransform: "uppercase",
        letterSpacing: "0",
        willChange: "box-shadow, transform",
        transition:
          "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        lineHeight: "1.42857143",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        touchAction: "manipulation",
        cursor: "pointer",
        "&:hover,&:focus": {
          color: "#FFFFFF",
          backgroundColor: grayColor,
          boxShadow:
            "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
          position: "relative",
          display: "inline-block",
          top: "0",
          marginTop: "-1em",
          marginBottom: "-1em",
          fontSize: "1.1rem",
          marginRight: "4px",
          verticalAlign: "middle"
        },
        "& svg": {
          position: "relative",
          display: "inline-block",
          top: "0",
          width: "18px",
          height: "18px",
          marginRight: "4px",
          verticalAlign: "middle"
        },
        "&$justIcon": {
          "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginTop: "0px",
            position: "absolute",
            width: "100%",
            transform: "none",
            left: "0px",
            top: "0px",
            height: "100%",
            lineHeight: "41px",
            fontSize: "20px"
          }
        }
      },
      
  success: {
    backgroundColor: successColor,
    boxShadow:
      "0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)",
    "&:hover,&:focus": {
      backgroundColor: successColor,
      boxShadow:
        "0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)"
    }
  },
  warning: {
    backgroundColor: warningColor,
    boxShadow:
      "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
    "&:hover,&:focus": {
      backgroundColor: warningColor,
      boxShadow:
        "0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2)"
    }
  },
  danger: {
    backgroundColor: dangerColor,
    boxShadow:
      "0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)",
    "&:hover,&:focus": {
      backgroundColor: dangerColor,
      boxShadow:
        "0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)"
    }
  },
})
export default style;