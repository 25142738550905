const initialState = {
    data: ""
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MAP_LOCATION':
            return {
                ...state,
            };
        case 'GET_MAP_LOCATION_SUCCESS':
           // console.log("manufac", action)
            return {
                ...state,
               data: action.data
            };
        case 'GET_MAP_LOCATION_FAILURE':
            return state
        default:
            return state;
    }
}
export default reducer;