const initialState = {
    data: "",
    cooName: "",
    cooInfo: [],
    cooDes: "",
    cooImage: [],
    cooCert: [],
    cooMap: "",
    cooTx: "",
    cooDynamicDescription: "",
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MANUFACTURER':
            return {
                ...state,
            };
        case 'GET_MANUFACTURER_SUCCESS':
           // console.log("manufac", action)
            return {
                ...state,
                cooName: action.cooName,
                cooInfo: action.cooInfo,
                cooDes: action.cooDes,
                cooImage: action.cooImage,
                cooCert: action.cooCert,
                cooMap: action.cooMap,
                cooTx: action.cooTx,
                cooDynamicDescription: action.cooDynamicDescription
            };
        case 'GET_MANUFACTURER_FAILURE':
            return state
        default:
            return state;
    }
}
export default reducer;