import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import strings from 'constants/strings';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './infoStyles';


class Distributor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			tx: '',
			isDistributor: false,
			listDistributor: []
		};
		this.id = this.props.data.id;
	}
	componentDidMount() {
		if (this.props.distributor && this.props.distributor.data) {
			let main = [];
			if(this.props.distributor.data[0] && this.props.distributor.data[0].length > 0){
				if(this.props.distributor.data[0][0].address){
					main.push({
						key: strings.address,
						value: this.props.distributor.data[0][0].address
					})
				}
				if(this.props.distributor.data[0][0].email){
					main.push({
						key: strings.email,
						value: this.props.distributor.data[0][0].email
					})
				}
				if(this.props.distributor.data[0][0].phone){
					main.push({
						key: strings.phone,
						value: this.props.distributor.data[0][0].phone
					})
				}
				if(this.props.distributor.data[0][0].website){
					main.push({
						key: strings.website,
						value: this.props.distributor.data[0][0].website
					})
				}
			}

			this.setState({
				isDistributor: this.props.distributor.check,
				listDistributor: this.props.distributor.data.length > 0 ? this.props.distributor.data[0] : [],
				main: main
			})
		}
	}
	componentWillReceiveProps(preProps) {
		if (preProps.distributor !== this.props.distributor) {
			let main = [];
			if(preProps.distributor.data && preProps.distributor.data[0] && preProps.distributor.data[0].length > 0){

				if(preProps.distributor.data[0][0].address){
					main.push({
						key: strings.address,
						value: preProps.distributor.data[0][0].address
					})
				}
				if(preProps.distributor.data[0][0].email){
					main.push({
						key: strings.email,
						value: preProps.distributor.data[0][0].email
					})
				}
				if(preProps.distributor.data[0][0].phone){
					main.push({
						key: strings.phone,
						value: preProps.distributor.data[0][0].phone
					})
				}
				if(preProps.distributor.data[0][0].website){
					main.push({
						key: strings.website,
						value: preProps.distributor.data[0][0].website
					})
				}
			}

			this.setState({
				isDistributor: preProps.distributor.check,
				listDistributor: preProps.distributor.data.length > 0 ? preProps.distributor.data[0] : [],
				main: main
			})
		}
	}
	render() {
		const {classes} = this.props;
		return (
			<div>
				<div className="flexcenter" style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
					{this.state.isDistributor && Object.keys(this.state.listDistributor).length > 0 ? (
						// this.state.listDistributor.map((distributor, index) => (
							<GridItem xs={12} sm={12} md={10}>
								<Typography className="xuatxutag">{this.state.listDistributor.name}</Typography>
								<div className="descriptionCor">
									{this.state.listDistributor.description ?(
										 this.state.listDistributor.description.split('\n').map((item, i) => (
										<div key={i}>{item}</div>
									))
								
									):null}
								</div>
								<hr className="LineBreakDes"></hr>
								
								<div className="ppErr" style={{ display: 'flex', justifyContent: 'center', marginBottom: '3%' }}>
									<GridItem xs={12} sm={12} md={12}>
										<div className="sectionone">
											<Typography className="tagmanufact">{strings.headquarters}</Typography>
										</div>
										<div style={{ marginTop: '20px' }}>
											{this.state.listDistributor.main ? this.state.listDistributor.main.map((main, indexx) => (
												<GridContainer key={indexx} style={{ paddingLeft: 19 }} className="manufactsec">
													<div className="sectionone" >
														<Typography className="tagmanufact"> {main.key} </Typography>
													</div>
													<div className="sectiontwo">
														<Typography className="tagmanufactdes" >{main.value}</Typography>
													</div>
													<hr className="LineBreakDesManu" />
												</GridContainer>
											)) : null}
											<div >
												<hr className="LineBreakDes"></hr>
												{this.state.listDistributor.branchs && this.state.listDistributor.branchs.length > 0? 
												<div>
													<div className="sectionone">
														<Typography className="tagmanufact"> {strings.branch}</Typography>
													</div>
													<GridContainer style={{ display: 'flex', justifyContent: 'center' }}>
														{this.state.listDistributor.branchs.map((branch, key) => (

															<GridItem xs={12} sm={6} md={6} lg={6}>
																<Card className="cardErr" >
																	<CardBody>
																		<div className="tagPP">{branch.name}</div>
																		<div> {branch.address} </div>
																		<div> {branch.phone} </div>
																	</CardBody>
																</Card>
															</GridItem>

														))}
													</GridContainer>
												</div>
												: null}
											</div>
										</div>
									</GridItem>
								</div>
							</GridItem>
					) : (
							<div className="sectionone" style={{ marginBottom: 20, textAlign: "center", flexFlow: "column", display: "flex", justifyContent: "center", alignItems:"center", width: "100%"  }}>
								<img src="https://res.cloudinary.com/agridential/image/upload/v1584604688/images/undraw_heavy_box_agqi_btpmix.svg" alt="noDis" className={classes.imgNo} />
								<Typography className="tagmanufactupdated" style={{marginTop: "10px"}}> {strings.updated} </Typography>
							</div>
						)}
				</div>
			</div >
		);
	}
}
const mapStateToProps = (state) => {
	return {
		data: state.overview,
		distributor: state.distributor
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
	}
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Distributor)));
