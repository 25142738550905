import { combineReducers } from 'redux';
import diary from "./diary"
import overview from "./overview"
import distributor from "./distributor"
import manufacturer from "./manufacturer"
import certificate from "./certificate"
import map from "./map"
const appReducer = combineReducers({
 diary,
 overview,
 distributor,
 manufacturer,
 certificate,
 map
});


const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    state = undefined;
   // storage.removeItem('persist:root')
  }
  return appReducer(state, action);
};

export default rootReducer;