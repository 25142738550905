// ##############################
// // // HeaderLinks styles
// #############################

import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx";


const HeaderLookupStyle = theme => ({
  ...customDropdownStyle(theme),
  headerBg: {
    backgroundColor:"white",
    position: "fixed",
    width:"100%",
    boxShadow:"0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    zIndex:"1000",
    height:"50px"
  },
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
    marginRight:"20px",
    width: "95%",
    left:"15px",
    [theme.breakpoints.down("sm")]: {
      // margin: "10px 0px",
      // marginRight:"10px !important",
      // float: "none !important",
      // paddingTop: "1px",
      // paddingBottom: "1px",
      // padding: "10px 15px",
      // width: "auto"
    }
  },
  searchInput: {
    paddingTop: "2px",
    marginRight:"25px",
    fontWeight:"700",
    fontFamily:"Muli",
    color: "#ffffff"
  },
 
  headerButton: {
    height: "35px",
    width :"35px",
    marginLeft:"8px",
    paddingLeft:"10px",
    paddingRight:"10px",
    paddingTop:"7px",
    marginTop:"7px",
    minWidth:"35px !important"
  },
  searchButton: {
    [theme.breakpoints.down("sm")]: {
      top: "-50px !important",
      marginRight: "38px",
      float: "right"
    },
    float:'right',
    marginTop:"7px",
    marginRight:"10px",
    height: "35px",
    width :"35px",
    marginLeft:"8px",
    paddingLeft:"10px",
    paddingRight:"10px",
    paddingTop:"7px",
    minWidth:"35px !important"
  },
  top: {
    zIndex: "4"
  },
  searchIcon: {
    width: "15px",
    zIndex: "4"
  },
  managerClasses: {
    [theme.breakpoints.up("md")]: {
      display: "inline-block"
    }
  },
  headerLinksSvg: {
    // color: "#4caf50",
    width: "20px !important",
    height: "20px !important"
  },
  qrButton: {
    padding:"0px",
    backgroundColor:"transparent",
    boxShadow: "none",
    height: "35px",
    width :"35px",
    marginTop:"3px",
    marginLeft:"0px",
    "&:hover,&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  },
  languageButton: {
    padding:"0px",
    backgroundColor:"transparent",
    boxShadow: "none",
    float:'right',
    marginRight:"8px",
    "&:hover,&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  },
  
  qrImg: {
    maxHeight:"30px",
  },  
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  placeholdersearch:{
    marginTop:"6px",
    color: "#ffffff !important",
  },
  underlinesearch:{
    borderBottom: '2px solid white',
    '&:after': {
      // The MUI source seems to use this but it doesn't work
      borderBottom: '2px solid white',
    },
  },
  root:{
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    flexFlow:"row",
    paddingTop: "20px",
    paddingBottom: "20px",
    width: "919px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
  noPadding:{
    padding: "0px 0px !important",
    display: "flex",
    flexFlow:"row"
  },
  vbc:{
    [theme.breakpoints.down("xs")]: {
      height: "30px"
    },
    height: "60px"
  },
  agd:{
    [theme.breakpoints.down("xs")]: {
        height:"30px",
        marginLeft: "10px"
    },
    height: "60px",
    marginLeft: "20px"
  },
  paddingLogo: {
    display: "flex",
    flexFlow: "row",
    justifyContent:"start",
    padding: "0px 0px !important",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 15px !important"
  },
    
  },
  lookupBar:{
    padding: "0px 0px !important",
    display: "flex",
    width: "919px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
  headerBar:{
    backgroundColor: "#4FB779",
    padding: "15px 0px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 10px",
    },
    },
    language:{
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "end",
      padding: "0px 0px !important",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 15px !important",
      },
    }
});

export default HeaderLookupStyle;
