import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import strings from 'constants/strings';

const styles = {
  gray: {
    color: 'gray',
  }
}

class StepProgressBar extends React.Component {
  render() {
    return (
      <ProgressBar
        percent={100}
        filledBackground="linear-gradient(to right, #2e7d32, #ffea00, #ffc400)"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={styles.gray}>
              <p>{strings.sour}</p>
              <p>.</p>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div>
              <p style={styles.gray}>{strings.medium}</p>
              <p>.</p>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div>
              <p style={styles.gray}>{strings.sweet}</p>
              <p>.</p>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div style={{ fontWeight: "bold" }}>
              <p>{strings.out_of_date}</p>
              <p>.</p>
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }
}

export default StepProgressBar;