import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem.jsx";
import strings from 'constants/strings';
import PropTypes from 'prop-types';
import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import styles from './infoStyles';
import FaceIcon from "mdi-react/FacebookIcon"
import YoutubeIcon from "mdi-react/YoutubeIcon"
class LookupRice extends React.Component {

  render() {
    const {classes} = this.props;
    const VBC = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_221/v1570694865/Logo/logo_vbc_kijy1j.png";
    const AGR = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_200/v1570694729/Logo/agd_white_text_ofaasb.png";
    strings.setLanguage(strings.getLanguage());

    return (
      <div>
        <div className="footer-distributed">
          <div className={classes.root}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <img alt="VBC" className={classes.logoVBC} src={VBC} />
              {/* <img alt="AGD" className={classes.logoAGD} src={AGR} /> */}
              <p> </p>
              <p className={classes.addressvbc}>{strings.vbc_about}</p>
              <p className={classes.weblink}>Website: <a href="# " className={classes.linkweb} onClick={() => {
                window.location.href = 'https://vietnamblockchain.asia/';
                return null;
              }}>vietnamblockchain.asia</a></p>
             
              <div style={{ marginTop: "0px", display: "flex", flexFlow: "row" }}>
                <p className={classes.addressvbc}>0908 444 095</p>
                <p className={classes.addressvbc} style={{marginLeft: "10px", color: "white"}}><a href="mailto:contact@vietnamblockchain.asia" style={{color: "white !important"}}>contact@vietnamblockchain.asia</a></p>
              </div>
              <div style={{ marginTop: "-10px" }}>
                <p className={classes.addressvbc}>{strings.vbc_address}</p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <YoutubeIcon style={{fill: "#fff"}} onClick={()=> window.open("https://www.youtube.com/channel/UC7k1WHzYlMhGzHJHH0yN1yQ")}/>
            <FaceIcon style={{fill: "#fff", marginLeft: "10px"}} onClick={()=> window.open("http://m.me/agridential")}/>
            </GridItem>
            </GridContainer>
            </div>
          </div>
      </div>
    );
  }
}

LookupRice.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(LookupRice);