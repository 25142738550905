import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"



const MapWithAMarkers = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <div>
        <GoogleMap ref={(map) => {
            if (map) {
                const bounds = new window.google.maps.LatLngBounds();
                map.props.children.forEach((child) => {
                    if (child.type === Marker) {
                        bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                    }
                })
                map.fitBounds(bounds);
            }

        }} defaultZoom={5}>
            {props.markers.map((marker, index) => (
                <Marker
                    key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    title= {marker.name}
                    icon={{
                        url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png",
                        scaledSize: new window.google.maps.Size(20, 27)
                      }}
                />
            ))}


        </GoogleMap>

        <GoogleMap >
            <Polyline
                path={props.markers}
                geodesic={true}
                options={{
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    icons: [{
                        // repeat: "50%",
                        icon: {
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 2
                        },
                        offset: '100%'
                    }]
                }}
            />
        </GoogleMap>
    </div>


);

class MapComponent extends React.PureComponent {
    componentWillMount() {
        this.setState({ markers: [] })
    }


    async componentDidMount() {
        let stories = await this.props.stories.reverse();
        //console.log("stories hjahsjdashjdsahjkdahkjsahkjsahdsajhdajhd", stories)
        let data = stories.map(item => {
            return {
                lat: item.latitude,
                lng: item.longitude,
                name: item.from_name
            }
        })
        this.setState({ markers: data });
    }

    componentWillReceiveProps(preProps) {
        if (this.props.stories !== preProps.stories) {
            let stories = preProps.stories.reverse();
            let data = stories.map(item => {
                return {
                    lat: item.latitude,
                    lng: item.longitude,
                    name: item.from_name
                }
            })
            this.setState({ markers: data });
        }
    }


    render() {
        return (
            this.state.markers.length > 0 && <MapWithAMarkers markers={this.state.markers} />
        )
    }
}

export default MapComponent;