const initialState = {  data: [], dataFull: [], slider: false};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CERTIFICATE':
            return {
                ...state,
            };
        case 'GET_CERTIFICATE_SUCCESS':
            //console.log(action)
            return {
                ...state,
                data: action.data,
                slider: action.slider,
                dataFull: action.dataFull
            };
        case 'GET_CERTIFICATE_FALURE':
            return state
        default:
            return state;
    }
}
export default reducer;