const Sample_Fruit_Info = {
  vi: {
    type: "FRUIT",
  objectInfo: {
   // idCode: "893607144003300010002",
    idCode: "0000000000001"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "hoptacxaxoaimyxuong@gmail.com",
    name: "HTX Xoài Mỹ Xương",
    gln: "8936071440006",
    address:
      "Xã Mỹ Xương, huyện Cao Lãnh, tỉnh Đồng Tháp",
    standard: "VietGAP",
    phone: "(0277) 3521799",
    website: "xoaicaolanh.com.vn",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  distributor: [
    {
      address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
      description: "Công ty Cổ phần Vietnam Blockchain (Vietnam Blockchain Corporation – VBC) là công ty cung cấp các giải pháp và dịch vụ về công nghệ blockchain phục vụ các lĩnh vực chủ chốt như Nông nghiệp, Sản xuất, Chuỗi cung ứng, Hậu cần, Thương mại Điện tử, Kinh tế chia sẻ, Công Nghệ Tài Chính, Dịch Vụ Công và Đô Thị Thông Minh.",
      email: "contact@vietnamblockchain.asia",
      name: "CÔNG TY CỔ PHẦN VIETNAM BLOCKCHAIN",
      phone: "0908 444 095",
      uuid: "F000001",
      website: "vietnamblockchain.asia",
      _id: "5d9787e8a930ee415745b183",
      branchs: []
    }],
  productInfo: {
    name: "XOÀI CÁT CHU CAO LÃNH",
    type: "F",
    mfDate: 1555580172000,
    description:
      "Xoài cát Cao Lãnh Đồng Tháp có trọng lượng trung bình 350-450g, hình thon dài, khi chín vỏ màu vàng nhạt. Thịt xoài Cát Chu ít xơ, mềm mà hơi dai lại rất ngọt và thơm. Khi ăn miếng xoài tan nhẹ trong miệng và vị ngọt dịu vẫn còn đọng mãi trên đầu lưỡi. Loại nông sản này giàu lượng acid amin rất cần thiết cho cơ thể. Xoài được HTX Xoài Mỹ Xương sản xuất theo mô hình Cây Xoài Nhà Tôi, giúp đảm bảo vệ sinh an toàn thực phẩm, kiểm soát được chất lượng và nguồn gốc rõ ràng.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 1,
    standard: "VietGAP",
    "expired_date ": "7 ngày sau đóng gói",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image:"",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577073668/samples/certificates/cert_htxmyxuong_vietgap_h2eypy.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1577073553/samples/certificates/cert_myxuong_spnongnghieptieubieu_lfkgei.jpg"}]
  },
  diary: [
    [ 
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          step: "ĐĂNG KÝ CÂY",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0xc0cdd4e733d79d70aaa4fa8f07bfe60445680a17fe11e9d8f458113c775a44dc",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Cây trồng được đăng ký áp dụng giải pháp truy xuất nguồn gốc Blockchain và được cấp mã số định danh phù hợp tiêu chuẩn GS1.",
          step_attribute: "",
          step_icon: "mango.svg"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "BÓN PHÂN",
          step_attribute: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          txID:
            "0xca6e461b26c5e27b378b45659025d3b864632ad8fef3aba82e631a39bb62550c",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Bón phân xới vòng tròn theo hình chiếu tán cây, trộn phân đều vào đất, sau đó tưới nước và tủ cỏ giữ ẩm. Cung cấp cho cây các chất dinh dưỡng cần thiết như Đạm, Lân, Kali.",
          step_icon: "fertilizer.svg"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546473600000,
          step: "PHUN XỊT",
          step_attribute:
            "Mục đích: Dưỡng cây \n Loại thuốc: Polytrin P, Topsin M và NAA",
          txID:
            "0xecf77cb4ee70e218ca49d2544f2cc80a9b77d37c6ec8774d1567f85fe612a369",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp cho cây ra hoa đồng loạt, tăng tỷ lệ đậu trái và diệt các loại côn trùng gây hại.",
          step_icon: "pesticide.svg"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546560000000,
          detail: "Loại bao: Màu vàng, 2 lớp \n Nguồn gốc: Đài Loan",
          step: "BAO TRÁI",
          step_attribute: "Loại bao: Màu vàng, 2 lớp \n Nguồn gốc: Đài Loan",
          txID:
            "0xeabc1a023c46cc9383d9a53584808929336494456605f10597b155678dfb15de",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Bao trái nhằm hạn chế được tổn thương cho vỏ trái, giảm tỷ lệ bệnh, tác động của côn trùng và chim, cải thiện màu sắc vỏ trái, hạn chế nám nắng và hạn chế lượng thuốc bảo vệ thực vật sử dụng trên cây.",
          step_icon: "bao_trai.png"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1555545600000,
          step: "THU HOẠCH",
          step_attribute: "",
          txID:
            "0xbe56c3c67296f10e6066fc803df4fbdd6127708cb29f25dba86c256c116bc750",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Tiến hành sau 90 ngày kể từ khi Bao Trái. Quá trình thu hoạch được nhà vườn thực hiện thông qua sự quản lý của hợp tác xã. Các trái xoài trên cùng một cây nếu đạt chuẩn sẽ được chọn và để riêng trong cùng một thùng.",
          step_icon: "harvest.svg"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1555545600000,
          step: "SƠ CHẾ VÀ ĐÓNG GÓI",
          step_attribute:
            "Cắt cuống \nNgâm nước sôi 42°C (4 phút) \nĐể ráo nước và đóng gói",
          txID:
            "0x34a06fd78234ee381418afa77b815c9d099ec3f45d11d8fcc8b527e33831ff0f",
          from_name: "HTX Xoài Mỹ Xương",
          step_desc:
            "Xoài sẽ được ngắt cuống, khử trùng bằng nước sôi ở nhiệt độ thích hợp và để quạt ráo nước. Sau đó sẽ được dán tem Blockchain truy xuất nguồn gốc và các trái xoài chung một cây sẽ được đóng gói chung một thùng.",
          step_icon: "delivery-package-opened.png"
        }
      ],
    ]
  ]
  },
  en: {
    type: "FRUIT",
  objectInfo: {
    //idCode: "893607144003300010002",
    idCode: "0000000000000"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "hoptacxaxoaimyxuong@gmail.com",
    name: "My Xuong Cooperative",
    gln: "8936071440006",
    address:
      "My Xuong , Cao Lanh District, Dong Thap Province",
    standard: "VietGAP",
    phone: "(0277) 3521799",
    website: "xoaicaolanh.com.vn",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  productInfo: {
    name: "CAT CHU MANGOE FROM CAO LANH PROVINCE",
    mfDate: 1555580172000,
    type: "F",
    description:
      " Cao Lanh Dong Thap sand mango has an average weight of 350-450g, elongated shape, ripe ripe yellowish skin. Although Cat Chu mango is low in fiber, soft but slightly tough, it is very sweet and fragrant. When eating, the mango melts slightly in the mouth and the sweetness remains on the tongue. This agricultural product is rich in amino acids that are essential for the body. Mangoes are produced by My Xuong Cooperative in the model of My Mango Tree, which helps to ensure food hygiene and safety, quality control and clear origin.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 1,
    standard: "VietGAP",
    "expired_date ": "7 ngày sau đóng gói",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image:"",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577073668/samples/certificates/cert_htxmyxuong_vietgap_h2eypy.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1577073553/samples/certificates/cert_myxuong_spnongnghieptieubieu_lfkgei.jpg"}]

  },
  diary: [
    [
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          step: "Register a tree",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0xc0cdd4e733d79d70aaa4fa8f07bfe60445680a17fe11e9d8f458113c775a44dc",
          from_name: "My Xuong Mango Farm",
          step_desc:
            "The plant is registered for a Blockchain traceability solution and has an identification number that conforms to GS1 standards.",
          step_attribute: "",
          step_icon: "mango.svg"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "1st: Organic Fertilizer \n 2st: NPK Fertilizer",
          step: "Fertilize",
          step_attribute: "1st: Organic Fertilizer \n 2st: NPK Fertilizer",
          txID:
            "0xca6e461b26c5e27b378b45659025d3b864632ad8fef3aba82e631a39bb62550c",
          from_name: "My Xuong Mango Farm",
          step_desc:
            "Fertilize the soil in a circular pattern according to the tree canopy, mix the fertilizer into the soil, then water and keep the grass moist. Provide plants with necessary nutrients such as Nitrogen, Phosphorus, Potassium.",
          step_icon: "fertilizer.svg"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546473600000,
          step: "Spray",
          step_attribute:
            "Purpose: Taking care of tree\n Medicine: Polytrin P, Topsin M and NAA",
          txID:
            "0xecf77cb4ee70e218ca49d2544f2cc80a9b77d37c6ec8774d1567f85fe612a369",
          from_name: "My Xuong Mango Farm",
          step_desc:
            "Spraying drugs on the list of allowed by the Ministry of Health at many times. Helping the plant to simultaneously flower, increase the rate of fruiting and kill harmful insects.",
          step_icon: "pesticide.svg"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546560000000,
          detail: "Packet type: Yellow, 2 Paper Pack \n Origin: Taiwan",
          step: "Fruit bagging",
          step_attribute: "Packet type: Yellow, 2 Paper Pack \n Origin: Taiwan",
          txID:
            "0xeabc1a023c46cc9383d9a53584808929336494456605f10597b155678dfb15de",
          from_name: "My Xuong Mango Farm",
          step_desc:
            "Fruit bags are designed to minimize damage to the skin, reduce the incidence of diseases, the impact of insects and birds, improve the color of the fruit skin, limit sunburn and limit the amount of plant protection products used on trees.",
          step_icon: "bao_trai.png"
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1555545600000,
          step: "Harvest",
          step_attribute: "",
          txID:
            "0xbe56c3c67296f10e6066fc803df4fbdd6127708cb29f25dba86c256c116bc750",
          from_name: "My Xuong Mango Farm",
          step_desc:
            "Conducted after 90 days since Fruit Packaging. Harvesting is done by the growers through cooperative management. If the mangoes are on the same tree, they will be selected and placed separately in the same box.",
          step_icon: "harvest.svg"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1555545600000,
          step: "Preliminary Processing And Packing",
          step_attribute:
            "Stalked \nBoiling under 42C in 4min \nDried and packed",
          txID:
            "0x34a06fd78234ee381418afa77b815c9d099ec3f45d11d8fcc8b527e33831ff0f",
          from_name: "My Xuong Cooperative",
          step_desc:
            "Mango will be stalked, disinfected with boiling water at appropriate temperature and drained. It will then be stamped with the Traceability Blockchain stamp and the mangoes in the same tree will be packaged in one carton.",
          step_icon: "delivery-package-opened.png"
        }
      ],
    ]
  ]
  }
};
const Sample_Mango_Info = {
  type: "MANGO",
  objectInfo: {
    idCode: "893607144003300010002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "happyhouse099@gmail.com",
    name: "HTX Dịch Vụ Nông Nghiệp Tân Khánh Đông",
    gln: "",
    address:
      "Ấp Đông Huề, xã Tân Khánh Đông, thành phố Sa Đéc, tỉnh Đồng Tháp",
    standard: "VietGAP",
    phone: "0939912952 ",
    website: "",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  productInfo: {
    name: "XOÀI SA ĐÉC",
    type: "F",
    description:
      "Xoài Sa Đéc có trọng lượng trung bình 350-450g, hình thon dài, khi chín vỏ màu vàng nhạt. Thịt xoài Sa Đéc ít xơ, mềm mà hơi dai lại rất ngọt và thơm. Khi ăn miếng xoài tan nhẹ trong miệng và vị ngọt dịu vẫn còn đọng mãi trên đầu lưỡi. Loại nông sản này giàu lượng acid amin rất cần thiết cho cơ thể. Xoài được HTX Tân Khánh Đông sản xuất theo tiêu chuẩn VietGAP, giúp đảm bảo vệ sinh an toàn thực phẩm, kiểm soát được chất lượng và nguồn gốc rõ ràng.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    "expired_date ": "7 ngày sau đóng gói",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074195/samples/certificates/chungnhanxoaisadec_lfspph.png"}]
      
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1555545600000,
          step: "SƠ CHẾ VÀ ĐÓNG GÓI",
          step_attribute:
            "Cắt cuống \nNgâm nước sôi 42°C (4 phút) \nĐể ráo nước và đóng gói",
          txID:
            "0xc242825b6794256d8b0582f06eb460c814fe0b46a83b6f01d64e766dae820f3d",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Xoài sẽ được ngắt cuống, khử trùng bằng nước sôi ở nhiệt độ thích hợp và để quạt ráo nước. Sau đó sẽ được dán tem Blockchain truy xuất nguồn gốc và các trái xoài chung một cây sẽ được đóng gói chung một thùng.",
          step_icon: "delivery-package-opened.png"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1537414621000,
          step: "THU HOẠCH",
          step_attribute: "Sản lượng thu hoạch: 700kg \n Loại xoài: Xoài Cát \n Cở sở thu mua: Chín Thạnh",
          txID:
            "0x5f6b74c14a350003133a0acaf5f291c79b2008a0c239f02ff1d64c0737a4e34d",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Tiến hành sau 90 ngày kể từ khi Bao Trái. Quá trình thu hoạch được nhà vườn thực hiện thông qua sự quản lý của hợp tác xã. Các trái xoài trên cùng một cây nếu đạt chuẩn sẽ được chọn và để riêng trong cùng một thùng.",
          step_icon: "harvest.svg"
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546560000000,
          detail: "Loại bao: Màu vàng, 2 lớp \n Nguồn gốc: Đài Loan",
          step: "BAO TRÁI",
          step_attribute: "Loại bao: Màu vàng, 2 lớp \n Nguồn gốc: Đài Loan",
          txID:
            "0xfd74a2f7db6cc2931a3ae5182b85f70dc3e89400b95b4ae687ee04a89e2df4f3",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Bao trái nhằm hạn chế được tổn thương cho vỏ trái, giảm tỷ lệ bệnh, tác động của côn trùng và chim, cải thiện màu sắc vỏ trái, hạn chế nám nắng và hạn chế lượng thuốc bảo vệ thực vật sử dụng trên cây.",
          step_icon: "bao_trai.png"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1526701021000,
          step: "PHUN XỊT",
          step_attribute:
            "Mục đích: Kích thích cây \n Loại thuốc: DH.05 \n Nồng độ và tổng lượng sử dụng: 5 phi, 600g/220 lít",
          txID:
            "0xecf77cb4ee70e218ca49d2544f2cc80a9b77d37c6ec8774d1567f85fe612a369",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp cho cây ra hoa đồng loạt, tăng tỷ lệ đậu trái và diệt các loại côn trùng gây hại.",
          step_icon: "pesticide.svg"
        },
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1526701021000,
          step: "PHUN XỊT",
          step_attribute:
            "Mục đích: Kích thích cây \n Loại thuốc: Thiouvea 99% \n Nồng độ và tổng lượng sử dụng: 5 phi, 300g/220 lít",
          txID:
            "0x4638628d020399d2c80dca9119ccd1403a7f74e456d7c4e6021c59f99280ec51",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp cho cây ra hoa đồng loạt, tăng tỷ lệ đậu trái và diệt các loại côn trùng gây hại.",
          step_icon: "pesticide.svg"
        },
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1527305821000,
          step: "PHUN XỊT",
          step_attribute:
            "Mục đích: Kích thích cây \n Loại thuốc: DH.05 \n Nồng độ và tổng lượng sử dụng: 5 phi, 300g/220 lít",
          txID:
            "0x3fd0586b4f50e21049592fc9e1ca02d251596d7b428041c288160e988d549bf4",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp cho cây ra hoa đồng loạt, tăng tỷ lệ đậu trái và diệt các loại côn trùng gây hại.",
          step_icon: "pesticide.svg"
        },
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1528256221000,
          step: "PHUN XỊT",
          step_attribute:
            "Mục đích: Kích thích cây \n Loại thuốc: 70 WP AntraCol \n Nồng độ và tổng lượng sử dụng: 5 phi, 300g/220 lít \n Thời gian cách ly: 7 ngày",
          txID:
            "0x646046462ea89422b5b5559af7d288012b2dcedaa04e4509b2a35c7b5470e098",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp cho cây ra hoa đồng loạt, tăng tỷ lệ đậu trái và diệt các loại côn trùng gây hại.",
          step_icon: "pesticide.svg"
        },
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1528256221000,
          step: "PHUN XỊT",
          step_attribute:
            "Mục đích: Kích thích cây \n Loại thuốc: 300EC Tilt Super\n Nồng độ và tổng lượng sử dụng: 5 phi, 50cc/220 lít \n Thời gian cách ly: 14 ngày",
          txID:
            "0x711131da761378c33f714f92403f503502161177c88e554ee108e20320b47478",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp cho cây ra hoa đồng loạt, tăng tỷ lệ đậu trái và diệt các loại côn trùng gây hại.",
          step_icon: "pesticide.svg"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1514777821000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "BÓN PHÂN",
          step_attribute: "Lô: 1 \n Diện tích: 500m2 \n Loại phân: Phân chuồng \n Lượng sử dụng: 1000kg",
          txID:
            "0xdd044bd565dd6621b330b161fe7d4ac2dad64b66834ef21244c79f3a6119fcd2",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Bón phân xới vòng tròn theo hình chiếu tán cây, trộn phân đều vào đất, sau đó tưới nước và tủ cỏ giữ ẩm. Cung cấp cho cây các chất dinh dưỡng cần thiết như Đạm, Lân, Kali.",
          step_icon: "fertilizer.svg"
        },
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1514777821000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "BÓN PHÂN",
          step_attribute: "Lô: 1 \n Diện tích: 500m2 \n Loại phân: NPK \n Lượng sử dụng: 300kg",
          txID:
            "0x52ca2333128efc1e431be5f4f24c233fdd1c72fb15a8008f5a042f9e2349cfdf",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Bón phân xới vòng tròn theo hình chiếu tán cây, trộn phân đều vào đất, sau đó tưới nước và tủ cỏ giữ ẩm. Cung cấp cho cây các chất dinh dưỡng cần thiết như Đạm, Lân, Kali.",
          step_icon: "fertilizer.svg"
        },
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1514777821000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "BÓN PHÂN",
          step_attribute: "Lô: 1 \n Diện tích: 500m2 \n Loại phân: Urê \n Lượng sử dụng: 100kg",
          txID:
            "0xd5aef6c925081edb3e32105ceb3c218df6781094192727c4f7c180f010aad039",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Bón phân xới vòng tròn theo hình chiếu tán cây, trộn phân đều vào đất, sau đó tưới nước và tủ cỏ giữ ẩm. Cung cấp cho cây các chất dinh dưỡng cần thiết như Đạm, Lân, Kali.",
          step_icon: "fertilizer.svg"
        },
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1530329821000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "BÓN PHÂN",
          step_attribute: "Lô: 1 \n Diện tích: 500m2 \n Loại phân: NPK \n Lượng sử dụng: 100kg",
          txID:
            "0x3543bccb5af7bc7f05215f365322b3dfa0c4825f6410bd683babd28c20d5aaa8",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Bón phân xới vòng tròn theo hình chiếu tán cây, trộn phân đều vào đất, sau đó tưới nước và tủ cỏ giữ ẩm. Cung cấp cho cây các chất dinh dưỡng cần thiết như Đạm, Lân, Kali.",
          step_icon: "fertilizer.svg"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          step: "ĐĂNG KÝ CÂY",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0xb3d536e1861c28b49a1d8698ab10e9c54077f9b9b8f5b94e1abae00d75bdf5db",
          from_name: "HTX Tân Khánh Đông",
          step_desc:
            "Cây trồng được đăng ký áp dụng giải pháp truy xuất nguồn gốc Blockchain và được cấp mã số định danh phù hợp tiêu chuẩn GS1.",
          step_attribute: "",
          step_icon: "mango.svg"
        }
      ]
    ]
  ]
};
const Sample_Tree_Info = {
  objectInfo: {
    quantity: 0,
    idCode: "893607144003300010001",
    expiredDate: "",
    from: "89360714400130001",
    renter: "",
    cooperativeGln: "8936071440006",
    tokenId: 1,
    tx: "0x804312d130385722ee9c55b6d231b98f89339749854261d0d3f2b53476ca16b0",
    rentId: -1,
    tx_rent: "0x00",
    standard: "VietGAP",
    registeredDate: 1554377317758,
    id: null
  },
  cooperativeInfo: {
    email: "hoptacxaxoaimyxuong@gmail.com",
    name: "HTX Xoài Mỹ Xương",
    gln: "8936071440006",
    address:
      "Số 637, Khu dân cư Trung tâm, ấp Mỹ Thới, xã Mỹ Xương, huyện Cao Lãnh, tỉnh Đồng Tháp",
    standard: "VietGAP",
    phone: "(0277) 3521799",
    website: "xoaicaolanh.com.vn",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  productInfo: {
    name: "Cây xoài nhà tôi",
    type: "F",
    description:
      "Trong mô hình Cây Xoày Nhà Tôi, mỗi cây xoài sẽ được “đặt mua” trước trong vòng một năm và số tiền trả phụ thuộc từng cây xoài. Từng cây xoài được hợp tác xã cam kết về sản lượng cũng như chất lượng trái. Việc chăm sóc sẽ được xã viên tiến hành theo tiêu chuẩn VietGAP do Bộ Nông nghiệp và Phát triển Nông thôn quy định. Khi thu hoạch trái, hợp tác xã sẽ đóng thùng và vận chuyển tới tay người sở hữu. Như vậy, mô hình này giúp cho người tiêu dùng phần nào nắm bắt được nguồn gốc sản phẩm và an tâm khi sử dụng. Ngược lại, đối với xã viên thì có kinh phí ổn định để chăm sóc cây, đồng thời tránh được tình trạng không thể tiêu thụ sản phẩm hoặc bị ép giá.",
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1555545600000,
          step: "THU HOẠCH",
          step_attribute: "",
          txID:
            "0xbe56c3c67296f10e6066fc803df4fbdd6127708cb29f25dba86c256c116bc750",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Tiến hành sau 90 ngày kể từ khi Bao Trái. Quá trình thu hoạch được nhà vườn thực hiện thông qua sự quản lý của hợp tác xã. Các trái xoài trên cùng một cây nếu đạt chuẩn sẽ được chọn và để riêng trong cùng một thùng.",
          step_icon: "harvest.svg"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546560000000,
          detail: "Loại bao: Màu vàng, 2 lớp \n Nguồn gốc: Đài Loan",
          step: "BAO TRÁI",
          step_attribute: "Loại bao: Màu vàng, 2 lớp \n Nguồn gốc: Đài Loan",
          txID:
            "0xeabc1a023c46cc9383d9a53584808929336494456605f10597b155678dfb15de",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Bao trái nhằm hạn chế được tổn thương cho vỏ trái, giảm tỷ lệ bệnh, tác động của côn trùng và chim, cải thiện màu sắc vỏ trái, hạn chế nám nắng và hạn chế lượng thuốc bảo vệ thực vật sử dụng trên cây.",
          step_icon: "bao_trai.png"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546473600000,
          step: "PHUN XỊT",
          step_attribute:
            "Mục đích: Dưỡng cây \n Loại thuốc: Polytrin P, Topsin M và NAA",
          txID:
            "0xecf77cb4ee70e218ca49d2544f2cc80a9b77d37c6ec8774d1567f85fe612a369",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp cho cây ra hoa đồng loạt, tăng tỷ lệ đậu trái và diệt các loại côn trùng gây hại.",
          step_icon: "pesticide.svg"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "BÓN PHÂN",
          step_attribute: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          txID:
            "0xca6e461b26c5e27b378b45659025d3b864632ad8fef3aba82e631a39bb62550c",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Bón phân xới vòng tròn theo hình chiếu tán cây, trộn phân đều vào đất, sau đó tưới nước và tủ cỏ giữ ẩm. Cung cấp cho cây các chất dinh dưỡng cần thiết như Đạm, Lân, Kali.",
          step_icon: "fertilizer.svg"
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc trái xoài",
          step: "ĐĂNG KÝ CÂY",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0xc0cdd4e733d79d70aaa4fa8f07bfe60445680a17fe11e9d8f458113c775a44dc",
          from_name: "Vườn xoài Mỹ Xương",
          step_desc:
            "Cây trồng được đăng ký áp dụng giải pháp truy xuất nguồn gốc Blockchain và được cấp mã số định danh phù hợp tiêu chuẩn GS1.",
          step_attribute: "",
          step_icon: "mango.svg"
        }
      ]
    ]
  ]
};
const Sample_Rice_Info = {
  type: "RICE",
  objectInfo: {
    idCode: "993607144003300010001"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "dagrimex@dagrimex.vn",
    name: "CÔNG TY LƯƠNG THỰC ĐỒNG THÁP",
    gln: "8936071440006",
    address:
      "Số 531, Quốc lộ 30, xã Mỹ Tân, thành phố Cao Lãnh, tỉnh Đồng Tháp, Việt Nam",
    standard: "FDA",
    phone: " (84-277) 3852 374 - 3852 206",
    website: "dagrimex.com.vn",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  productInfo: {
    name: "GẠO SẠCH TRÀNG CHIM ĐỒNG THÁP",
    type: "F",
    description:
      "Nhãn hiệu lấy hình tượng lúa và chim sếu là đặc trưng của Đồng Tháp để làm nhãn hiệu hàng hóa cho sản phẩm gạo nhằm thể hiện sự gần gũi với thiên nhiên, tạo cho người tiêu dùng liên tưởng ngay đến vùng đất Đồng Tháp Mười trù phú với những đàn sếu đầu đỏ của vườn Quốc gia Tràm Chim - khu Ramsar có tầm quan trọng quốc tế, xếp thứ 2.000 trên thế giới và thứ 4 ở Việt Nam, nơi đây có vùng nguyên liệu canh tác theo hướng sử dụng phân bón và thuốc BVTV có nguồn gốc hữu cơ và sinh học tạo nên được những loại gạo đạt tiêu chuẩn chất lượng và an toàn vệ sinh thực phẩm.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expired_date ": "1 năm sau ngày đóng gói",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074358/samples/certificates/fda_gjqj7f.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1577074365/samples/certificates/top100gdt_d5set3.jpg"}]
  },
  diary: []
};
const Sample_Caffe_Info = {
  vi: {
    type: "COFFEE",
    objectInfo: {
      idCode: "8938522156580"
    },
    farmInfo: {
      gln: "89360714400130001",
      farm_name: "Nguyễn Văn Mách",
      scope: "60 cây",
      txID:
        "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
      id: null
    },
    cooperativeInfo: {
      email: "Info@mayacacoffee.com",
      name: "Công ty TNHH MTV Cà phê MAYACA",
      gln: "8936071440006",
      address: "53 Nguyễn Thị Thập, Hòa Minh, Liên Chiểu, Đà Nẵng ",
      standard: "FDA",
      phone: " (84-277) 3852 374 - 3852 206",
      website: "mayacacoffee.com",
      txId:
        "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
      id: null
    },
    productInfo: {
      name: "MaYaCa GOOD INSIDE GI",
      type: "F",
      description:
        "Mayaca Good Inside(GI) là sản phẩm tâm huyết chủ lực của Mayaca Coffee với công nghệ lên men 2 lần trong trái cho vị cà phê đặc biệt và giữ nguyên giá trị của hạt cà phê.",
      process_code: "R1",
      create_date: 1554460325003,
      owner_gln: "8936071440006",
      trace_cycle: "y",
      TABLE_NAME: "8936071440037",
      sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
      status: 0,
      standard: "FDA",
      "expiredDate ": "1 năm sau ngày đóng gói",
      gtin: "0000000000000",
      blockchain:
        "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
      index: "1",
      prefix: "003",
      __v: 0,
      id: null,
      image: "", 
      certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577067633/samples/certificates/antoancoffee_oo6qzu.jpg"}]
    },
    distributor: [
      {
        address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
        description: "Công ty Cổ phần Vietnam Blockchain (Vietnam Blockchain Corporation – VBC) là công ty cung cấp các giải pháp và dịch vụ về công nghệ blockchain phục vụ các lĩnh vực chủ chốt như Nông nghiệp, Sản xuất, Chuỗi cung ứng, Hậu cần, Thương mại Điện tử, Kinh tế chia sẻ, Công Nghệ Tài Chính, Dịch Vụ Công và Đô Thị Thông Minh.",
        email: "contact@vietnamblockchain.asia",
        name: "CÔNG TY CỔ PHẦN VIETNAM BLOCKCHAIN",
        phone: "0908 444 095",
        uuid: "F000001",
        website: "vietnamblockchain.asia",
        _id: "5d9787e8a930ee415745b183",
        branchs: []
      }],
    diary: [
      [
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            step: "ĐĂNG KÝ CÂY",
            from_id: "89360714400130001",
            date: 1546387200000,
            txID:
              "0x918b171aeec3af6a61807021dbb99c75aff11991bc4b9a8cbd7155d76ca934e7",
            from_name: "Cà phê MAYACA",
            step_desc:
              "Cây trồng được đăng ký áp dụng giải pháp truy xuất nguồn gốc Blockchain và được cấp mã số định danh phù hợp tiêu chuẩn GS1.",
            step_attribute: "",
            step_icon: "register.svg",
            images: []
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1546473600000,
            detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
            step: "HÁI QUẢ CHÍN",
            step_attribute: "",
            txID:
              "0x87381c4694fc18e45da345821fac9c8b5c94cdd9eaba733117bf66e422956071",
            from_name: "Cà phê MAYACA",
            step_desc:
              "Hái quả chín từ cây các cây cafe đã được đăng ký trong vườn",
            step_icon: "picking.svg",
            images:  [{url: "https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/haitrai_hzd6rs.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1546473600000,
            step: "LÊN MEN YẾM KHÍ",
            step_attribute: "",
            txID:
              "0x2a5002a14e02d8f1ac29caa26193f47264a8ab1856c8a4c7878baaa49f4c359f",
            from_name: "Cà phê MAYACA",
            step_desc: "Phân rã chất nhầy để thu lấy nhân cà phê",
            step_icon: "fermentation.svg",
            images:  [{url: "https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/laynhan_scb4qd.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1546560000000,
            detail: "",
            step: "PHƠI CHẬM TRÊN GIÀN",
            step_attribute: "",
            txID:
              "0x9b2fcf4f7bba63e9ad1b141f45624cabdd897acddb692f1de26ad9292f11728e",
            from_name: "Cà phê MAYACA",
            step_desc:
              "Phơi sấy khô cafe để loại bỏ nhẹ nhàng và đồng đều lượng thủy phần có trong cafe",
            step_icon: "rang.svg",
            images:  [{url: "https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/phoi_kgisje.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "BẢO QUẢN HẠT NHÂN 3 THÁNG",
            step_attribute: "",
            txID:
              "0x3450cd3d548faf0916e67d4438ddf3747110ea75ae095cee9d6b00cf791515af",
            from_name: "Cà phê MAYACA",
            step_desc: "Làm giảm nhiệt độ và độ ẩm của cafe",
            step_icon: "warehouse.svg",
            images:  [{url: "https://res.cloudinary.com/agridential/image/upload/v1569294611/Portal/u1_jukze9.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "RANG",
            step_attribute: "",
            txID:
              "0x390b28f5479993f53579e027f11a666255eb73debc13cb7e4e820a9a330c3075",
            from_name: "Cà phê MAYACA",
            step_desc:
              "Là quá trình tác động bằng nhiệt độ làm thay đổi đặc tính hóa học của hạt cà phê. Hạt cà phê khi được rang sẽ liên tục bốc hơi nước, teo nhỏ lại sau đó nở ra và đạt ngưỡng thể tích tối đa tại mức nhiệt độ 240 - 250 độ C. Hạt cà phê khi được rang sẽ tỏa ra hương thơm đặc trưng, hạt sẽ trở nên giòn, xốp và dễ xay.",
            step_icon: "rang.svg",
            images:  [{url: "https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/rang_fbhdb1.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "Ủ HẠT CAFE SAU RANG",
            step_attribute: "",
            txID:
              "0xa85f496485dcf9901a7ad93805e9b848bb83943fe8e121cc17e64aafba9390a0",
            from_name: "Cà phê MAYACA",
            step_desc: "Là quá trình bảo quản hương vị của hạt cafe sau khi rang",
            step_icon: "warehouse.svg",
            images:  [{url: "https://res.cloudinary.com/agridential/image/upload/v1569294611/Portal/u2_uwdqtg.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "SƠ CHẾ VÀ ĐÓNG GÓI",
            step_attribute: "",
            txID:
              "0x832bd10c692ca93e8f3969d14880c3c34bdb3751748f45bb9736fd0498bf54a3",
            from_name: "Cà phê MAYACA",
            step_desc:
              "Để nguyên hạt cafe hoặc xay ra để đóng gói. Sau đó sẽ được dán tem Blockchain truy xuất nguồn gốc và các gói cafe chung một vườn sẽ được đóng gói chung một thùng.",
            step_icon: "delivery-package-opened.png",
            images:  [{url: "https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/thanhpham_k3esn1.jpg"}]
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1566552931000,
            step: "GIAO HÀNG",
            step_attribute: "",
            txID:
              "0x99277a0bdb2fefe9b15d7fc24a27ed49f530739c0f84c1fe437aa1daa2b59490",
            from_name: "Cà phê MAYACA",
            step_desc:
              "Công ty TNHH MTV Cà phê MAYACA giao hàng cho công ty Cổ phần Việt Nam Blockchain Corporation",
            step_icon: "delivery.svg",
            images:  [],
            moreInfo: {
              gps: {
                coords: {
                  speed: 0,
                  heading: 0,
                  accuracy: 22.746999740600586,
                  altitude: 26,
                  longitude: 108.1703547,
                  latitude: 16.074192,
                  from_name: "testnet34"
                }
              }
            }
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1566661161000,
            step: "NHẬN HÀNG",
            step_attribute: "",
            txID:
              "0xe75751830e3c45f3dff48920438ace4e597cccae22e2bd016f5f422d46e23b36",
            from_name: "Công ty Cổ phần VietNam Blockchain ",
            step_desc:
              "Công ty Cổ phần Việt Nam Blockchain nhận hàng từ công ty TNHH MTV Cà phê MAYACA",
            step_icon: "giaohang.svg",
            images:  [],
            moreInfo: {
              gps: {
                coords: {
                  speed: 0,
                  heading: 0,
                  accuracy: 22.4060001373291,
                  altitude: 26.100000381469727,
                  longitude: 106.6541971,
                  latitude: 10.7639901,
                  from_name: "testnet34"
                }
              }
            }
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1568283313000,
            step: "GIAO HÀNG",
            step_attribute: "",
            txID:
              "0xbe4fb3e640a3da4297d8363745a094b7a9b26fd25d3e011dcd311ddebc861ccd",
            from_name: "Công ty Cổ phần VietNam Blockchain ",
            step_desc:
              "Công ty Cổ phần Việt Nam Blockchain vận chuyển đến Trung Tâm Hội Nghị PYTOPIA",
            step_icon: "delivery.svg",
            images:  [],
            // moreInfo: {
            //   gps: {
            //     coords: {
            //       speed: 0,
            //       heading: 0,
            //       accuracy: 22.4060001373291,
            //       altitude: 26.100000381469727,
            //       longitude: 106.6541971,
            //       latitude: 10.7639901,
            //       from_name: "testnet34"
            //     }
            //   }
            // }
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1568532917000,
            step: "NHẬN HÀNG",
            step_attribute: "",
            txID:
              "0x665408c27d88bbbb52f895313d87b6501cb296518a679d27536ba5f1254c9445",
            from_name: "Trung Tâm Hội Nghị PYTOPIA",
            step_desc:
              "",
            step_icon: "delivery-package-opened.png",
            images:  [],
            moreInfo: {
              gps: {
                coords: {
                  speed: 0,
                  heading: 0,
                  accuracy: 22.4060001373291,
                  altitude: 26.100000381469727,
                  longitude: 109.3061933,
                  latitude: 13.1007921,
                  from_name: "testnet34"
                }
              }
            }
          }
        ]
      ]
    ]
  },
  en: {
    type: "COFFEE",
    objectInfo: {
      idCode: "8938522156580"
    },
    farmInfo: {
      gln: "89360714400130001",
      farm_name: "Nguyễn Văn Mách",
      scope: "60 cây",
      txID:
        "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
      id: null
    },
    cooperativeInfo: {
      email: "Info@mayacacoffee.com",
      name: "Single share-holder limited company Mayaca",
      gln: "8936071440006",
      address: "53 Nguyen Thi Thap, Hoa Minh, Lien Chieu, Da Nang",
      standard: "FDA",
      phone: " (84-277) 3852 374 - 3852 206",
      website: "mayacacoffee.com",
      txId:
        "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
      id: null
    },
    productInfo: {
      name: "MaYaCa INSIDE GI (1KG)",
      type: "F",
      description:
        "Mayaca Good Inside (GI) is the core product of Mayaca Coffee with twice-fermentation technology in a special coffee flavor and preserves the value of coffee beans.",
      process_code: "R1",
      create_date: 1554460325003,
      owner_gln: "8936071440006",
      trace_cycle: "y",
      TABLE_NAME: "8936071440037",
      sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
      status: 0,
      standard: "FDA",
      "expiredDate ": "1 year",
      gtin: "0000000000000",
      blockchain:
        "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
      index: "1",
      prefix: "003",
      __v: 0,
      id: null,
      image: "",
      certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577067633/samples/certificates/antoancoffee_oo6qzu.jpg"}]
    },
    distributor: [
      {
        address: "4th floor, Lu Gia Plaza, 70 Lu Gia, Ward 15, District 11, Ho Chi Minh City",
        description: "Vietnam Blockchain Corporation (VBC) is a company providing solutions and services on blockchain technology for key areas such as Agriculture, Manufacturing, Supply Chain, Logistics, E-Commerce, Sharing Economics, Financial Technology, Public Services and Smart Cities.",
        email: "contact@vietnamblockchain.asia",
        name: "VIETNAM BLOCKCHAIN CORPORATION",
        phone: "0908 444 095",
        uuid: "F000001",
        website: "vietnamblockchain.asia",
        _id: "5d9787e8a930ee415745b183",
        branchs: []
      }],
    diary: [
      [
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            step: "REGISTER COFFEE TREE",
            from_id: "89360714400130001",
            date: 1546387200000,
            txID:
              "0x918b171aeec3af6a61807021dbb99c75aff11991bc4b9a8cbd7155d76ca934e7",
            from_name: "MAYACA Coffee",
            step_desc:
              "Plants are registered to apply Blockchain traceability solution and are granted GS1 standard identification code.",
            step_attribute: "",
            step_icon: "register.svg",
            images: []
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1546473600000,
            detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
            step: "PICKING RIPE FRUIT",
            step_attribute: "",
            txID:
              "0x87381c4694fc18e45da345821fac9c8b5c94cdd9eaba733117bf66e422956071",
            from_name: "MAYACA Coffee",
            step_desc:
              "Plants are registered to apply Blockchain traceability solution and are granted GS1 standard identification code.",
            step_icon: "picking.svg",
            images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/haitrai_hzd6rs.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1546473600000,
            step: "FERMENTING ANAEROBIC",
            step_attribute: "",
            txID:
              "0x2a5002a14e02d8f1ac29caa26193f47264a8ab1856c8a4c7878baaa49f4c359f",
            from_name: "MAYACA Coffee",
            step_desc: "Fermenting anaerobic",
            step_icon: "fermentation.svg",
            images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/laynhan_scb4qd.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1546560000000,
            detail: "",
            step: "DRYING SLOWLY",
            step_attribute: "",
            txID:
              "0x9b2fcf4f7bba63e9ad1b141f45624cabdd897acddb692f1de26ad9292f11728e",
            from_name: "MAYACA Coffee",
            step_desc:
              "Drying the coffee to gently and uniformly remove the amount of water in the coffee",
            step_icon: "rang.svg",
            images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/phoi_kgisje.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "PRESERVING 3 MONTHS",
            step_attribute: "",
            txID:
              "0x3450cd3d548faf0916e67d4438ddf3747110ea75ae095cee9d6b00cf791515af",
            from_name: "MAYACA Coffee",
            step_desc: "Reducing the temperature and humidity of coffee",
            step_icon: "warehouse.svg",
            images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569294611/Portal/u1_jukze9.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "ROASTED",
            step_attribute: "",
            txID:
              "0x390b28f5479993f53579e027f11a666255eb73debc13cb7e4e820a9a330c3075",
            from_name: "MAYACA Coffee",
            step_desc:
              "A process of temperature impact that changes the chemical properties of coffee beans. When roasted coffee beans will continuously evaporate water, shrink and then expand and reach the maximum volume at the temperature of 240 - 250 degrees. Coffee beans when roasted will emit a characteristic aroma The seeds will become brittle, spongy and easy to grind.",
            step_icon: "rang.svg",
            images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/rang_fbhdb1.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "GRAIN COFFEE INCUBATION",
            step_attribute: "",
            txID:
              "0xa85f496485dcf9901a7ad93805e9b848bb83943fe8e121cc17e64aafba9390a0",
            from_name: "MAYACA Coffee",
            step_desc:
              "It is the process of preserving the flavor of coffee beans after roasting",
            step_icon: "warehouse.svg",
            images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569294611/Portal/u2_uwdqtg.jpg"}]
          },
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1555545600000,
            step: "PROCESSING AND PACKING",
            step_attribute: "",
            txID:
              "0x832bd10c692ca93e8f3969d14880c3c34bdb3751748f45bb9736fd0498bf54a3",
            from_name: "MAYACA Coffee",
            step_desc:
              "Leave the whole grain of coffee or grind it to pack. After that, Blockchain stamps are traced back to the origin and the same cafe package will be packed together.",
            step_icon: "delivery-package-opened.png",
            images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569294610/Portal/thanhpham_k3esn1.jpg"}]
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1566552931000,
            step: "Delivery",
            step_attribute: "",
            txID:
              "0x99277a0bdb2fefe9b15d7fc24a27ed49f530739c0f84c1fe437aa1daa2b59490",
            from_name: "MAYACA Coffee",
            step_desc:
              "MAYACA Coffee One Member Company Limited delivers goods to Vietnam Blockchain Corporation",
            step_icon: "delivery.svg",
            images:  [],
            moreInfo: {
              gps: {
                coords: {
                  speed: 0,
                  heading: 0,
                  accuracy: 22.746999740600586,
                  altitude: 26,
                  longitude: 108.1703547,
                  latitude: 16.074192,
                  from_name: "testnet34"
                }
              }
            }
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1566661161000,
            step: "Receive",
            step_attribute: "",
            txID:
              "0xe75751830e3c45f3dff48920438ace4e597cccae22e2bd016f5f422d46e23b36",
            from_name: "Vietnam Blockchain Corporation",
            step_desc:
              "Vietnam Blockchain Corporation receives inventories from MAYACA Coffee One Member Company Limited",
            step_icon: "giaohang.svg",
            images:  [],
            moreInfo: {
              gps: {
                coords: {
                  speed: 0,
                  heading: 0,
                  accuracy: 22.4060001373291,
                  altitude: 26.100000381469727,
                  longitude: 106.6541971,
                  latitude: 10.7639901,
                  from_name: "testnet34"
                }
              }
            }
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1568283313000,
            step: "Delivery",
            step_attribute: "",
            txID:
              "0xbe4fb3e640a3da4297d8363745a094b7a9b26fd25d3e011dcd311ddebc861ccd",
            from_name: "Vietnam Blockchain Corporation",
            step_desc:
              "Vietnam Blockchain Corporation transports to PYTOPIA Convention Center",
            step_icon: "delivery.svg",
            images:  [],
            // moreInfo: {
            //   gps: {
            //     coords: {
            //       speed: 0,
            //       heading: 0,
            //       accuracy: 22.4060001373291,
            //       altitude: 26.100000381469727,
            //       longitude: 106.6541971,
            //       latitude: 10.7639901,
            //       from_name: "testnet34"
            //     }
            //   }
            // }
          }
        ],
        [
          {
            business_steps: "Truy xuất nguồn gốc coffee",
            from_id: "89360714400130001",
            date: 1568532917000,
            step: "Receive",
            step_attribute: "",
            txID:
              "0x665408c27d88bbbb52f895313d87b6501cb296518a679d27536ba5f1254c9445",
            from_name: "PYTOPIA Convention Center",
            step_desc:
              "",
            step_icon: "delivery-package-opened.png",
            images:  [],
            moreInfo: {
              gps: {
                coords: {
                  speed: 0,
                  heading: 0,
                  accuracy: 22.4060001373291,
                  altitude: 26.100000381469727,
                  longitude: 109.3061933,
                  latitude: 13.1007921,
                  from_name: "testnet34"
                }
              }
            }
          }
        ]
      ]
    ]
  }
};
const Sample_Garlic_Info = {
  type: "GARLIC",
  objectInfo: {
    idCode: "8936097080002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "lienhe@fnc.vn",
    name: "Công ty Cổ phần Tư vấn Phát triển Thực phẩm và Dinh dưỡng",
    gln: "",
    address:
      "Số 43, ngõ 2, phố Tây Trà, Phường Trần Phú, Quận Hoàng Mai, Hà Nội",
    standard: "FDA",
    phone: "0945365247 (HN) - 0912135766 (HCM)",
    website: "toidenlinhdan.vn",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  productInfo: {
    name: "Hũ nhân tỏi đen mật ong",
    type: "F",
    description:
      "Được sản xuất từ các củ tỏi cô đơn và mật ong rừng nguyên chất, tỏi đen mật ong Linh Đan của FNC mang trong từng tép tỏi không chỉ công nghệ thực phẩm hiện đại của Nhật Bản, Hàn Quốc mà còn là niềm tự hào của tỏi tía Việt, qua nắng gió nhiệt đới đã chắt lọc những tinh hoa từ đất mẹ, được vun bồi bởi bàn tay cần mẫn của người nông dân. Các tép tỏi đen lên men từ tỏi tía không chỉ giàu hoạt tính sinh học hơn hẳn mà còn có vị ngọt dịu và mùi thơm dễ chịu, không còn mùi hăng của tỏi.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "24 tháng",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577068277/samples/certificates/toilinhdanh_plm2ok.png"}]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc Tỏi đen Linh Đan",
          step: "CHỌN NGUYÊN LIỆU",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x799dd0ad49d322b4926a8655245e91dd317686fa8c7c35835912bac5aa71a2a3",
          from_name: "Tỏi đen Linh Đan",
          step_desc:
            "Tỏi đủ tuổi, không già, không non, hương vị đậm đà và nhiều tinh dầu, nhiều chất khoáng, protein mới tạo ra được tỏi đen chuẩn.",
          step_attribute: "",
          step_icon: "garlic.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Tỏi đen Linh Đan",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "SƠ CHẾ NGUYÊN LIỆU",
          step_attribute: "",
          txID:
            "0xe21236652ba10b8c632c119b3e974ddccad244de5162bce4a96d044e9e0117db",
          from_name: "Tỏi đen Linh Đan",
          step_desc:
            "Tỏi cần được sơ chế, loại bỏ tạp chất, vỏ xấu bên ngoài, bỏ đầu thừa bỏ đuôi, hiệu quả nhất vẫn là tuyển chọn thủ công từng củ một.",
          step_icon: "picking.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Tỏi đen Linh Đan",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "CHUẨN BỊ VÀO LÒ LÊN MEN",
          step_attribute: "",
          txID:
            "0x415558e97def2d8751cca1e9434a6a20efe9beabcf029d0e6e4e29c2cff64e3b",
          from_name: "Tỏi đen Linh Đan",
          step_desc:
            "Xếp tỏi vào khay inox chuyên dụng cùng với thiết bị lên men tỏi đen, không dùng khay bằng vật liệu dễ cháy nổ (tre, gỗ) hoặc inox có chất lượng thấp để tránh bị ăn mòn.",
          step_icon: "sort.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Tỏi đen Linh Đan",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "CHỈNH THÔNG SỐ VÀ LÊN MEN",
          step_attribute: "",
          txID:
            "0xd31152cb3935e0d4ec656bd3834ca7f949ce99de1f2be491aa5b8393c4b0951c",
          from_name: "Tỏi đen Linh Đan",
          step_desc:
            "Nhiệt độ len men có thể dao động từ 65 - 70 độ C. Cần đảm bảo các thông số nhiệt độ và độ ẩm được giữ trong mức mong muốn. Thời gian lên men tỏi khoảng 40-45 ngày, thêm giai đoạn ổn định chất lượng cần 7-10 ngày, như vậy tổng kết khoảng 60 ngày. Lâu mau cũng tùy thuộc vào loại nguyên liệu.",
          step_icon: "oven.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Tỏi đen Linh Đan",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "RA LÒ, XỬ LÝ BÁN THÀNH PHẨM",
          step_attribute: "",
          txID:
            "0xd1a1480e79c5e345ec95df1d0885b233d3f17d1e0d8db1057200b818aefdd0b5",
          from_name: "Tỏi đen Linh Đan",
          step_desc:
            "Tiến hành đánh giá chất lượng tỏi đen, kiểm tra các chỉ tiêu chất lượng theo tiêu chuẩn cảm quan, hóa lý, hóa sinh.",
          step_icon: "test.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Tỏi đen Linh Đan",
          from_id: "89360714400130001",
          date: 1546473600000,
          detail: "Lần 1: Phân hữu cơ \n Lần 2: Phân NPK (16-16-8)",
          step: "BAO GÓI VÀ BẢO QUẢN",
          step_attribute: "",
          txID:
            "0xfe8dc0138927751d1b52c931be92952213689dc21912d49b68c8064ad6108915",
          from_name: "Tỏi đen Linh Đan",
          step_desc:
            "Cân định lượng và cho tỏi vào hộp kín, hũ kín theo các quy cách khác nhau. Trong điều kiện khô mát, tỏi đen có thể bảo quản được đến 2 năm mà không bị hư hỏng.",
          step_icon: "harvest.svg",
          images: []
        }
      ]
    ]
  ]

};

const Sample_Honey_Info = {
  type: "HONEY",
  objectInfo: {
    idCode: "8938514080008"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "",
    name: "Doanh nghiệp tư nhân Na Ni",
    gln: "",
    address: "22/65 Lê Lợi, TP Quảng Ngãi, Tỉnh Quảng Ngãi",
    standard: "FDA",
    phone: "0911 047 557",
    website: "matongsach.com",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  productInfo: {
    name: "Mật ong sạch Na Ni chai thủy tinh",
    type: "F",
    description:
      "100% sản phẩm mật ong sạch Na Ni từ thiên nhiên, mang đến cho người tiêu dùng sản phẩm mật ong chất lượng nhất. Luôn cam kết 3 không với khách hàng: Không nhiễm đường mía, Không nhiễm thuốc kháng sinh, Không nhiễm thuốc bảo vệ thực vật. Sản phẩm mật ong sạch Na Ni đã được kiểm nghiệm và chứng nhận đạt tiêu chuẩn tốt cho người sử dụng. Sản phẩm đạt giải nhất cuộc thi khởi nghiệp tỉnh Quảng Ngãi năm 2017. ",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "3 năm từ ngày sản xuất",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577073806/samples/certificates/chungnhannani1_sz6utc.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1577073811/samples/certificates/chungnhannani2_kl7tbw.jpg"}]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc Mật ông Na Ni",
          step: "THU HOẠCH MẬT ONG",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x7c61e750e20c275895f0277900285674a0afc9022caa2434e36dfbb1defa438f",
          from_name: "Mật ong Na Ni",
          step_desc:
            "Thu hoạch mật ong phụ thuộc vào thời tiết, nếu trời mưa thì mất khoảng 10 ngày, còn trời nắng thì mất khoảng 01 tháng.",
          step_attribute: "",
          step_icon: "honeycomb.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569295830/Portal/thuhoachmatong_jqjdat.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Mật ông Na Ni",
          step: "KIỂM TRA MẬT ONG",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x5d6e88cce346de7dde0d7dd9afa27c0baf14ba9fb40e47ed2782133f60a85898",
          from_name: "Mật ong Na Ni",
          step_desc:
            "Mật ong chất lượng cần phải được kiểm tra lượng đường mía, thuốc bảo vệ thực vật và thuốc kháng sinh (đảm bảo: đường nhỏ hơn 5%, bảo vệ thực vật nhỏ hơn 10 microgam/kg).",
          step_attribute: "",
          step_icon: "test.svg",
          images: [{url: "https://res.cloudinary.com/agridential/image/upload/v1569295827/Portal/chungnhan_timcja.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Mật ông Na Ni",
          step: "TIẾN HÀNH LỌC THÔ",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x5ea0406ddc9e81da9c59a10f29fa12aa47e19114fabd5a5724bdba4c3b8c43d9",
          from_name: "Mật ong Na Ni",
          step_desc: "Lọc thô mật ong trong khoảng 10 phút.",
          step_attribute: "",
          step_icon: "filter.svg",
          images: [{url:"https://res.cloudinary.com/agridential/image/upload/v1569295829/Portal/saytho_d0k70o.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Mật ông Na Ni",
          step: "SẤY LẠNH TÁCH NƯỚC KHỎI MẬT ONG",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0xab8d26349851deb731ba0f2b1f31064ca8a7f0edd1558314db255dc6b91e40ab",
          from_name: "Mật ong Na Ni",
          step_desc:
            "Đối với 1 mẻ 150kg, việc sấy lạnh yêu cầu phải diễn ra khoảng 3 tiếng.",
          step_attribute: "",
          step_icon: "cold.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569295829/Portal/saylanh_okhht2.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Mật ông Na Ni",
          step: "ĐƯA ĐI DIỆT MEN, NẤM VÀ LỌC TINH",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x4a90773a9b676f1e2409edbefc0307a063c0efac5b76cc42ca9a9ecfd1477546",
          from_name: "Mật ong Na Ni",
          step_desc:
            "Đối với 1 mẻ 150kg, việc diệt men, nấm và lọc tinh mất khoảng 1 tiếng.",
          step_attribute: "",
          step_icon: "oven.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569295829/Portal/dietkhuan_kpn4tw.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Mật ông Na Ni",
          step: "ĐÓNG CHAI VÀ TIÊU THỤ",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x3be86c13e01c86b01acfed08a5343f97325fcf873e72a6baa1d06087fbc929a8",
          from_name: "Mật ong Na Ni",
          step_desc: "Mật ong được đóng vào chai và dán nhãn",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569295829/Portal/dongchai_aslda7.png"}]
        }
      ]
    ]
  ]
};

const Sample_Alcohol_Info = {
  type: "ALCOHOL",
  objectInfo: {
    idCode: "893852479012"
  },
  farmInfo: {
    gln: "893852479",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "",
    name: "HTX Nông - Lâm nghiệp - Thương mại & Dịch vụ Thanh Tâm",
    gln: "893852479",
    address: "Thôn Mô Pả xã Đăk Hà huyện Tu Mơ Rông tỉnh Kon Tum",
    standard: "FDA",
    phone: "0977 07 39 49",
    website: "",
    txId: "0x61e926527192d7e5b97fba4d913bdecb4b267bc90fd504caf240624a455608fd",
    id: null
  },
  productInfo: {
    name: "Rượu Đẳng Sâm",
    type: "F",
    description:
      "Giúp giảm Stress, chống trầm cảm, đồng thời tăng cường trí nhớ, làm giảm các triệu chứng như hoa mắt, đau đầu, chóng mặt, ... Đặc biệt tốt với các bệnh hô hấp, viêm xoang, dị ứng ... và còn tăng cường sức đề kháng cho cơ thể.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577073553/samples/certificates/cert_myxuong_spnongnghieptieubieu_lfkgei.jpg"}]
  },
  diary: [
    [

      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "TRỒNG SÂM",
          from_id: "89360714400130001",
          date: 1493774064000,
          txID:
            "0x4115b694fcde501e98be624d5235c2531e4f8f1108595ed0f6c829aba91cac86",
          from_name: "HTX Nông - Lâm nghiệp - Thương mại & Dịch vụ Thanh Tâm",
          step_desc:
            "Quá trình trồng bao gồm những công việc như sau: Đào, cuốc đất / Đập nát đất cục/ Gieo giống",
          step_attribute: "- Trồng tự nhiên, không theo luống\n- Không sử dụng bất kỳ phân bón hoặc thuốc\n- Sinh trưởng trong khoảng 1 năm",
          step_icon: "tree.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569236912/Portal/trongsam_me9svy.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "CHĂM SÓC",
          from_id: "89360714400130001",
          date: 1494829224000,
          txID:
            "0x204d923218db16ca6ad1296b4465890504c9d1c5e71cf367049c43deb121247c",
          from_name: "Nguyễn Văn Thu",
          step_desc:
            "Chăm sóc cây, đảm bảo cây có đầy đủ dinh dưỡng cần thiết để phát triển một cách tốt nhất",
          step_attribute: "Làm cỏ, dọn dẹp/Tưới nước",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569236911/Portal/chamsoc_vmlhkz.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1528338230000,
          txID:
            "0x71d9cc2ab192ee524f36d26c90284856520718678ad0b980c8597e4bb05af67b",
          from_name: "Nguyễn Văn Thu",
          step_desc:
            "Sâm sau khi thu hoạch sẽ được rửa sạch, phơi khô 1 - 2 nắng và đựng trong rổ",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569236911/Portal/thuhoach_elbgsg.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "NẤU VÀ Ủ RƯỢU",
          from_id: "89360714400130001",
          date: 1531019614000,
          txID:
            "0x95a029d22b365e4975e5563b1508533a3ae19eb44e3e86e052d3ac49aec03401",
          from_name: "HTX Nông - Lâm nghiệp - Thương mại & Dịch vụ Thanh Tâm",
          step_desc:
            "Ủ khoảng 1 năm",
          step_attribute: "",
          step_icon: "filter.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569236912/Portal/nauvauruou_mdtdqo.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "ĐÓNG CHAI",
          from_id: "89360714400130001",
          date: 1564730490000,
          txID:
            "0x9f3743aac59a82b23151bff01bbcdbd667042557b70f9095dc47bbfa765529ff",
          from_name: "HTX Nông - Lâm nghiệp - Thương mại & Dịch vụ Thanh Tâm",
          step_desc: "Dán tem TXNG rượu Đẳng Sâm, kích hoạt tem",
          step_attribute: "",
          step_icon: "bottle.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569236909/Portal/dongchai_xvhbbn.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "ĐÓNG THÙNG",
          from_id: "89360714400130001",
          date: 1565144723000,
          txID:
            "0x2078b658d4394ac89d91ed4ef4cba656fac03c5faf1a00c7ea6870a3088654a0",
          from_name: "HTX Nông - Lâm nghiệp - Thương mại & Dịch vụ Thanh Tâm",
          step_desc: "Đóng thùng & Dán tem thùng Blockchain",
          step_attribute: "Quy cách: 20 chai mỗi thùng",
          step_icon: "delivery-package-opened.png",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569236909/Portal/dongthung_ix4tdh.png"}]
        },
      ],
    ]
  ]
};

const Sample_Box_Info = {
  type: "BOX",
  objectInfo: {
    idCode: "893852479012",
    type: "BOX"
  },
  farmInfo: {
    gln: "893852479",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "info@ptfarm.vn",
    name: "Công ty TNHH Sản xuất - Thương mại Nông Sản Phong Thúy",
    gln: "5801195991",
    address: "Lê Hồng Phong, tổ 27, Liên Nghĩa, Đức Trọng, Lâm Đồng",
    standard: "FDA",
    phone: "02633844095",
    website: "https://ptfarm.vn/",
    txId: "0x76a368d5f04237332139d03e2ed73237b5a1399aa5cb140b90788a07b200cc37",
    id: null
  },
  productInfo: {
    name: "Rượu Đẳng Sâm",
    type: "F",
    description:
      "Giúp giảm Stress, chống trầm cảm, đồng thời tăng cường trí nhớ, làm giảm các triệu chứng như hoa mắt, đau đầu, chóng mặt, ... Đặc biệt tốt với các bệnh hô hấp, viêm xoang, dị ứng ... và còn tăng cường sức đề kháng cho cơ thể.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  mappingInfo: {
    id: "89385247900270000000001"
  },
  boxInfo: {
    status: "Lưu kho"
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "TRỒNG",
          from_id: "89360714400130001",
          date: 1495959567000,
          txID:
            "0x10aa494382e6d2ae4464e1ba46b758c108eed1663f88855d938bbb4558fcbb75",
          from_name: "Cơ sở Thanh Tâm",
          step_desc:
            "Quá trình trồng bao gồm những công việc như sau: chở cây, bón phân, trồng cây, tưới nước, nghiệm thu.",
          step_attribute: "",
          step_icon: "tree.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "CHĂM SÓC",
          from_id: "89360714400130001",
          date: 1520500767000,
          txID:
            "0x8436f130e63d31c2a4109327db8e75b6916f6a49666a9132235f4a384538bb4b",
          from_name: "Cơ sở Thanh Tâm",
          step_desc:
            "Chăm sóc cây, đảm bảo cây có đầy đủ dinh dưỡng cần thiết để phát triển một cách tốt nhất",
          step_attribute: "",
          step_icon: "picking.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rượu sâm",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1528435167000,
          txID:
            "0x329846bcd872c16b08981eeefe2debde7b3d98ba9210d35dba42b5031fa1719d",
          from_name: "Cơ sở Thanh Tâm",
          step_desc:
            "Thu hoạch sản phẩm và đảm bảo các sản phẩm thu hoạch đạt yêu cầu về chất lượng.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images: []
        },
      ],
     [
      {
        business_steps: "Truy xuất nguồn gốc cà chua",
        step: "SƠ CHẾ VÀ BẢO QUẢN LẠNH",
        from_id: "89360714400130001",
        date: 1562763603000,
        txID:
          "0x29fdfabe54f1e664670e2cb2f1121a3ad620823a82cda8e0f9a92287c39607e6",
        from_name: "Công ty Phong Thúy",
        step_desc:
          "Tiếp nhận -> Rửa -> Làm khô -> Phân loại -> Đóng hộp -> Dán tem HTX -> Lưu trữ trong kho lạnh của hợp tác xã",
        step_attribute: "",
        step_icon: "snowflake.svg",
        images: []
      },
     ],
     [
      {
        business_steps: "Truy xuất nguồn gốc cà chua",
        step: "GIAO HÀNG",
        from_id: "89360714400130001",
        date: 1562835600000,
        txID:
          "0x3ee2c60c6227169ca4f2f1e17940c1dbef1327d566fa8d6bee13f756aa06ab56",
        from_name: "Công ty Phong Thúy",
        step_desc:
          "Giao hàng đến nhà bán lẻ",
        step_attribute: "Siêu thị Lotte",
        step_icon: "delivery-package-opened.png",
        images:  [ {url: "https://res.cloudinary.com/agridential/image/upload/v1569236103/Portal/vanchuyencachua_grdsl7.jpg"},{url: "https://res.cloudinary.com/agridential/image/upload/v1569236111/Portal/vanchuyencachua1_mqsdq2.png"}]
      },
     ],
    ] 
  ]
};
// const Sample_Tomato_Info_3 = {
//   type: "TOMATO",
//   objectInfo: {
//     idCode: "8938522156583"
//   },
//   farmInfo: {
//     gln: "89360714400130001",
//     farm_name: "Nguyễn Văn Mách",
//     scope: "60 cây",
//     txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
//     id: null
//   },
//   cooperativeInfo: {
//     email: "phong-farm@vnn.vn",
//     name: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
//     gln: "5801195991",
//     address: "Lô nhà trắng, Tổ 20, Liên Nghĩa - Đức Trọng - Lâm Đồng",
//     standard: "FDA",
//     phone: "02633.844711",
//     website: "https://ptfarm.vn/",
//     txId: "0x76a368d5f04237332139d03e2ed73237b5a1399aa5cb140b90788a07b200cc37",
//     id: null
//   },
//   productInfo: {
//     name: "Cà chua cocktail",
//     type: "F",
//     description:
//       "Cà chua bi trái nhỏ, tròn hoặc dài, màu đỏ đều, đặc biệt có vị ngọt nhiều hơn cà chua thường. Cà chua bi cũng rất dễ trồng và cho quả sai. Với những món salad, bạn nên chọn cà chua bi để chế biến. Vì ngoài vị ngọt, cà chua bi còn rất giòn và tươi mát. Ngoài ra, loại cà chua này còn được sử dụng để lấy nước ép, làm mứt, trang trí món ăn cho đẹp mắt",
//     process_code: "R1",
//     create_date: 1554460325003,
//     owner_gln: "8936071440006",
//     trace_cycle: "y",
//     TABLE_NAME: "8936071440037",
//     sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
//     status: 0,
//     standard: "FDA",
//     "expiredDate ": "6 ngày",
//     gtin: "0000000000000",
//     blockchain:
//       "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
//     index: "1",
//     prefix: "003",
//     __v: 0,
//     id: null,
//     image: "cachuacocktail.png"
//   },
//   diary: [
//     [
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc rượu sâm",
//           step: "CHƯNG CẤT RƯỢU GẠO",
//           from_id: "89360714400130001",
//           date: 1528618767000,
//           txID:
//             "0x0c16923560e8f0297c571814b875a091cc799ac9d9fa5bc4787f887dc32358ae",
//           from_name: "Cơ sở Thanh Tâm",
//           step_desc:
//             "Quá trình chưng cất làm tinh khiết chất lỏng và loại bỏ các thành phần pha loãng như nước với mục đích tăng tỷ lệ nồng độ cồn.",
//           step_attribute: "",
//           step_icon: "filter.svg",
//           images: []
//         },
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc rượu sâm",
//           step: "TÁCH ALDEHIT",
//           from_id: "89360714400130001",
//           date: 1529475567000,
//           txID:
//             "0xa2c4226a9286ee1001d73d036d769c6efeb0ae109617dc0e7ca80be4450a669c",
//           from_name: "Cơ sở Thanh Tâm",
//           step_desc: "Loại bỏ Aldehit có trong rượu.",
//           step_attribute: "",
//           step_icon: "filter.svg",
//           images: "ruoudangsam.jpg"
//         },
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc rượu sâm",
//           step: "TÁCH KIM LOẠI NẶNG",
//           from_id: "89360714400130001",
//           date: 1529475567000,
//           txID:
//             "0x83a857057e03629af64f272494946101f8c65cea2d0fe54efdaacb1a216ad88c",
//           from_name: "Cơ sở Thanh Tâm",
//           step_desc: "Loại bỏ kim loại nặng có trong rượu.",
//           step_attribute: "",
//           step_icon: "filter.svg",
//           images: "ruoudangsam.jpg"
//         },
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc rượu sâm",
//           step: "HẠ THỔ RƯỢU",
//           from_id: "89360714400130001",
//           date: 1530079200000,
//           txID:
//             "0x600e21d896e20051a840b9ac51caa99f73620b6f5bb454575282844ca693e93e",
//           from_name: "Cơ sở Thanh Tâm",
//           step_desc: "Ngâm sâm vào lu sành và chôn vào đất.",
//           step_attribute: "",
//           step_icon: "shovel.svg",
//           images: []
//         },
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc rượu sâm",
//           step: "TÁCH BỌT, MÀU",
//           from_id: "89360714400130001",
//           date: 1561615200000,
//           txID:
//             "0x3d2bc1db0c08ddeb45c8c664ac81f40e9480c4ee53fa44afcbaf9d1bf5dd064a",
//           from_name: "Cơ sở Thanh Tâm",
//           step_desc: "Tách bọt và màu khỏi rượu",
//           step_attribute: "",
//           step_icon: "tachbot.svg",
//           images: []
//         },
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc rượu sâm",
//           step: "ĐÓNG CHAI",
//           from_id: "89360714400130001",
//           date: 1561615200000,
//           txID:
//             "0xd0bd8691daeccc394e94ed4620fedffaf7f21917ff313ef12daae424bc7bbe22",
//           from_name: "Cơ sở Thanh Tâm",
//           step_desc: "Rượu được đóng vào chai, dán nhãn và sau cùng là đóng hộp",
//           step_attribute: "",
//           step_icon: "bottle.svg",
//           images: []
//         }
//       ]
//     ]
//   ]
// };

const Sample_Incense_Info = {
  type: "INCENSE",
  objectInfo: {
    idCode: "8938522156583"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "tramhuongphuclinh@gmail.com",
    name: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Châu Thông",
    gln: "",
    address: "1163-1165 đường Nguyễn Tất Thành, TP. Đà Nẵng",
    standard: "FDA",
    phone: "+84-2363 668747 / +84-901.144.119",
    website: "tramhuongphuclinh.vn",
    txId: "0xce96daf06a013dfbf8edea8921345adbcfb282490589d0c8c3b8e2f3448b4acf",
    id: null
  },
  productInfo: {
    name: "Nụ trầm PL đặc biệt",
    type: "F",
    description:
      "Nụ viên xông đốt. 100% nguồn gốc từ thiên nhiên không gây hại sức khỏe con người.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074046/samples/certificates/chungnhantramhuong1_qzjkfm.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1577074051/samples/certificates/chungnhantramhuong2_vb4qhd.jpg"}]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc Trầm hương Phúc Linh",
          step: "CHỌN NGUYÊN LIỆU",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Trầm hương Phúc Linh",
          step_desc: "Từ nguyên liệu cây dó bầu đã tạo trầm",
          step_attribute: "",
          step_icon: "tree.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Trầm hương Phúc Linh",
          step: "XOAY THÀNH BỘT",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x7551e113f900f6cc5ae1fbca9b8c431f2ce39c9622e560e8c67b1983bf78c8f0",
          from_name: "Trầm hương Phúc Linh",
          step_desc: "Xoay nguyên liệu thành bột",
          step_attribute: "",
          step_icon: "grinder.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Trầm hương Phúc Linh",
          step: "TRỘN BỘT",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x95a33e896df498554cf6bcc0ef8590911b059642aa4322ca432a2ee59685042b",
          from_name: "Trầm hương Phúc Linh",
          step_desc: "Trộn bột trầm với keo bời lời (tự nhiên) và nước.",
          step_attribute: "",
          step_icon: "mixer.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Trầm hương Phúc Linh",
          step: "VIÊN THÀNH NỤ",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0xdeda651bf6a6f7f97384b6fcd8ca7f1d9d914eac5e54031b39fa86d55280f610",
          from_name: "Trầm hương Phúc Linh",
          step_desc:
            "Sau khi đã trộn đều, người nghệ nhân tiến hành viên thành viên nụ.",
          step_attribute: "",
          step_icon: "bud.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Trầm hương Phúc Linh",
          step: "PHƠI KHÔ",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x95a33e896df498554cf6bcc0ef8590911b059642aa4322ca432a2ee59685042b",
          from_name: "Trầm hương Phúc Linh",
          step_desc: "Phơi khô dưới nhiệt độ mặt trời.",
          step_attribute: "",
          step_icon: "sun.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Trầm hương Phúc Linh",
          step: "KIỂM TRA CHẤT LƯỢNG",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x581732016a3ff1c2199bbafb23b34d3d0041128d3cb5f9c6ea288b7af7068b0f",
          from_name: "Trầm hương Phúc Linh",
          step_desc:
            "Trước khi vào khâu bao bì đóng gói, bộ phận kiểm tra chất lượng sẽ kiểm tra sản phẩm đạt chất lượng chuẩn rồi đóng gói, thành phẩm. ( Các sản phẩm đều tự nhiên 100%, với các kết quả kiểm định tại trung tâm Quatest 2 công nhận).",
          step_attribute: "",
          step_icon: "test.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569296572/Portal/chungnhanphuclinh1_e8orex.jpg"},  {url:"https://res.cloudinary.com/agridential/image/upload/v1569296572/Portal/chungnhanphuclinh2_kcbfsj.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc Trầm hương Phúc Linh",
          step: "ĐÓNG GÓI THÀNH PHẨM",
          from_id: "89360714400130001",
          date: 1546387200000,
          txID:
            "0x109e5bd1fab16bdb85b5e76e3797b1df8f8b886714c2bb29dbba2a242be7c44f",
          from_name: "Trầm hương Phúc Linh",
          step_desc: "Sản phẩm được đóng gói và dán nhãn.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images: []
        }
      ]
    ]
  ]
};

const Sample_Tomato_Info_1 = {
  type: "TOMATO",
  objectInfo: {
    idCode: "8938522156583"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x2c1736a1bdd181f781963228560fccc324a89675243df175725bc1dc10915456",
    id: null
  },
  cooperativeInfo: {
    email: "phong-farm@vnn.vn",
    name: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
    gln: "5801195991",
    address: "Lô nhà trắng, Tổ 20, Liên Nghĩa - Đức Trọng - Lâm Đồng",
    standard: "FDA",
    phone: "02633.844711",
    website: "ptfarm.vn",
    txId: "0x76a368d5f04237332139d03e2ed73237b5a1399aa5cb140b90788a07b200cc37",
    id: null
  },
  productInfo: {
    name: "Cà chua bi vàng",
    type: "F",
    description:
      "Cà chua bi vàng Đà Lạt có hình dáng nhỏ thuôn dài và có màu vàng pha chút cam rất đẹp mắt. Khi ăn vào cực kỳ ngon, rất sạch và an toàn cho người sử dụng. Cà chu Bi vàng Đà Lạt chứa nhiều Sắt, Phospho, kali, Vitamin B...",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "6 ngày",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "cachuabivang.png",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074686/samples/certificates/chungnhanchuoiantoan_a25eey.jpg"},
    {url: "https://res.cloudinary.com/agridential/image/upload/v1577074577/samples/certificates/cachuabi_bshljq.jpg"},
    {url: "https://res.cloudinary.com/agridential/image/upload/v1577074561/samples/certificates/chungnhanVGcachua_iq3h7o.jpg"}
  ]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "GIEO GIỐNG",
          from_id: "89360714400130001",
          date: 1553498103000,
          txID:
            "0x4bf787127076322321091d104ee24257021aa3c6582cc84a9c736d37ed5fe086",
          from_name: "HTX Phong Thúy",
          step_desc:
            "Để tạo ra giống mới, giống có năng suất và chất lượng cao. HTX sẽ nghiên cứu, tiến hành lai tạo, cấy ghép giống cây mới. Qúa trình gieo giống được tiến hành tự động bằng máy.",
          step_attribute:
            "",
          step_icon: "tomato.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569296942/Portal/gieogiong_czjxjb.jpg"}]
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "CHĂM SÓC",
          from_id: "89360714400130001",
          date: 1554665403000,
          txID:
            "0x7041593e0c04931523133686858d2bbceaf6cb421568e35df14e1b1b8c879db8",
          from_name: "HTX Phong Thúy",
          step_desc:
            "Giống và cây non được đưa đến khu vực chăm sóc đặc biệt, đảm bảo đủ điều kiện về nhiệt độ, ánh sáng, chất dinh dưỡng, lượng nước để phát triển thành cây con khỏe.",
          step_attribute:
            "",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569297495/Portal/chamsocdacbiet_njycxf.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569297494/Portal/chamsoccachua_evbosj.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1562792403000,
          txID:
            "0xb60a2e64031cfc096a858ce6fbabc00c02b39c612ff779d8e8f3bb9649804d8d",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Cà chua sẽ cho thu hoạch sau khi trồng 70 - 75 ngày. Trái có thể được thu hoạch chín hoàn toàn hoặc một phần, thu hoạch chùm hoặc rời tùy thuộc vào loại cà chua và nhu cầu của khách hàng. Bảo quản trái ở nơi thoáng mát, khô ráo.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569297494/Portal/thuhoachcachua_cl1wui.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569297496/Portal/thuhoachcachua2_vp1wws.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "SƠ CHẾ VÀ BẢO QUẢN LẠNH",
          from_id: "89360714400130001",
          date: 1562763603000,
          txID:
            "0x20ac87006f69ca77f9d94e8688824da5f7c59e6abdf8e8ef5f191c33f385a9ab",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Tiếp nhận -> Rửa -> Làm khô -> Chụp hình quả -> Phân tích độ đường, Thống kê số lượng -> Phân loại -> Đóng hộp -> Dán tem HTX -> Lưu trữ trong kho lạnh của hợp tác xã",
          step_attribute: "",
          step_icon: "fruit.svg",
          images: []
        },
      ]
    ]
  ]
};
const Sample_Tomato_Info_2 = {
  type: "TOMATO",
  objectInfo: {
    idCode: "8938522156583"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "info@ptfarm.vn",
    name: "Công ty TNHH Sản xuất - Thương mại Nông Sản Phong Thúy",
    gln: "5801195991",
    address: "Lê Hồng Phong, tổ 27, Liên Nghĩa, Đức Trọng, Lâm Đồng",
    standard: "FDA",
    phone: "02633844095",
    website: "https://ptfarm.vn/",
    txId: "0x76a368d5f04237332139d03e2ed73237b5a1399aa5cb140b90788a07b200cc37",
    id: null
  },
  productInfo: {
    name: "Cà chua cherry",
    type: "F",
    description:
      "Cà chua CHERRY thuộc họ bạch anh, cây thân mềm có thể bò trên mặt đất hoặc leo thường sống ở các vùng có khí hậu mát, là loại thực phẩm thường được sử dụng nhiều trong nấu ăn, chế biến các món trộn hàng ngày",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "6 ngày",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "cachuacherry.png",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074686/samples/certificates/chungnhanchuoiantoan_a25eey.jpg"},
    {url: "https://res.cloudinary.com/agridential/image/upload/v1577074577/samples/certificates/cachuabi_bshljq.jpg"},
    {url: "https://res.cloudinary.com/agridential/image/upload/v1577074561/samples/certificates/chungnhanVGcachua_iq3h7o.jpg"}
  ]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "GIEO GIỐNG",
          from_id: "89360714400130001",
          date: 1553498103000,
          txID:
            "0x4bf787127076322321091d104ee24257021aa3c6582cc84a9c736d37ed5fe086",
          from_name: "HTX Phong Thúy",
          step_desc:
            "Để tạo ra giống mới, giống có năng suất và chất lượng cao. HTX sẽ nghiên cứu, tiến hành lai tạo, cấy ghép giống cây mới. Qúa trình gieo giống được tiến hành tự động bằng máy.",
          step_attribute:
            "",
          step_icon: "tomato.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569296942/Portal/gieogiong_czjxjb.jpg"},]
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "CHĂM SÓC",
          from_id: "89360714400130001",
          date: 1554665403000,
          txID:
            "0x7041593e0c04931523133686858d2bbceaf6cb421568e35df14e1b1b8c879db8",
          from_name: "HTX Phong Thúy",
          step_desc:
            "Giống và cây non được đưa đến khu vực chăm sóc đặc biệt, đảm bảo đủ điều kiện về nhiệt độ, ánh sáng, chất dinh dưỡng, lượng nước để phát triển thành cây con khỏe.",
          step_attribute:
            "",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569297495/Portal/chamsocdacbiet_njycxf.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569297494/Portal/chamsoccachua_evbosj.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1562792403000,
          txID:
            "0xb60a2e64031cfc096a858ce6fbabc00c02b39c612ff779d8e8f3bb9649804d8d",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Cà chua sẽ cho thu hoạch sau khi trồng 70 - 75 ngày. Trái có thể được thu hoạch chín hoàn toàn hoặc một phần, thu hoạch chùm hoặc rời tùy thuộc vào loại cà chua và nhu cầu của khách hàng. Bảo quản trái ở nơi thoáng mát, khô ráo.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569297494/Portal/thuhoachcachua_cl1wui.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569297496/Portal/thuhoachcachua2_vp1wws.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "SƠ CHẾ VÀ BẢO QUẢN LẠNH",
          from_id: "89360714400130001",
          date: 1562763603000,
          txID:
            "0x20ac87006f69ca77f9d94e8688824da5f7c59e6abdf8e8ef5f191c33f385a9ab",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Tiếp nhận -> Rửa -> Làm khô -> Phân loại -> Đóng hộp -> Dán tem HTX -> Lưu trữ trong kho lạnh của hợp tác xã",
          step_attribute: "",
          step_icon: "snowflake.svg",
          images: []
        },
       ],
       [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "GIAO HÀNG",
          from_id: "89360714400130001",
          date: 1562835600000,
          txID:
            "0x20ac87006f69ca77f9d94e8688824da5f7c59e6abdf8e8ef5f191c33f385a9ab",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Giao hàng đến nhà bán lẻ",
          step_attribute: "Siêu thị Lotte",
          step_icon: "delivery-package-opened.png",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569297957/Portal/vanchuyencachua_bjr3p5.jpg"},  {url:"https://res.cloudinary.com/agridential/image/upload/v1569297959/Portal/vanchuyencachua1_qmfx1h.png"}]
        },
       ],
    ]
  ]
};
const Sample_Tomato_Info_3 = {
  type: "TOMATO",
  objectInfo: {
    idCode: "8938522156583"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "phong-farm@vnn.vn",
    name: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
    gln: "5801195991",
    address: "Lô nhà trắng, Tổ 20, Liên Nghĩa - Đức Trọng - Lâm Đồng",
    standard: "FDA",
    phone: "02633.844711",
    website: "https://ptfarm.vn/",
    txId: "0x76a368d5f04237332139d03e2ed73237b5a1399aa5cb140b90788a07b200cc37",
    id: null
  },
  productInfo: {
    name: "Cà chua cocktail",
    type: "F",
    description:
      "Cà chua bi trái nhỏ, tròn hoặc dài, màu đỏ đều, đặc biệt có vị ngọt nhiều hơn cà chua thường. Cà chua bi cũng rất dễ trồng và cho quả sai. Với những món salad, bạn nên chọn cà chua bi để chế biến. Vì ngoài vị ngọt, cà chua bi còn rất giòn và tươi mát. Ngoài ra, loại cà chua này còn được sử dụng để lấy nước ép, làm mứt, trang trí món ăn cho đẹp mắt",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "6 ngày",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "cachuacocktail.png",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074686/samples/certificates/chungnhanchuoiantoan_a25eey.jpg"},
    {url: "https://res.cloudinary.com/agridential/image/upload/v1577074577/samples/certificates/cachuabi_bshljq.jpg"},
    {url: "https://res.cloudinary.com/agridential/image/upload/v1577074561/samples/certificates/chungnhanVGcachua_iq3h7o.jpg"}
  ]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "GIEO GIỐNG",
          from_id: "89360714400130001",
          date: 1553498103000,
          txID:
            "0x4bf787127076322321091d104ee24257021aa3c6582cc84a9c736d37ed5fe086",
          from_name: "HTX Phong Thúy",
          step_desc:
            "Để tạo ra giống mới, giống có năng suất và chất lượng cao. HTX sẽ nghiên cứu, tiến hành lai tạo, cấy ghép giống cây mới. Qúa trình gieo giống được tiến hành tự động bằng máy.",
          step_attribute:
            "",
          step_icon: "tomato.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569296942/Portal/gieogiong_czjxjb.jpg"}]
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "CHĂM SÓC",
          from_id: "89360714400130001",
          date: 1554665403000,
          txID:
            "0x7041593e0c04931523133686858d2bbceaf6cb421568e35df14e1b1b8c879db8",
          from_name: "HTX Phong Thúy",
          step_desc:
            "Giống và cây non được đưa đến khu vực chăm sóc đặc biệt, đảm bảo đủ điều kiện về nhiệt độ, ánh sáng, chất dinh dưỡng, lượng nước để phát triển thành cây con khỏe.",
          step_attribute:
            "",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569297495/Portal/chamsocdacbiet_njycxf.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569297494/Portal/chamsoccachua_evbosj.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1562792403000,
          txID:
            "0xb60a2e64031cfc096a858ce6fbabc00c02b39c612ff779d8e8f3bb9649804d8d",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Cà chua sẽ cho thu hoạch sau khi trồng 70 - 75 ngày. Trái có thể được thu hoạch chín hoàn toàn hoặc một phần, thu hoạch chùm hoặc rời tùy thuộc vào loại cà chua và nhu cầu của khách hàng. Bảo quản trái ở nơi thoáng mát, khô ráo.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569297494/Portal/thuhoachcachua_cl1wui.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569297496/Portal/thuhoachcachua2_vp1wws.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc cà chua",
          step: "SƠ CHẾ VÀ BẢO QUẢN LẠNH",
          from_id: "89360714400130001",
          date: 1562763603000,
          txID:
            "0x20ac87006f69ca77f9d94e8688824da5f7c59e6abdf8e8ef5f191c33f385a9ab",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Tiếp nhận -> Rửa -> Làm khô -> Chụp hình quả -> Phân tích độ đường, Thống kê số lượng -> Phân loại -> Đóng hộp -> Dán tem HTX -> Lưu trữ trong kho lạnh của hợp tác xã",
          step_attribute: "",
          step_icon: "fruit.svg",
          images: []
        },
      ]
    ]
  ]
};
const Sample_Tungho_Info = {
  type: "TUNGHO",
  objectInfo: {
    idCode: "8938522156583"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "",
    name: "Công ty TNHH Sản xuất Thương mại và Dịch vụ Phong Thúy",
    gln: "5801195991",
    address: "Lô nhà trắng, Tổ 20, Liên Nghĩa - Đức Trọng - Lâm Đồng",
    standard: "FDA",
    phone: "02633.844711",
    website: "",
    txId: "0x76a368d5f04237332139d03e2ed73237b5a1399aa5cb140b90788a07b200cc37",
    id: null
  },
  productInfo: {
    name: "Tần ô",
    type: "F",
    description:
      "Tần ô có vị ngọt nhạt, hơi đắng, the, mùi thơm, tính mát, được xem như một loại rau giúp khai vị làm ăn ngon, giúp tiêu hoá, trừ đờm, tán phong nhiệt.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "4 ngày",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074561/samples/certificates/chungnhanVGcachua_iq3h7o.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1577074577/samples/certificates/cachuabi_bshljq.jpg"}]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc tần ô",
          step: "BÓN PHÂN",
          from_id: "89360714400130001",
          date: 1546488904000,
          txID:
            "0x06c25243ee2f1f54e3e286c37f280526acf109cb761bd9058d94e6e9016e2bb3",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Bón lót bằng phân Lân và phân hữu cơ hoai mục. Sau đó bón thúc bằng phân ure để cung cấp chất dinh dưỡng cần thiết cho cây",
          step_attribute: "Super Lân, phân hữu cơ hoai mục, Phân Ure, DAP",
          step_icon: "picking.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc tần ô",
          step: "PHUN XỊT",
          from_id: "89360714400130001",
          date: 1549339204000,
          txID:
            "0xcc22b674a55942732620c61b3b751466d5c80e0370c96ac0dcd91cc7e7204171",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp phòng tránh dịch bệnh và diệt các loại côn trùng gây hại,thuốc có nguồn gốc sinh học,vi sinh có thời gian cách ly ngắn.",
          step_attribute: "Delfin 32 WG, Radiant 60SC, amista 250sc, Daconil 500SC",
          step_icon: "pesticide.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc tần ô",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1551430804000,
          txID:
            "0x9e16918d572a31ff20ad89ae51c26e6e33d6d72eb77759b94aa84af60e9c7973",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Tần ô thu hoạch sau 25-30 ngày từ khi gieo hạt. Khi thu hoạch, rau được xếp gọn gàng vô két nhựa có trải giấy lót và để nơi thoáng mát.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc tần ô",
          step: "SƠ CHẾ VÀ ĐÓNG GÓI",
          from_id: "89360714400130001",
          date: 1551517204000,
          txID:
            "0xb4bb25cff561c1470fb31c0e274cc0c695459c74286437925ca83f0858d8c804",
          from_name: "Công ty Phong Thúy",
          step_desc:
            "Rau được công nhân lặt những lá vàng – hư, cân và xếp gọn gàng thành những bó 350gr. Sau đó đóng vào túi và dán tem.",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images: []
        }
      ]
    ]
  ]
};

const Sample_Spinach_Info = {
  type: "SPINACH",
  objectInfo: {
    idCode: "0000000000008"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  distributor: [
    {
      address: "469 Đường Nguyễn Hữu Thọ, Tân Hưng, Quận 7, Hồ Chí Minh, Vietnam",
      description: "LOTTE Mart là một công ty của tập đoàn LOTTE Hàn Quốc, là chuỗi siêu thị lớn bán nhiều loại hàng tạp hoá, quần áo, đồ chơi, đồ điện tử và các hàng hoá khác.",
      email: "info@lotte.vn",
      name: "CÔNG TY CỔ PHẦN TRUNG TÂM THƯƠNG MẠI LOTTE VIỆT NAM",
      phone: "02837753232",
      uuid: "F000001",
      website: "lottemart.com.vn",
      _id: "5d9787e8a930ee415745b183",
      branchs: [
        {
          address: "469 Đường Nguyễn Hữu Thọ, Tân Hưng, Quận 7, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Quận 7",
          ownedBy: "F000001",
          phone: "02837718989",
          uuid: "0001",
          website: "",
          _id: "5d978891a930ee415745b186"
        },
        {
          address: "The EverRich 1, 968 Ba Tháng Hai, Phường 15, Quận 11, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Quận 11",
          ownedBy: "F000001",
          phone: "02822250686",
          uuid: "0002",
          website: "",
          _id: "5d9788dba930ee415745b187"
        },
        {
          address: "20 Cộng Hòa, Phường 4, Tân Bình, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Tân Bình",
          ownedBy: "F000001",
          phone: "02839489055",
          uuid: "0003",
          website: "",
          _id: "5d978908a930ee415745b188"
        },
        {
          address: "242 Nguyễn Văn Lượng, Phường 17, Gò Vấp, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Gò Vấp",
          ownedBy: "F000001",
          phone: "02838950907",
          uuid: "0004",
          website: "",
          _id: "5d97892ca930ee415745b189",
        }
      ]
    }
  ],
  cooperativeInfo: {
    avatar: [{url: "https://res.cloudinary.com/agridential/image/upload/v1570761503/production/tjjcqze6i1d4qzzj7l1t.png"}],
    email: "vietrat.com@gmail.com",
    name: "HTX Rau An Toàn Việt (VietRAT)",
    gln: "",
    address: "Đường Ấp 4, Cần Đước, Long An, Vietnam",
    standard: "GS1",
    phone: "0908 988 237",
    website: "",
    txId: "0x76a368d5f04237332139d03e2ed73237b5a1399aa5cb140b90788a07b200cc37",
    id: null
  },
  productInfo: {
    name: "Rau muống VietRAT",
    type: "F",
    description:
      "Rau muống không chỉ là một loại rau quen thuộc trong các bữa ăn hàng ngày của người Việt Nam mà còn là một cây thuốc nam quý, Cây mọc bò ở nước hay trên cạn, thân rỗng, dày, có những đốt, mặt ngoài nhẵn. Lá hình ba cạnh, đầu nhọn, nhưng cũng có khi hẹp, và dài. Phiến lá dài 7-9 cm, rộng 3.5-7 cm, cuống lá nhẵn 3-6 cm.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 1,
    standard: "GS1",
    expiredDate: "4 ngày kể từ ngày đóng gói",
    preservation: "Bảo quản ở nhiệt độ thoáng mát",
    guide: "Làm sạch trước khi chế biến",
    weight: "300g",
    gtin: "0000000000000",
    blockchain:
      "0x0227a2744b19e01360da7eedc77f46d51595e99dc8056f2ff06648e41c1a760c",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc rau muống",
          step: "BÓN PHÂN",
          from_id: "89360714400130001",
          date: 1546488904000,
          txID:
            "0x0947aac07ad80d12fad06cad01ad223b6498a1d83a102551b9f6098737ad05d3",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Dùng phân gà tại chỗ, tụ ủ.",
          step_attribute: "Phân bò, phân gà ủ sẵn",
          step_icon: "picking.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau muống",
          step: "PHUN XỊT",
          from_id: "89360714400130001",
          date: 1549339204000,
          txID:
            "0x607be20f551cee7abacbfd09e6d723e6104ebf6e04bcd63f0f4b3f65d2867585",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp phòng tránh dịch bệnh và diệt các loại côn trùng gây hại,thuốc có nguồn gốc sinh học,vi sinh có thời gian cách ly ngắn.",
          step_attribute: "Delfin 32 WG, Radiant 60SC, amista 250sc, Daconil 500SC",
          step_icon: "pesticide.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau muống",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1551430804000,
          txID:
            "0x3aa71b12183991d74e9152b79d267a1dc855796beef043c160bbd41cd971ed46",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Rau thu hoạch sau 25-30 ngày từ khi gieo hạt. Khi thu hoạch, rau được xếp gọn gàng vô két nhựa có trải giấy lót và để nơi thoáng mát.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300188/Portal/bonphanrau_pqnais.png"},  {url:"https://res.cloudinary.com/agridential/image/upload/v1569300187/Portal/vuonraumuong_muqogw.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau muống",
          step: "SƠ CHẾ VÀ ĐÓNG GÓI",
          from_id: "89360714400130001",
          date: 1551517204000,
          txID:
            "0xd567dabb53468a3b820420f9c52333eacd6099f5ead9548d192f1a5d4acc7caa",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Rau được công nhân lặt những lá vàng – hư, cân và xếp gọn gàng thành những bó 300gr. Sau đó đóng vào túi và dán tem.",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300188/Portal/donggoirau_zk0vsq.png"}]
        }
      ],
      
      [
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "GIAO HÀNG",
          from_id: "M000012",
          date: 1571787955000,
          txID:
            "0x0d48e04a2ac90857ccc30f4b6ed184ca326467c4798a11219a832ba7eabff890",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Đơn vị phân phối khi nhận HÀNG sẽ xác nhận bằng cách quét tem chứa mã QR trên mỗi thùng /LÔ HÀNG. Thông tin về đơn vị phân phối cũng như thời gian nhận sẽ được lưu trữ trực tiếp trên Blockchain.",
          step_attribute: "- Nơi nhận: CÔNG TY CỔ PHẦN TRUNG TÂM THƯƠNG MẠI LOTTE VIỆT NAM (Chi nhánh: Siêu thị LOTTE Mart Quận 7)",
          step_icon: "delivery-truck.svg",
          images: []
        }
      ],
    ]
    ]
  
};

const Sample_Herb_Info = {
  type: "HERB",
  objectInfo: {
    idCode: "0000000000009"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    avatar: [{url: "https://res.cloudinary.com/agridential/image/upload/v1570761503/production/tjjcqze6i1d4qzzj7l1t.png"}],
    email: "vietrat.com@gmail.com",
    name: "HTX Rau An Toàn Việt (VietRAT)",
    gln: "",
    address: "Đường Ấp 4, Cần Đước, Long An, Vietnam",
    standard: "GS1",
    phone: "0908 988 237",
    website: "",
    txId: "0x0227a2744b19e01360da7eedc77f46d51595e99dc8056f2ff06648e41c1a760c",
    id: null
  },
  productInfo: {
    name: "Rau thơm hỗn hợp VietRAT",
    type: "F",
    description:
      "Trong ẩm thực, rau thơm hay rau gia vị là khái niệm khái quát dùng để chỉ các loại rau ăn được (có thể là rau, củ, quả thơm), được trồng hoặc hái từ tự nhiên, có mùi thơm đặc biệt tùy theo loại do các tinh dầu trong rau bay hơi tạo thành.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 1,
    standard: "GS1",
    expiredDate: "4 ngày kể từ ngày đóng gói",
    preservation: "Bảo quản ở nhiệt độ thoáng mát",
    guide: "Làm sạch trước khi chế biến",
    weight: "150g",
    gtin: "0000000000000",
    blockchain:
      "0x0227a2744b19e01360da7eedc77f46d51595e99dc8056f2ff06648e41c1a760c",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  distributor: [
    {
      address: "469 Đường Nguyễn Hữu Thọ, Tân Hưng, Quận 7, Hồ Chí Minh, Vietnam",
      description: "LOTTE Mart là một công ty của tập đoàn LOTTE Hàn Quốc, là chuỗi siêu thị lớn bán nhiều loại hàng tạp hoá, quần áo, đồ chơi, đồ điện tử và các hàng hoá khác.",
      email: "info@lotte.vn",
      name: "CÔNG TY CỔ PHẦN TRUNG TÂM THƯƠNG MẠI LOTTE VIỆT NAM",
      phone: "02837753232",
      uuid: "F000001",
      website: "lottemart.com.vn",
      _id: "5d9787e8a930ee415745b183",
      branchs: [
        {
          address: "469 Đường Nguyễn Hữu Thọ, Tân Hưng, Quận 7, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Quận 7",
          ownedBy: "F000001",
          phone: "02837718989",
          uuid: "0001",
          website: "",
          _id: "5d978891a930ee415745b186"
        },
        {
          address: "The EverRich 1, 968 Ba Tháng Hai, Phường 15, Quận 11, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Quận 11",
          ownedBy: "F000001",
          phone: "02822250686",
          uuid: "0002",
          website: "",
          _id: "5d9788dba930ee415745b187"
        },
        {
          address: "20 Cộng Hòa, Phường 4, Tân Bình, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Tân Bình",
          ownedBy: "F000001",
          phone: "02839489055",
          uuid: "0003",
          website: "",
          _id: "5d978908a930ee415745b188"
        },
        {
          address: "242 Nguyễn Văn Lượng, Phường 17, Gò Vấp, Hồ Chí Minh, Vietnam",
          description: "Chưa có",
          images: [],
          name: "Siêu thị LOTTE Mart Gò Vấp",
          ownedBy: "F000001",
          phone: "02838950907",
          uuid: "0004",
          website: "",
          _id: "5d97892ca930ee415745b189",
        }
      ]
    }
  ],
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "XUỐNG GIỐNG",
          from_id: "89360714400130001",
          date: 1561249254000,
          txID:
            "0x433b9966ba827e5140ddb84dc174786f2990fc67dbec477df1a8818614e997cf",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Gieo đều hạt trên các luống.",
          step_attribute: "",
          step_icon: "picking.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "BÓN PHÂN",
          from_id: "89360714400130001",
          date: 1561857325000,
          txID:
            "0x433b9966ba827e5140ddb84dc174786f2990fc67dbec477df1a8818614e997cf",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Dùng phân gà tại chỗ, tụ ủ.",
          step_attribute: "Loại phân: Phân bò, phân gà ủ sẵn",
          step_icon: "fertilizer.svg",
          images: []
        },
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "BÓN PHÂN",
          from_id: "89360714400130001",
          date: 1563585935000,
          txID:
            "0x433b9966ba827e5140ddb84dc174786f2990fc67dbec477df1a8818614e997cf",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Dùng phân gà tại chỗ, tụ ủ.",
          step_attribute: "Loại phân: Phân bò, phân gà ủ sẵn",
          step_icon: "fertilizer.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "PHUN XỊT",
          from_id: "89360714400130001",
          date: 1561860733000,
          txID:
            "0x5840ca3f1ec3562cdb516f55d211ac6a05de0eea759b9d90be7781cdc28bf6a7",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp phòng tránh dịch bệnh và diệt các loại côn trùng gây hại,thuốc có nguồn gốc sinh học,vi sinh có thời gian cách ly ngắn.",
          step_attribute: "Loại thuốc: Delfin 32 WG, Radiant 60SC, amista 250sc, Daconil 500SC",
          step_icon: "pesticide.svg",
          images: []
        },
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "PHUN XỊT",
          from_id: "89360714400130001",
          date: 1563589812000,
          txID:
            "0x5840ca3f1ec3562cdb516f55d211ac6a05de0eea759b9d90be7781cdc28bf6a7",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Phun các loại thuốc nằm trong danh mục cho phép của Bộ Y Tế tại nhiều thời điểm. Giúp phòng tránh dịch bệnh và diệt các loại côn trùng gây hại,thuốc có nguồn gốc sinh học,vi sinh có thời gian cách ly ngắn.",
          step_attribute: "Loại thuốc: Delfin 32 WG, Radiant 60SC, amista 250sc, Daconil 500SC",
          step_icon: "pesticide.svg",
          images: []
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1566552612000,
          txID:
            "0xf44fc9581200ac56a26b2d26ddce11e95398df20987896438cadec80feb9775d",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Rau thu hoạch sau 25-30 ngày từ khi gieo hạt. Khi thu hoạch, rau được xếp gọn gàng vô két nhựa có trải giấy lót và để nơi thoáng mát.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300188/Portal/bonphanrau_pqnais.png"},  {url:"https://res.cloudinary.com/agridential/image/upload/v1569300271/Portal/vuonrauthom_vvlfn4.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "SƠ CHẾ VÀ ĐÓNG GÓI",
          from_id: "89360714400130001",
          date: 1566558929000,
          txID:
            "0x8edb3289877927a43410d1fb1d52f0cd8a80741378f0a7def59f70c84c45ecfe",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Rau được công nhân lặt những lá vàng – hư, cân và xếp gọn gàng thành những bó 150gr. Sau đó đóng vào túi và dán tem.",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images: []
        }
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc rau thơm",
          step: "GIAO HÀNG",
          from_id: "M000012",
          date: 1571787955000,
          txID:
            "0x0d48e04a2ac90857ccc30f4b6ed184ca326467c4798a11219a832ba7eabff890",
          from_name: "HTX Rau An Toàn Việt",
          step_desc:
            "Đơn vị phân phối khi nhận HÀNG sẽ xác nhận bằng cách quét tem chứa mã QR trên mỗi thùng /LÔ HÀNG. Thông tin về đơn vị phân phối cũng như thời gian nhận sẽ được lưu trữ trực tiếp trên Blockchain.",
          step_attribute: "- Nơi nhận: CÔNG TY CỔ PHẦN TRUNG TÂM THƯƠNG MẠI LOTTE VIỆT NAM (Chi nhánh: Siêu thị LOTTE Mart Quận 7)",
          step_icon: "delivery-truck.svg",
          images: []
        }
      ],
    ]
  ]
};

const Sample_Kimchi_Info = {
  type: "KIMCHI",
  objectInfo: {
    idCode: "8938522156583"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "kimchidongdu@gmail.com",
    name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
    gln: "Chưa có",
    address: "B3 Tô Hiến Thành, P.3, Tp.Đà Lạt, tỉnh Lâm Đồng",
    standard: "FDA",
    phone: "0908 027 614",
    website: "",
    txId: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Kim chi Đông Du",
    description:
      "Kim Chi Đông Du có nguồn cung cấp rau củ sạch tại chỗ từ nông trại Đông Du không qua trung gian. Sẵn sàng sản xuất ra các sản phẩm kim chi chất lượng tuyệt hảo, quy trình sản xuất khép kín, hiện đại và giá cả hợp lý. Cùng với công nghệ lên men Nhật Bản, kim chi Đông Du mang một nét riêng trong mình, nhưng vẫn giữ nguyên tính đặc trưng, mùi vị ngon nhất.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    expiredDate: "2 tháng sau khi đóng gói",
    mix: "0%, không sử dụng chất bảo quản",
    part: "Cải thảo 80%, Cà rốt 10%, Hành lá, Ớt bột, Tỏi 10%",
    procedure: "100% thiên nhiên",
    type: "Kim chi cải thảo Đông Du",
    characteristic: "Không dùng chất bảo quản, lên men tự nhiên",
    weight: "500 gram",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc kim chi đông du",
          step: "CHỌN LỌC",
          from_id: "89360714400130001",
          date: 1562308043000,
          txID:
            "0x081406e891c8528861ec17ecccddaf395c069ae0c20f60e6e2538d704f42fa82",
          from_name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
          step_desc: "Chọn lọc rau sạch, tươi ngon",
          step_attribute: "",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300375/Portal/choncai_rjusyu.png"}, {url:"https://res.cloudinary.com/agridential/image/upload/v1569300375/Portal/choncai2_nelsq1.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc kim chi đông du",
          step: "LÀM SẠCH & SƠ CHẾ",
          from_id: "89360714400130001",
          date: 1562478335000,
          txID:
            "0x9de853b1e756427cb672878a3a121a1b89b205591b80f2c437abd55a0dd0e782",
          from_name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
          step_desc: "Làm sạch, bổ đôi, cắt lát bắp cải",
          step_attribute: "",
          step_icon: "process.svg",
          images: [{url: "https://res.cloudinary.com/agridential/image/upload/v1569300375/Portal/lamsachcai_dcxmj9.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc kim chi đông du",
          step: "NGÂM NƯỚC MUỐI",
          from_id: "89360714400130001",
          date: 1562716835000,
          txID:
            "0xcdab85ada694c59a76ae538feef39515fbf2303a7d9592dc551eb095873946d8",
          from_name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
          step_desc: "",
          step_attribute: "",
          step_icon: "water-drop.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc kim chi đông đu",
          step: "RỬA",
          from_id: "89360714400130001",
          date: 1562770835000,
          txID:
            "0xc17dbbd020f16aa0d854639755ddc3ab961b3ed2ae42d046b503f123950547b6",
          from_name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
          step_desc: "Rửa sạch và thu hồi nước muối",
          step_attribute: "",
          step_icon: "water.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc kim chi đông đu",
          step: "LÊN MEN",
          from_id: "89360714400130001",
          date: 1562781600000,
          txID:
            "0xf57ecd24112c68e2e456ad5396942ea2d9fa1752efb6b8dc7b0aa4d4f3e0f887",
          from_name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
          step_desc: "Lên men tự nhiên bằng công nghệ Nhật Bản",
          step_attribute: "",
          step_icon: "lenmen.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc kim chi đông đu",
          step: "ĐÓNG GÓI & SEAL BAO BÌ",
          from_id: "89360714400130001",
          date: 1563346800000,
          txID:
            "0x3dee0664e58f08ebd26a29635f62b5902d1ff909e1c4be44cf3cecf07ce43070",
          from_name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
          step_desc: "",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc kim chi đông đu",
          step: "THÀNH PHẨM",
          from_id: "89360714400130001",
          date: 1563368400000,
          txID:
            "0xe92b0bc464cf0eac87aa89591efeff07e72f6c61c352b53d92810518ecd26e18",
          from_name: "Công ty TNHH SX TM DV Đông Du Nguyễn",
          step_desc: "",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300357/Portal/kimchi_frlj3r.png"}]
        }
      ]
    ]
  ]
};

const Sample_Melon_Info = {
  type: "Melon",
  objectInfo: {
    idCode: "8938522156583"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "songmaiagri@gmail.com",
    name: "Công ty TNHH Nông Nghiệp Song Mai",
    gln: "Chưa có",
    address: "Đường Cái Sao Bờ Hồ, khóm Đông Thạnh A, P.Mỹ Thạnh, TP.Long Xuyên, tỉnh An Giang   ",
    standard: "FDA",
    phone: "0981 558 939 - 0365 193 119",
    website: "",
    txId: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Dưa lưới - Song Mai",
    type: "F",
    description: "Dưa lưới là rau ăn quả có thời gian sinh trưởng ngắn, trồng được nhiều vụ trong năm với năng suất khá cao. Dưa lưới có quả hình ôval, da quả màu xanh, khi chín thương phẩm ngả xanh vàng và có các đường gân trắng đan xen như lưới nên có tên gọi là vân lưới. Thịt quả dưa vân lưới thường màu vàng da cam nghiêng vàng đỏ. Dưa lưới cung cấp rất nhiều tiền vitamin A (β-carotene), vitamin C, các loại dinh dưỡng như vitamin E và axit folic là những chất chống oxy hóa quan trọng trong quá trình biến dưỡng dinh dưỡng của con người",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "Dễ bị thối nhũn đít trái",
    characteristic: "Trái tròn oval, vỏ xanh, ruột cam. Vỏ mỏng, thịt mềm, thơm, Brix 14+",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "",
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577074297/samples/certificates/songmai_certificate_qoradj.jpg"}]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc dưa lưới Song Mai",
          step: "CHUẨN BỊ NHÀ MÀNG",
          from_id: "89360714400130001",
          date: 1562308043000,
          txID:
            "0x081406e891c8528861ec17ecccddaf395c069ae0c20f60e6e2538d704f42fa82",
          from_name: "Công ty TNHH Nông Nghiệp Song Mai",
          step_desc: "Nhà màng sau khi thu hoạch cần:\n Dọn vệ sinh sạch sẽ\n- Khử trùng nhà màng bằng thuốc sát trùng, dùng vòi áp suất phun bụi 4 bên vách để cây có ánh sáng\n - Căng chỉnh dây cáp và dây treo trái\n- Vệ sinh mái nhà (2-3 vụ nên chà rửa 1 lần)",
          step_attribute: "-Trộn giá thể: Xơ dừa + Phân trùn quế với tỉ lệ 8 dừa : 2 trùn\n-Vô bầu giá thể: Vô đầy bầu\n** Lưu ý thời gian và nhân công thực hiện (tiến độ liên tục, dự kiến xong trong 1,5 ngày/1000 m2):\n  + Số người trộn giá thể: 1 (nam)\n  + Số người vô bầu: 4 (2 nam 2 nữ để luân phiên nam trộn giá thể và đẩy bầu)\n  + Số người đẩy bầu vào nhà màng: 1 (nam)\n  + Căng chỉnh bầu cho thẳng hàng.\n  + Cắm ống capinet vào giữa bầu.\n  + Mở nước tưới cho đẫm bầu trước khi trồng",
          step_icon: "home.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc dưa lưới Song Mai",
          step: "Xuống giống",
          from_id: "89360714400130001",
          date: 1562716835000,
          txID:
            "0xcdab85ada694c59a76ae538feef39515fbf2303a7d9592dc551eb095873946d8",
          from_name: "Công ty TNHH Nông Nghiệp Song Mai",
          step_desc: "Xuống giống vào khoảng 3h chiều.      ",
          step_attribute: "Tiến độ trồng dự kiến (2 - 2,5h/1000 m2):\n- Số người khơi cây: 1\n- Số người rải cây: 1\n- Số người trồng: 3\n- Khi trồng xong: \n+ Kiểm tra cây trồng sót\n+ Kiểm tra ống ghim capinet\n+ Tưới nước lần nữa cho đẫm bầu\n- Qua 1 ngày: Pha dung dịch dinh dưỡng vào bồn.\n- Qua 3 - 5 ngày: cây bắt đầu bắt phân.",
          step_icon: "sprout.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc dưa lưới Song Mai",
          step: "Quản lí và chăm sóc",
          from_id: "89360714400130001",
          date: 1562716835000,
          txID:
            "0xcdab85ada694c59a76ae538feef39515fbf2303a7d9592dc551eb095873946d8",
          from_name: "Công ty TNHH Nông Nghiệp Song Mai",
          step_desc: "",
          step_attribute: "1. Lãi chồi nách\n 2. Buộc dây\n3. Quấn ngọn\n 4. Lãi chồi đến tầng lá thứ 10 - Quấn ngọn\n5. Thụ phấn - Quấn ngọn\n 6. Tỉa trái, tỉa lá chân, tỉa nhánh trên - Quấn ngọn.\n 7. Bấm đọt\n  8. Theo dõi - Chăm sóc     ",
          step_icon: "picking.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc dưa lưới Song Mai",
          step: "PHA DINH DƯỠNG",
          from_id: "89360714400130001",
          date: 1562478335000,
          txID:
            "0x9de853b1e756427cb672878a3a121a1b89b205591b80f2c437abd55a0dd0e782",
          from_name: "Công ty TNHH Nông Nghiệp Song Mai",
          step_desc: "Nguyên tắc pha dinh dưỡng: dung dịch mẹ A và dung dịch mẹ B không được trộn lẫn nhau ngay lặp tức. Đổ 1 nửa dung dịch mẹ A vào mỗi bồn 2000 lít, tương tự với dung dịch mẹ B và vi lượng.      ",
          step_attribute: "- Giai đoạn 1: từ 1 - 20 ngày\n- Giai đoạn 2: từ 21 - 30 ngày\n- Giai đoạn 3: từ 31 - 40 ngày\n- Giai đoạn 4: từ 41 - 60 ngày      ",
          step_icon: "gmo.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc dưa lưới Song Mai",
          step: "Sử dụng Phân - Thuốc",
          from_id: "89360714400130001",
          date: 1562716835000,
          txID:
            "0xcdab85ada694c59a76ae538feef39515fbf2303a7d9592dc551eb095873946d8",
          from_name: "Công ty TNHH Nông Nghiệp Song Mai",
          step_desc: "",
          step_attribute: "- Thời gian phun phân bón lá: buổi sáng (6h30)\n - Thời gian phun thuốc trừ sâu: buổi chiều (16h30) \n - Thời gian phun thuốc bệnh: buổi sáng (6h30)      ",
          step_icon: "fertilizer.svg",
          images: []
        },
      ]
    ]
  ]
};

const Sample_Biophap_Info = {
  type: "BIOPHAP",
  objectInfo: {
    idCode: "0000000000015"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "info@biophap.com",
    name: "Công ty TNHH Biophap",
    gln: "",
    address: "140 đường Thi Sách, Thắng Lợi, Kon Tum",
    standard: "FDA",
    phone: "(+84) 260 3919 696",
    website: "www.biophap.com",
    txId: "0xf3684e422b702e0cc06d5309b7b991749c350cd3a78a1408f20d0fbdbbb8ec16",
    id: null
  },
  productInfo: {
    name: "Bột nghệ hữu cơ Biophap",
    type: "F",
    description:
      "Bột nghệ chứa hoạt chất ‘curcumin’ – một trong những đặc tính kháng viêm và chống oxy hóa tốt nhất giúp chữa lành vết thương, giảm đau bao tử, bảo vệ não và tim. Dùng làm gia vị và tạo màu cho cho món ăn.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "FDA",
    "expiredDate ": "",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    certificates: [{url: "https://res.cloudinary.com/agridential/image/upload/v1577073403/samples/certificates/biophapcert1_khxytb.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1577073405/samples/certificates/biophapcert2_fpvblx.png"}]
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "KHAI HOANG, LÀM ĐẤT",
          from_id: "89360714400130001",
          date: 1420092701000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Nông dân Bahnar",
          step_desc:
            "Quá trình khai hoang làm đất thực hiện những công việc như sau: thu gom cây, làm sạch đất, đo đạc, cắm mốc, đào hố.",
          step_attribute: "",
          step_icon: "shovel.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569298931/Portal/khaihoang_l2rdrq.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569298933/Portal/khaihoang_1_mkk4kd.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "KHẢO SÁT TRANG TRẠI",
          from_id: "89360714400130001",
          date: 1451621501000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Phòng nghiên cứu Biophap",
          step_desc:
            "Bao gồm những công việc sau: lên kế hoạch, thiết kế, thực hiện và nghiệm thu.",
          step_attribute: "",
          step_icon: "khaosat.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569298932/Portal/khaosat_ncqgvt.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569298932/Portal/kstt_2_vb98xu.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "TRỒNG",
          from_id: "89360714400130001",
          date: 1492240500000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Phòng trồng trọt Biophap",
          step_desc:
            "Quá trình trồng bao gồm những công việc như sau: chở cây, bón phân, trồng cây, tưới nước, nghiệm thu.",
          step_attribute: "",
          step_icon: "tree.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569299604/Portal/trong_smbaef.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569299604/Portal/trong_1_izoefk.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "CHĂM SÓC",
          from_id: "89360714400130001",
          date: 1513296900000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Nông dân Bahnar",
          step_desc:
            "Bao gồm những công việc như sau lên kế hoạch, hướng dẫn cho nông dân, công nhân, quản lý tình hình sử dụng nhân sự tại vùng, lên kế hoạch chăm sóc cây.",
          step_attribute: "",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569299722/Portal/chamsoc_akaehf.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569299722/Portal/chamsoc_1_tnu5ag.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1513296900000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Kỹ thuật viên Biophap",
          step_desc:
            "Thu hoạch sản phẩm và đảm bảo các sản phẩm thu hoạch đạt yêu cầu về chất lượng.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569299812/Portal/thuhoach_eopjd2.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569298932/Portal/thuhoach_2_wskqcf.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "CHẾ BIẾN",
          from_id: "89360714400130001",
          date: 1515579300000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Xưởng PCG1 Biophap",
          step_desc:
            "Chế biến sản phẩm tại nơi sơ chế (rửa, phơi khô, sấy, phân loại, đóng gói etc.)",
          step_attribute: "",
          step_icon: "process.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569298930/Portal/chebien_qmmv6f.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "ĐÓNG GÓI",
          from_id: "89360714400130001",
          date: 1518257700000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Xưởng PCG1 Biophap",
          step_desc: "Sau khi chế biến, sản phẩm sẽ được đóng gói cẩn thận.",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images: [{url:"https://res.cloudinary.com/agridential/image/upload/v1569298931/Portal/donggoi_kc6mjl.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569298931/Portal/donggoi_2_kthlsy.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "LƯU KHO & BẢO QUẢN",
          from_id: "89360714400130001",
          date: 1533460500000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Kho thành phẩm Biophap",
          step_desc:
            "Chế biến sản phẩm tại nơi sơ chế (rửa, phơi khô, sấy, phân loại, đóng gói etc.)",
          step_attribute: "",
          step_icon: "warehouse.svg",
          images: [{url:"https://res.cloudinary.com/agridential/image/upload/v1569298931/Portal/baoquan_jfltfb.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569298933/Portal/baoquan_2_qw2ba4.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "VẬN CHUYỂN",
          from_id: "89360714400130001",
          date: 1533480500000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Viettel Post",
          step_desc:
            "Quá trình này đưa ra nội dung, cách thức, trình tự của việc thực hiện công tác vận chuyển sản phẩm bao gồm lựa chọn nhà cung cấp dịch vụ vận chuyển và vận chuyển hàng hóa.",
          step_attribute: "",
          step_icon: "delivery.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569298934/Portal/vanchuyen_c5gdkx.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc bột nghệ hữu cơ Biophap",
          step: "BÁN LẺ",
          from_id: "89360714400130001",
          date: 1556694900000,
          txID:
            "0x54fc1b8cfcad5300285dd384895726d06099495620354ed90592f42d66f306c0",
          from_name: "Bán lẻ Biophap",
          step_desc: "Bán lẻ sản phẩm",
          step_attribute: "",
          step_icon: "sell.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569298929/Portal/banle_afbjdw.jpg"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569298929/Portal/banle_1_jiebrb.jpg"}]
        }
      ]
    ]
  ]
};
const Sample_Salad_Info = {
  type: "SALAD",
  objectInfo: {
    idCode: "8938525767189"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "",
    name: "Long Khánh Green Farm",
    gln: "",
    address: "Số 23, Đường Trần Văn thi, Khu phố 1, Phường Xuân Thanh, Thị xã Long Khánh , Tỉnh Đồng Nai",
    standard: "GS1",
    phone: "0913179666",
    website: "",
    txId: "0x5fb171294718ab93958c7b12f04504afca958be522112f488499c157bad571b4",
    id: null
  },
  productInfo: {
    name: "Rau xà lách thủy canh",
    type: "F",
    description:
      "Xà lách là một loại rau xanh quen thuộc, giàu dinh dưỡng. Xà lách thường được tiêu thụ dưới dạng rau sống, rau trộn, nước ép rau. Bên cạnh tác dụng giúp giảm cân, làm đẹp da, rau xà lách còn có nhiều tác dụng trong cải thiện, hỗ trợ sức khỏe.",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "GS1",
    expiredDate: "4 ngày kể từ ngày đóng gói",
    preservation: "Bảo quản ở nhiệt độ thoáng mát",
    guide: "Làm sạch trước khi chế biến",
    weight: "300g",
    gtin: "0000000000000",
    blockchain:
      "0x0227a2744b19e01360da7eedc77f46d51595e99dc8056f2ff06648e41c1a760c",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc xà lách",
          step: "ƯƠM HẠT VÀ CHĂM SÓC CÂY CON",
          from_id: "89360714400130001",
          date: 1561967211000,
          txID:
            "0x40603b53dc5c04e9a82f0d98e6b935a781dd9d04a1a0fef715005d994594af69",
          from_name: "Long Khánh Green Farm",
          step_desc:
            "Sử dụng loại giống chuyên dùng cho canh tác thủy canh, hạt được bọc men và xử lý tiền nảy mầm. Khi phát triển thành cây con, tưới đủ ẩm cho cây với nước sạch. Khi cây có lá thật đầu tiên dài khoảng 5mm thì bắt đầu tưới phân. Giai đoạn cây con kéo dài từ 10 - 20 ngày tùy vào điều kiện vườn trồng.",
          step_attribute: "",
          step_icon: "vegetable.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300548/Portal/b1xalach_novwom.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569300548/Portal/b2xalach_kz0ubj.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc xà lách",
          step: "TRỒNG VÀ CHĂM SÓC",
          from_id: "89360714400130001",
          date: 1563172200000,
          txID:
            "0x4dd5ea4b0162abd806631fca3a12bd712ceeee24fd38c4dc8b97e16b88b9135b",
          from_name: "Long Khánh Green Farm",
          step_desc:
            "Vệ sinh bàn ươm bằng máy nén áp lực cao và khử trùng. Đưa cây con lên bàn trồng vào lúc sáng sớm hoặc chiều mát để tránh shock nhiệt. Dinh dưỡng cho cây được theo dõi và cung cấp thường xuyên.",
          step_attribute: "",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300548/Portal/b3xalach_m64wcv.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569300549/Portal/b4xalach_bcdtul.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc xà lách",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1564620756000,
          txID:
            "0xe5668d814cd6759c41fce21e476d456772a2708ca9607653cb70bb8266ba9534",
          from_name: "Long Khánh Green Farm",
          step_desc:
            "Sau khi trồng 30-35 ngày sẽ tiến hành thu hoạch sản phẩm. Rau sẽ được thu từ hệ thống bàn trồng vào dụng cụ chứa.",
          step_attribute: "",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300550/Portal/b5xalach_za8rd4.png"}, {url: "https://res.cloudinary.com/agridential/image/upload/v1569300551/Portal/b6xalach_gkwgja.png"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc xà lách",
          step: "SƠ CHẾ VÀ ĐÓNG GÓI",
          from_id: "1564996542000",
          date: 1564640268000,
          txID:
            "0xcec5ea97953a1bdc148c6bb7cd3511299fcf77481f30a8dbda7e7dbd712cd6e4",
          from_name: "Long Khánh Green Farm",
          step_desc:
            "Rau sẽ được sơ chế, loại bỏ rễ, lá già và rọ trồng. Sau đó tiến hành đóng gói bằng cách sử dụng màng bao thực phẩm quấn góc lại hoặc sử dụng túi chuyên dụng đóng rau để đóng gói và bảo quản.",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images: []
        },
      ],
    ]
  ]
}
const Sample_Mushroom_Info = {
  type: "MUSHROOM",
  objectInfo: {
    idCode: "8938525767189"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "vinhphucsale@gmail.com",
    name: "Vinh Phúc Farm",
    gln: "",
    address: "Xóm Gò, Ấp Vàm, Xã Thiện Tân, Huyện Vĩnh Cửu, Đồng Nai",
    standard: "GS1",
    phone: "0908980100 - 0929736707",
    website: "",
    txId: "0x604fde2a734eb419dd22cda2a1c71033860c100a771f0b4d57a13bff80a4270d",
    id: null
  },
  productInfo: {
    name: "Nấm rơm Vinh Phúc",
    type: "F",
    description:
      "Nấm được trồng theo công nghệ của Hà Lan. Toàn bộ quy trình trồng nấm được khép kín trong nhà theo quy trình canh tác hữu cơ. Nấm mỡ vị ngọt, tính mát, có công dụng bổ tỳ ích khí, nhuận phế hoá đàm, tiêu thực lý khí, rất thích hợp cho những người chán ăn mệt mỏi do tỳ vị hư yếu, sản phụ thiếu sữa, viêm phế quản mạn tính, viêm gan mạn tính, hội chứng suy giảm bạch cầu…",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "GS1",
    expiredDate: "4 ngày kể từ ngày đóng gói",
    preservation: "Bảo quản ở nhiệt độ thoáng mát",
    guide: "Làm sạch trước khi chế biến",
    weight: "300g",
    gtin: "0000000000000",
    blockchain:
      "0x0227a2744b19e01360da7eedc77f46d51595e99dc8056f2ff06648e41c1a760c",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  diary: [
    [
      [
        {
          business_steps: "Truy xuất nguồn gốc xà lách",
          step: "Ủ NGUYÊN LIỆU",
          from_id: "89360714400130001",
          date: 1563845805000,
          txID:
            "0xfb1061971f82679af31a0a9eb7b2f25b5fcbcbfd1f21c95c7f5662c4556f34ab",
          from_name: "Vinh Phúc Farm",
          step_desc:
            "Rơm rạ khô được làm ẩm bằng cách ngâm hoặc tưới phun. Sau khi ngâm trong nước vôi và để khô thì sẽ được làm ẩm và xếp đống ủ. Khi xếp xong thì dùng một tấm nylon phủ kín đống ủ để hạn chế sự bốc hơi nước và tạp khuẩn không khí xâm nhập vào đống ủ.",
          step_attribute: "+ Thời gian ủ: 9 ngày \n + Đo PH đầu : 8 \n + Đo PH sau: 7 \n + Số lượng: 90 sọt",
          step_icon: "vegetable.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/b11nam_fow1bg.jpg"}, {url:"https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/b12nam_pbiyav.jpg"}],
          // action_image:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/b11nam_fow1bg.jpg"}, {url:"https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/b12nam_pbiyav.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc nấm rơm",
          step: "CẤY NẤM",
          from_id: "89360714400130001",
          date: 1564625423000,
          txID:
            "0x3b56afef059e805b59268db1a609fac71d1fd31f8d2e6d854aab6d9986fe0c32",
          from_name: "Vinh Phúc Farm",
          step_desc:
            "Rải một lớp rơm mỏng lên trên mặt liếp, dùng tay rải đều. Tiếp đó, rải meo giống dọc 2 bên mép luống, lui vào bên trong 5cm đến 7cm. Sau khi hoàn thành, vuốt đều xung quanh, để cho mô nấm gọn gàng nhìn vuông vắn, thuận tiện cho việc thu hoạch sau này.",
          step_attribute: "",
          step_icon: "picking.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/b21namrom_tzzx4r.jpg"}],
          
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc nấm rơm",
          step: "KIỂM TRA THÔNG SỐ (PH & NHIỆT ĐỘ)",
          from_id: "89360714400130001",
          date: 1564732935000,
          txID:
            "0x9ff2fdde10933af90f03caefccd81e5669af58722aa21787a57efc75ecd85c7b",
          from_name: "Vinh Phúc Farm",
          step_desc:
            "Thường xuyên kiểm tra thông số và đảm bảo môi trường lý tưởng để nấm phát triển, từ đó đảm bảo hiệu suất khi thu hoạch. Các thông số cần kiểm tra bao gồm nhiệt độ, độ ẩm và độ PH.",
          step_attribute: "+ Nhiệt mô 43 độ, nhiệt mô hơi nóng, tơ bung tốt. Kệ đôi phía trong cơ chất bị len men phụ nhẹ, có mùi chua nhẹ.",
          step_icon: "cold2.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc nấm rơm",
          step: "XẢ TƠ",
          from_id: "89360714400130001",
          date: 1565364227000,
          txID:
            "0xdf37a33531eb14e3fa493b35a8d8ddd1604e2e9a5b86136978d64d9868f62822",
          from_name: "Vinh Phúc Farm",
          step_desc:
            "Xả tơ sau khi cấy nấm 8 ngày.",
          step_attribute: "",
          step_icon: "floss.svg",
          images: []
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc nấm rơm",
          step: "THU HOẠCH",
          from_id: "89360714400130001",
          date: 1565915734000,
          txID:
            "0x6551ea9c33cbb3072605373e3466e50c8c2c7a64f6e93c14e0bb362e079fc259",
          from_name: "Vinh Phúc Farm",
          step_desc:
            "Hái những cây còn búp và hơi nhọn đầu. Việc hái được thực hiện bằng cách xoay nhẹ cây nấm, tách ra khỏi mô mẹ và loại bỏ phần chân nấm sót lại trên mô (nếu để lại phần chân nấm sẽ bị thối và gây ảnh hưởng đến các nụ nấm xung quanh).",
          step_attribute: "+ Hái 40kg nấm",
          step_icon: "harvest.svg",
          images:  [{url:"https://res.cloudinary.com/agridential/image/upload/v1569300846/Portal/thuhoachnamrom_nrtwio.jpg"}]
        },
      ],
      [
        {
          business_steps: "Truy xuất nguồn gốc nấm rơm",
          step: "SƠ CHẾ VÀ ĐÓNG GÓI",
          from_id: "1564996542000",
          date: 1565943612000,
          txID:
            "0x1e08110a15c27c79fcdaabecb556608ca0c0ffc64afb98a56273d703ac9ac19a",
            from_name: "Vinh Phúc Farm",
          step_desc:
            "Nấm sẽ được sơ chế và sau đó tiến hành đóng gói bằng cách sử dụng màng bao thực phẩm hoặc sử dụng túi chuyên dụng để đóng gói và bảo quản.",
          step_attribute: "",
          step_icon: "delivery-package-opened.png",
          images: []
        },
      ],
    ]
  ],
}


// const Sample_Longan_Info = {
//   type: "LONGAN",
//   objectInfo: {
//     idCode: "0000000000021"
//   },
//   farmInfo: {
//     gln: "89360714400130001",
//     farm_name: "Nguyễn Văn Mách",
//     scope: "60 cây",
//     txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
//     id: null
//   },
//   cooperativeInfo: {
//     email: "",
//     name: "Hợp tác xã nông nghiệp dịch vụ Nhân Tâm",
//     gln: "Chưa có",
//     address: "Ấp Phú Lâm, xã Hòa Hiệp, huyện Xuyên Mộc, tỉnh Bà Rịa Vũng Tàu",
//     standard: "VietGAP",
//     phone: "02543 507 506",
//     website: "",
//     txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
//     id: null
//   },
//   productInfo: {
//     name: "Nhãn Xuồng Cơm Vàng",
//     type: "F",
//     description: "Quả Nhãn xuồng cơm vàng là đặc sản của Nam Bộ, rất được ưa chuộng do rất dày cơm, trái to. Màu cơm trên đỉnh trái có màu vàng nhạt. Chủ yếu dùng ăn tươi.",
//     process_code: "R1",
//     create_date: 1554460325003,
//     owner_gln: "8936071440006",
//     trace_cycle: "y",
//     TABLE_NAME: "8936071440037",
//     sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
//     status: 0,
//     standard: "VietGAP",
//     harvestDate: "60 ngày",
//     weight: "1.5 kg",
//     weakness: "",
//     characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
//     gtin: "0000000000000",
//     blockchain:
//       "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
//     index: "1",
//     prefix: "003",
//     __v: 0,
//     id: null,
//     image: ""
//   },
//   diary: [
//     [
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc nhãn xuồng cơm vàng",
//           step: "GIEO GIỐNG",
//           from_id: "89360714400130001",
//           date: 1553498103000,
//           txID:
//             "0x2c4063b66a9cfa27c40e902a3b518c23e982d7b0027cfbdf93af8678afa8c55c",
//           from_name: "Hợp tác xã nông nghiệp dịch vụ Nhân Tâm",
//           step_desc:
//             "Nhãn được gieo giống hoặc trồng cây con được chiết cành từ các cây trường thành khác",
//           step_attribute:
//             "",
//           step_icon: "cherry.svg",
//           images: []
//         }
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc nhãn xuồng cơm vàng",
//           step: "CHĂM SÓC",
//           from_id: "89360714400130001",
//           date: 1554665403000,
//           txID:
//             "0xf45951ebf6bbd6d013e42430b30c2283778fc329e1a327050e9423d0b0f4f518",
//           from_name: "Hợp tác xã nông nghiệp dịch vụ Nhân Tâm",
//           step_desc:
//             "Giống và cây non được đưa đến khu vực chăm sóc đặc biệt, đảm bảo đủ điều kiện về nhiệt độ, ánh sáng, chất dinh dưỡng, lượng nước để phát triển thành cây con khỏe.",
//           step_attribute:
//             "",
//           step_icon: "picking.svg",
//           images: []
//         },
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc nhãn xuồng cơm vàng",
//           step: "THU HOẠCH",
//           from_id: "89360714400130001",
//           date: 1562792403000,
//           txID:
//             "0x20d52cab47453bc09a717ea84cbf394d6f6cb4693384a492f1033cba68363735",
//             from_name: "Hợp tác xã nông nghiệp dịch vụ Nhân Tâm",
//           step_desc:
//             "Thu hoạch nhãn từ cây, khi chín vỏ quả chuyển từ màu nâu hơi xanh sang màu nâu sáng",
//           step_attribute: "",
//           step_icon: "harvest.svg",
//           images: []
//         },
//       ],
//       [
//         {
//           business_steps: "Truy xuất nguồn gốc nhãn xuồng cơm vàng",
//           step: "ĐÓNG GÓI",
//           from_id: "89360714400130001",
//           date: 1562950170000,
//           txID:
//             "0x0d67e35dd8b9b375a27a65657cb9170381eca084605af8344d7a7295ef85de69",
//             from_name: "Hợp tác xã nông nghiệp dịch vụ Nhân Tâm",
//           step_desc:
//             "Sau khi thu hoạch nhãn được sơ chế và đóng gói.",
//           step_attribute: "",
//           step_icon: "box.svg",
//           images: []
//         },
//       ]
//     ]
//   ]
// };

const Sample_Xetang_Info_Panorama = {
  type: "CITY",
  panorama: true,
  objectInfo: {
    idCode: "00000003"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "",
    name: "De Heus Dong Nai",
    gln: "",
    address: "Xã Bàu, Khu công nghiệp, Hàm 2, Đồng Nai 710000, Việt Nam",
    standard: "VietGAP",
    phone: "+84 251 3772 159",
    website: "deheus.com.vn",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "De Heus Dong Nai",
    type: "F",
    description: "",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "De Heus Việt Nam trực thuộc tập đoàn De Heus, chuyên sản xuất thức ăn chăn nuôi chất lượng cao cho gia súc, gia cầm và thủy sản, hiện có 9 nhà máy và hệ thống các kho trung chuyển hoạt động trên khắp cả nước. De Heus hướng tới việc chủ động đáp ứng cho tất cả các nhu cầu của thị trường cũng như nhu cầu của khách hàng. Luôn nỗ lực mạnh mẽ trong việc cải tiến công nghệ và hiệu quả thức ăn chăn nuôi, De Heus cam kết rằng khách hàng của chúng tôi sẽ nhận được sự hỗ trợ kỹ thuật tốt nhất và kịp thời nhất cũng như giúp khách hàng cắt giảm chi phí để gia tăng hiệu quả chăn nuôi.",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  diary: []
};
const Sample_Xetang_Info = {
  type: "XETANG",
  objectInfo: {
    idCode: "00000001"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "contact@vietnamblockchain.asia",
    name: "Công ty cổ phần Việt Nam Blockchain",
    gln: "",
    address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
    standard: "VietGAP",
    phone: "0908 444 095",
    website: "vietnamblockchain.asia",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Panzer IV Medium Tank",
    type: "F",
    description: "",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  diary: []
};
const Sample_Xetang1_Info = {
  type: "XETANG1",
  objectInfo: {
    idCode: "00000002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "contact@vietnamblockchain.asia",
    name: "Công ty cổ phần Việt Nam Blockchain",
    gln: "",
    address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
    standard: "VietGAP",
    phone: "0908 444 095",
    website: "vietnamblockchain.asia",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Panzer IV Medium Tank",
    type: "F",
    description: "",
    process_code: "R1",
    create_date: 1554460325003,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
    gtin: "0000000000000",
    blockchain:
      "0xf48043e2f6617ce4c6f45859e485e5877c604ffb5fbbf794983a7054e328c6d4",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: ""
  },
  diary: []
};

const Sample_Certificate_Info = {
  type: "CERTIFICATE",
  // type: "XETANG1",
  objectInfo: {
    idCode: "00000002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "contact@vietnamblockchain.asia",
    name: "Công ty cổ phần Việt Nam Blockchain",
    gln: "",
    address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
    standard: "VietGAP",
    phone: "0908 444 095",
    website: "vietnamblockchain.asia",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Master of Business Administration",
    type: "F",
    description: "Do Van Long, CEO of Vietnam Blockchain Corporation, holds the MBA from the Alabama, USA based Columbia Southern University (CSU)\nLong has 19 years of senior managerial level experience in IT, E-commerce, Travel, Hospitality and Financial sectors. He is specialised in creating innovative enterprise solutions related to ERP, CRM, SCM, Financial Solutions and Business Intelligence; and now focusing on Blockchain Services & Business Strategy Development. He holds an bachelor’s degree in Computer Science from the Ho Chi Minh University of Technology.\nLong joined at Infinity Blockchain Labs(IBL) in 2016 as Fintech Director and currently serves as its Regional Chief Strategy Officer; he also serves as the CEO of Vietnam Blockchain Corporation. He works mainly on building business strategy and expanding blockchain knowledge to the community, and connecting IBL to the business partners, universities and institutions across Vietnam, in an effort to make Vietnam a Fintech hub. ",
    process_code: "R1",
    create_date: 1571399066000,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
    gtin: "0x8eac6e36caedebd8fbda9683ea5d221770354162f101adf851ea1e467f99cb8a",
    blockchain:
      "0x8eac6e36caedebd8fbda9683ea5d221770354162f101adf851ea1e467f99cb8a",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "https://res.cloudinary.com/agridential/image/upload/v1582104725/images/file_w8x8a4_wn27oh.jpg"
  },
};

const Sample_Certificate_Info2 = {
  type: "CERTIFICATE",
  // type: "XETANG1",
  objectInfo: {
    idCode: "00000002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "contact@vietnamblockchain.asia",
    name: "Công ty cổ phần Việt Nam Blockchain",
    gln: "",
    address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
    standard: "VietGAP",
    phone: "0908 444 095",
    website: "vietnamblockchain.asia",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Master Fintech Professional",
    type: "F",
    description: " Do Van Long, CEO of Vietnam Blockchain Corporation is honored by Colorado, USA based Global Academy of Finance and Management(GAFM)\nDo Van Long receives the Certification from Rajkumar Kanagasingam, President, Fintech Association of Sri Lanka (FASL) and GAFM Representative in Sri Lanka and The Maldives, at Park Hyatt Saigon, Vietnam \nDo Van Long, CEO of Vietnam Blockchain Corporation is recently honored by the Colorado, USA based Global Academy of Finance and Management(GAFM), an accredited global graduate society for management and finance with membership over 150 countries, by awarding the Certification - ‘Master Fintech Professional’ for his contributions towards Fintech sector especially in the Blockchain sphere.\nDo Van Long recently graced as a panelist at the “Block Plus Summit Series III Future of Fintech” in Ho Chi Minh City(Saigon), Vietnam which was collaborated with a number of international institutions including Fintech Association of Sri Lanka(FASL).\nLong is the third person to receive the certification after Professor Douglas W. Arner, the Kerry Holdings Professor in Law at the University of Hong Kong, who led the development of the world’s largest massive open online course (MOOC): Introduction to FinTech, launched on edX in May 2018, now with approximately 40,000 learners spanning every country in the world; and Mirna Sleiman, the Founder and CEO of Fintech Galaxy, the MENA region’s only digital crowdsourcing platform that empowers Fintech entrepreneurs and connects them with financial institutions, consultants, tech companies, mentors and investors both regionally and globally.",
    process_code: "R1",
    create_date: 1571399066000,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
    gtin: "0xf30655e2a0009acfbe3ebe22db8621289466202c162f8c11b8ed755ce68b1a7c",
    blockchain:
      "0xf30655e2a0009acfbe3ebe22db8621289466202c162f8c11b8ed755ce68b1a7c",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "https://res.cloudinary.com/agridential/image/upload/v1582131421/images/file_lp71xp_ucit3o.jpg"
  },
};

const Sample_Certificate_Info3 = {
  type: "CERTIFICATE",
  objectInfo: {
    idCode: "00000002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "contact@vietnamblockchain.asia",
    name: "Công ty cổ phần Việt Nam Blockchain",
    gln: "",
    address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
    standard: "VietGAP",
    phone: "0908 444 095",
    website: "vietnamblockchain.asia",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Chứng nhận hoàn thành khóa học Blockchain",
    type: "F",
    description: "Chứng nhận tham gia khóa học công nghệ Blockchain. Địa điểm: Tỉnh Đồng Tháp. Người nhận: Ông Đoàn Nguyễn Đăng Khoa. Đã hoàn thành Khóa học: Chuyên đề nền tảng công nghệ Blockchain ngày 2 tháng 8 năm 2019.",
    process_code: "R1",
    create_date: 1564730686000,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
    gtin: "0xbc06f2548a9b996c2fddedb6997612da7fbf60b16e1d67b9d8e6632244b271cf",
    blockchain:
      "0xbc06f2548a9b996c2fddedb6997612da7fbf60b16e1d67b9d8e6632244b271cf",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "https://res.cloudinary.com/agridential/image/upload/v1584429735/images/Cert_DongThap_Doan_Nguyen_Dang_Khoa_topkex.png"
  },
};
const Sample_Certificate_Info4 = {
  type: "CERTIFICATE",
  objectInfo: {
    idCode: "00000002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "contact@vietnamblockchain.asia",
    name: "Công ty cổ phần Việt Nam Blockchain",
    gln: "",
    address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
    standard: "VietGAP",
    phone: "0908 444 095",
    website: "vietnamblockchain.asia",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Master of Business Administration",
    type: "F",
    description: "- Name: Master of Business Administration - Description: Nguyen Tan Tuan, CEO Passionwin Apparel, holds the MBA from the Alabama, USA based Columbia Southern University (CSU)",
    process_code: "R1",
    create_date: 1571383486000,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
    gtin: "0x68f3bae902220a62e120edbe83cd2792033a8a546e7496c78a214f413304554e",
    blockchain:
      "0x68f3bae902220a62e120edbe83cd2792033a8a546e7496c78a214f413304554e",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "https://res.cloudinary.com/agridential/image/upload/v1584430826/images/CSU_Nguyen_Van_Tuan_vldbjf.png"
  },
};
const Sample_Certificate_Info5 = {
  type: "CERTIFICATE",
  objectInfo: {
    idCode: "00000002"
  },
  farmInfo: {
    gln: "89360714400130001",
    farm_name: "Nguyễn Văn Mách",
    scope: "60 cây",
    txID: "0x385410f1ded1a88ea17f3afba49dcebc266329650b450776ff596478e7a65e95",
    id: null
  },
  cooperativeInfo: {
    email: "contact@vietnamblockchain.asia",
    name: "Công ty cổ phần Việt Nam Blockchain",
    gln: "",
    address: "Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, TpHCM",
    standard: "VietGAP",
    phone: "0908 444 095",
    website: "vietnamblockchain.asia",
    txID: "0x2aca82e997d35899be8ab237ddd83ad4ad161cbcecaa1a49dc89d6a087ec5f2d",
    id: null
  },
  productInfo: {
    name: "Merchandiser Building Program",
    type: "F",
    description: "Khóa học tổng quan về nghề Merchandiser trong ngành may mặc sẽ cung cấp đầy đủ cho các bạn những kiến thức về các hoạt động Merchandise bao gồm thương mại, tiếp thị, thiết kế, nguồn, kiến thức sản phẩm, quy trình quản lý đơn hàng, chuỗi cung ứng… cũng như những kỹ năng cần thiết đối với một chuyên viên quản lý đơn hàng. Sau khóa học các bạn có thể quyết định công việc Merchandise nào phù hợp với trình độ và năng lực của mình, và có thể tự tin tham gia vào các công ty may mặc hàng đầu trên thế giới hiện nay.",
    process_code: "R1",
    create_date: 1584520206000,
    owner_gln: "8936071440006",
    trace_cycle: "y",
    TABLE_NAME: "8936071440037",
    sc_address: "0x3f381f8818d6dd947ffcc29208a5f69b2feff146",
    status: 0,
    standard: "VietGAP",
    harvestDate: "60 ngày",
    weight: "1.5 kg",
    weakness: "",
    characteristic: "Hương vị ngọt đậm đà, lôi cuốn vị giác. Bên cạnh hương vị thơm ngon, trong trái nhãn còn chứa rất nhiều giá trị dinh dưỡng như protein, chất béo, đường thiên nhiên, các loại vitamin và khoáng chất như vitamin A, C, kali, photpho, magie, sắt, axit hữu cơ, chất xơ… Loại trái cây này có công dụng tăng cường sức đề kháng cho cơ thể, chống lại các bệnh cảm cúm, ngăn chặn quá trình oxy hóa, làm đẹp da, phòng ngừa bệnh dạ dày hiệu quả, làm tăng cường khả năng hấp thu sắt, giúp ngăn ngừa bệnh thiếu máu hay bệnh ở tuyến tụy... Do đó, Nhãn xuồng cơm vàng sẽ là sự lựa chọn cho món tráng miệng lý tưởng sau mỗi bữa ăn của cả gia đình.",
    gtin: "0xdf9d8bbc4a47a6cd560748b8603457404695b63e38f9239e3069a41412889f7a",
    blockchain:
      "0xdf9d8bbc4a47a6cd560748b8603457404695b63e38f9239e3069a41412889f7a",
    index: "1",
    prefix: "003",
    __v: 0,
    id: null,
    image: "https://res.cloudinary.com/agridential/image/upload/c_scale,q_100,w_862/v1584519507/images/cert_nguyentantuan_2_cugvbi.jpg",
    options: [
      {
        key: "Thông tin khóa học",
        value: ["Hiểu rõ các khái niệm về Merchandise, hoạt động Merchandise và Merchandiser- quản lý đơn hàng.",
                "Hiểu rõ hoạt động Merchandise ở các công ty mua hàng (buyers) và nhà cung cấp (vendors/suppliers).",
                "Nắm vững vai trò và trách nhiệm của một Merchandiser- chuyên viên quản lý đơn hàng.",
                "Nắm vững quy trình quản lý đơn hàng và chuỗi cung ứng.",
                "Những kỹ năng cần thiết đối với một Merchandiser- chuyên viên quản lý đơn hàng."]
      },
      {
        key: "Kết quả đạt được sau khóa học",
        value: [
          "Trang bị đầy đủ kiến thức về nghề Merchandiser trong ngành công nghiệp may mặc.",
          "Giúp bạn tự tin hơn và quyết định nghề nghiệp của mình trong môi trường làm việc quốc tế và các công ty hàng đầu trên thế giới.",
          "Củng cố kiến thức và hổ trợ tích cực các hoạt động Merchandise mà bạn đang thực hiện.",
          "Củng cố và phát huy những kỹ năng cần thiết đối với một chuyên viên quản lý đơn hàng."
        ]
      },
      {
        key: "Quyền lợi dành cho học viên",
        value: [
          "Bảo hành khóa học trọn đời",
          "Tham gia Talkshow miễn phí",
          "Được giới thiệu việc làm",
          "Giảng viên 100% là doanh nhân",
          "Phòng học hiện đại, được phục vụ Tea Break",
          "Chứng chỉ có giá trị toàn quốc",
          "Nội dung mới cập nhật liên tục",

        ]
      },
      {
        key: "Nội dung khóa học",
        value: [
          "Chương 1: Tổng quan về Merchandiser trong ngành may mặc.",
          "Chương 2: Phân loại Merchandiser ở các loại hình doanh nghiệp.",
          "Chương 3: Cơ cấu sắp xếp công việc Merchandiser ở những vai trò khác nhau.",
          "Chương 4: Cơ cấu về chuỗi cung ứng và hoạt động của các nhà cung cấp.",
          "Chương 5: Tìm hiểu và phân tích một số hoạt động của các bộ phận liên quan.",
          "Chương 6: Những kỹ năng cần thiết khi tham gia vào các hoạt động Merchandise."
        ]
      }
    ]
  },
};
const Sample_GLN = {
  "00000000051": ["BOX", Sample_Box_Info],
  "0000000020002": ["ALCOHOL", Sample_Alcohol_Info],
  "0000000020001": ["ALCOHOL", Sample_Alcohol_Info],
  "1000000000000": ["RICE", Sample_Rice_Info],
  "1000000000001": ["RICE", Sample_Rice_Info],
  "1000000000002": ["RICE", Sample_Rice_Info],
  "1000000000003": ["RICE", Sample_Rice_Info],
  "1000000000004": ["RICE", Sample_Rice_Info],
  "1000000000005": ["RICE", Sample_Rice_Info],
  "1000000000006": ["RICE", Sample_Rice_Info],
  "1000000000007": ["RICE", Sample_Rice_Info],
  "1000000000008": ["RICE", Sample_Rice_Info],
  "1000000000009": ["RICE", Sample_Rice_Info],
  "1000000000010": ["RICE", Sample_Rice_Info],
  "1000000000011": ["RICE", Sample_Rice_Info],
  "1000000000012": ["RICE", Sample_Rice_Info],
  "1000000000013": ["RICE", Sample_Rice_Info],
  "1000000000014": ["RICE", Sample_Rice_Info],
  "1000000000015": ["RICE", Sample_Rice_Info],
  "1000000000016": ["RICE", Sample_Rice_Info],
  "1000000000017": ["RICE", Sample_Rice_Info],
  "1000000000018": ["RICE", Sample_Rice_Info],
  "1000000000019": ["RICE", Sample_Rice_Info],
  "1000000000020": ["RICE", Sample_Rice_Info],
  "1000000000021": ["RICE", Sample_Rice_Info],
  "1000000000022": ["RICE", Sample_Rice_Info],
  "1000000000023": ["RICE", Sample_Rice_Info],
  "1000000000024": ["RICE", Sample_Rice_Info],
  "1000000000025": ["RICE", Sample_Rice_Info],
  "1000000000026": ["RICE", Sample_Rice_Info],
  "1000000000027": ["RICE", Sample_Rice_Info],
  "1000000000028": ["RICE", Sample_Rice_Info],
  "1000000000029": ["RICE", Sample_Rice_Info],
  "1000000000030": ["RICE", Sample_Rice_Info],
  "1000000000031": ["RICE", Sample_Rice_Info],
  "1000000000032": ["RICE", Sample_Rice_Info],
  "1000000000033": ["RICE", Sample_Rice_Info],
  "1000000000034": ["RICE", Sample_Rice_Info],
  "1000000000035": ["RICE", Sample_Rice_Info],
  "1000000000036": ["RICE", Sample_Rice_Info],
  "1000000000037": ["RICE", Sample_Rice_Info],
  "1000000000038": ["RICE", Sample_Rice_Info],
  "1000000000039": ["RICE", Sample_Rice_Info],
  "1000000000040": ["RICE", Sample_Rice_Info],
  "1000000000041": ["RICE", Sample_Rice_Info],
  "1000000000042": ["RICE", Sample_Rice_Info],
  "1000000000043": ["RICE", Sample_Rice_Info],
  "1000000000044": ["RICE", Sample_Rice_Info],
  "1000000000045": ["RICE", Sample_Rice_Info],
  "1000000000046": ["RICE", Sample_Rice_Info],
  "1000000000047": ["RICE", Sample_Rice_Info],
  "1000000000048": ["RICE", Sample_Rice_Info],
  "1000000000049": ["RICE", Sample_Rice_Info],
  "1000000000050": ["RICE", Sample_Rice_Info],
  "893607144003300010001": ["TREE", Sample_Tree_Info],
  "0000001000000": ["COFFEE", Sample_Caffe_Info],
  "0000001001452": ["COFFEE", Sample_Caffe_Info],
  "0000001000493": ["COFFEE", Sample_Caffe_Info],
  "0000001000498": ["COFFEE", Sample_Caffe_Info],
  "0000001000489": ["COFFEE", Sample_Caffe_Info],
  "0000001000488": ["COFFEE", Sample_Caffe_Info],
  "1549611778": ["COFFEE", Sample_Caffe_Info],
  "0000000000002": ["GARLIC", Sample_Garlic_Info],
  "0000001000616": ["HONEY", Sample_Honey_Info],
  "0000000000003": ["INCENSE", Sample_Incense_Info],
  "0000000000004": ["TOMATO", Sample_Tomato_Info_1],
  "0000000000005": ["TUNGHO", Sample_Tungho_Info],
  "0000000000006": ["TOMATO", Sample_Tomato_Info_2],
  "0000000000007": ["TOMATO", Sample_Tomato_Info_3],
  "5784965812": ["BIOPHAP", Sample_Biophap_Info],
  "0000000000000": ["FRUIT", Sample_Fruit_Info],
  "0000000000001": ["FRUIT", Sample_Fruit_Info],
  "0000000000008": ["SPINACH", Sample_Spinach_Info],
  "0000000000009": ["HERB", Sample_Herb_Info],
  "0000000000010": ["KIMCHI", Sample_Kimchi_Info],
  "0000000000011": ["MELON", Sample_Melon_Info],
  "0000000000012": ["MANGO", Sample_Mango_Info],
  "0000000000013": ["SALAD", Sample_Salad_Info],
  "0000000000014": ["MUSHROOM", Sample_Mushroom_Info],
  "0000000000015": ["BIOPHAP", Sample_Biophap_Info],
  "00000001": ["XETANG", Sample_Xetang_Info],
  "00000003": [ "CITY", Sample_Xetang_Info_Panorama],
  "00000002": ["XETANG1", Sample_Xetang1_Info],
  "0x8eac6e36caedebd8fbda9683ea5d221770354162f101adf851ea1e467f99cb8a": ["CERTIFICATE",Sample_Certificate_Info],
  "0xf30655e2a0009acfbe3ebe22db8621289466202c162f8c11b8ed755ce68b1a7c": ["CERTIFICATE",Sample_Certificate_Info2],
  "0xbc06f2548a9b996c2fddedb6997612da7fbf60b16e1d67b9d8e6632244b271cf": ["CERTIFICATE",Sample_Certificate_Info3],
  "0x68f3bae902220a62e120edbe83cd2792033a8a546e7496c78a214f413304554e": ["CERTIFICATE",Sample_Certificate_Info4],
  "0xdf9d8bbc4a47a6cd560748b8603457404695b63e38f9239e3069a41412889f7a": ["CERTIFICATE",Sample_Certificate_Info5],

};

export { Sample_GLN };
