import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import strings from 'constants/strings';
import React from 'react';
import Gallery from 'react-grid-gallery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";
import styles from './infoStyles';
import renderHTML from 'react-render-html';


// const RegularMap = withScriptjs(
//   withGoogleMap((data) => {
//     //console.log("data", data)
//     return (
//       <GoogleMap
//         defaultZoom={15}
//         defaultCenter={{ lat: data.data.lat, lng: data.data.lng }}
//         defaultOptions={{
//           scrollwheel: true
//         }}
//       >
//         <Marker position={{ lat: data.data.lat, lng: data.data.lng }} />
//       </GoogleMap>
//     )

//   })
// );
class Manufacturer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tx: "",
      cooName: "",
      cooDes: "",
      cooInfo: [],
      cooCert: [],
      cooTx: "",
      cooImage: [],
      cooMap: "", //biến này sử dụng maps iframe //
      map_location: {
        lat: 0,
        lng: 0
      },
      cooDynamicDescription: ""

    }
    this.data = this.props.data;
    this.lookupTree = this.lookupTree.bind(this)
    this.loadingData = this.loadingData.bind(this)
    this.settingMap = this.settingMap.bind(this)
  }
  componentDidMount() {
    if (this.props.manufacturer) {
      this.loadingData(this.props.manufacturer)
    }
    if (this.props.map.data) {
      this.settingMap(this.props.map.data)
    }
  }
  componentWillReceiveProps(preProps) {
    if (preProps.manufacturer !== this.props.manufacturer) {
      this.loadingData(preProps.manufacturer)
    }
    if (preProps.map !== this.props.map) {
      if (preProps.map.data) {
        this.settingMap(preProps.map.data)
      }
    }
  }
  settingMap(data) {
    if (data.lat && data.lng) {
      this.setState({
        map_location: data
      })
    }

  }
  loadingData(data) {
    this.setState({
      cooName: data.cooName,
      cooDes: data.cooDes,
      cooInfo: data.cooInfo,
      cooCert: data.cooCert,
      cooTx: data.cooTx,
      cooImage: data.cooImage,
      cooMap: data.cooMap, //links embed maps
      cooDynamicDescription: data.cooDynamicDescription
    })
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  showBlockhainAlert = (tx) => {
    this.setState({ open: true, tx: tx });
  }

  lookupTree = () => {
    let path = "/lookup/" + this.data.treeInfo.idCode;
    this.props.history.push(path);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="flexcenter" style={{marginTop: "30px", display: "flex", justifyContent: "center" }}>
          <GridItem xs={12} sm={12} md={10} className={classes.noPadding}>
            <Typography className={classes.xuatxutag}>{this.state.cooName}</Typography>
            <div>
              {
                this.state.cooDynamicDescription && this.state.cooDynamicDescription !== "" ?
                <div className={classes.cooDes}>
                  {renderHTML(this.state.cooDynamicDescription)}
                </div>
                :
                <p className={classes.cooDes}>{this.state.cooDes}</p>
              }
            </div>
            <hr className="LineBreakDes"></hr>
            {this.state.cooImage.length > 0 ?
              <Gallery style={{ display: 'flex' }} images={this.state.cooImage} rowHeight={90} margin={3} /> : null}
          </GridItem>
        </div>
        <div className="styleXX">
          <GridItem xs={12} sm={12} md={10} className="manufactlayout">
            <div style={{ marginTop: "20px" }}>
              {this.state.cooInfo.length > 0 ?
                this.state.cooInfo.map((info, index) => (
                  info.key === strings.website ?
                    (<GridContainer key={index} className="manufactsec">
                      <div className="sectionone" >
                        <Typography className="tagmanufact"> {info.key} </Typography>
                      </div>
                      <div className="sectiontwo">
                        <Typography className="tagmanufactdes" ><a target="_blank" rel="noopener noreferrer" href={"http://" + info.value}>{info.value}</a></Typography>
                      </div>
                      <hr className="LineBreakDesManu" />
                    </GridContainer>) : (
                      <GridContainer key={index} className="manufactsec">
                        <div className="sectionone" >
                          <Typography className="tagmanufact"> {info.key} </Typography>
                        </div>
                        <div className="sectiontwo">
                          <Typography className="tagmanufactdes" >{info.value}</Typography>
                        </div>
                        <hr className="LineBreakDesManu" />
                      </GridContainer>
                    )
                ))
                : null}
              {this.state.cooCert.length > 0 ?
                <div>
                  <GridContainer className="manufactsec" style={{ marginTop: "10px", padding: "0px 0px !important" }}>
                    <div style={{ width: "100%" }} className="widthAvatar">
                      <div className="sectionone" style={{ width: "100%", marginBottom: 10 }}>
                        <Typography className="tagmanufact">{strings.certificate}</Typography>
                      </div>
                      <div style={{ width: "100%" }} className="sectiontwo">
                        <div style={{ color: "red" }}>
                          <Gallery style={{ display: "flex" }} images={this.state.cooCert} rowHeight={90} margin={3} />
                        </div>
                      </div>
                    </div>
                  </GridContainer>
                </div>
                : null}
              {this.state.cooTx ?
                <div>
                  <GridContainer className="manufactsec" style={{ marginTop: "10px", padding: "0px 0px !important" }}>
                    <hr className="LineBreakDesWide" />
                    <div className="sectionone" >
                      <Typography className="tagmanufact">{strings.blockchain_info}</Typography>
                    </div>
                    <div className="sectiontwo">
                      <Typography className="tagmanufactdesbc" onClick={() => this.showBlockhainAlert(this.state.cooTx)}> {this.state.cooTx} </Typography>
                    </div>

                  </GridContainer>
                  <GridItem xs={12} className={classes.noPadding}>
                    <hr className="LineBreakDes" />
                  </GridItem>
                </div>
                : null}
              {this.state.cooMap ?
                <GridContainer style={{ marginTop: "10px" }}>
                  <iframe
                  title="map"
                    width="100%" height="400" frameborder="0" style={{ border: "0", padding: "25px" }}
                    src={this.state.cooMap} allowfullscreen>
                  </iframe>
                  {/* {this.state.map_location.lat ? <RegularMap
                    data={this.state.map_location}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                      <div
                        style={{
                          height: `280px`,
                          width: "100%",
                          borderRadius: "6px",
                          overflow: "hidden"
                        }}
                      />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                  /> : null} */}
                </GridContainer>
                : null}
            </div>
          </GridItem>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: '10px', paddingBottom: '70px', paddingTop: '70px', zIndex: 3000 }}
          fullScreen={true}
        >
          <DialogContent style={{ padding: '0px' }}>
            <iframe title="blockchain" style={{ height: '100vh', width: '100%' }} src={'https://blockchain.agridential.vn/tx/' + this.state.tx} />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button style={{ color: 'white', background: 'black' }} onClick={this.handleClose} color="primary" autoFocus>
              {strings.close}
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    overview: state.overview,
    manufacturer: state.manufacturer,
    map: state.map
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Manufacturer)));