// ##############################
// // // Timeline component styles
// #############################

import {
    infoColor,
    primaryColor,
    dangerColor,
    warningColor
  } from "assets/jss/material-dashboard-pro-react.jsx";
  // import { FONT_SIZES, COLORS, FONTS } from '../../../../constants';
  import { FONT_SIZES, FONTS } from '../../../../constants';
  const timelineStyle = theme => ({
    timeline: {
      [theme.breakpoints.down("sm")]: {
        "&:before": {
          left: "5% !important",
          marginLeft: "0px !important"
        }
      },
      listStyle: "none",
      position: "relative",
      marginTop: "30px",
      "&:before": {
        top: "20px",
        bottom: "0",
        position: "absolute",
        content: '" "',
        width: "3px",
        backgroundColor: "#ff9f43",
        left: "9%",
        marginLeft: "32px",
        opacity: "1"
      }
    },
    timelineSimple: {
      marginTop: "30px",
      padding: "0 0 20px",
      "&:before": {
        left: "5%"
      }
    },
    noPaddingContent: {
      // [theme.breakpoints.down("sm")]: {
      //   "&:before": {
      //    padding: "0px 7px !important"
      //   }
      // },
      padding: "0px 7px !important"
    },
    item: {
      marginBottom: "20px",
      position: "relative",
      display: "flex",
      justifyContent:"center",
   
    },
    timelineBadge: {
      [theme.breakpoints.down("sm")]: {
        left: "4% !important",
        width: "30px",
      height: "30px",
      marginLeft: "-9px !important"
      },
      color: "#FFFFFF",
      width: "50px",
      height: "50px",
      lineHeight: "51px",
      fontSize: "1.4em",
      textAlign: "center",
      position: "absolute",
      top: "2px",
      left: "9%",
      marginLeft: "10px",
      borderTopRightRadius: "50%",
      borderTopLeftRadius: "50%",
      borderBottomRightRadius: "50%",
      borderBottomLeftRadius: "50%"
    },
    timelineSimpleBadge: {
      left: "5%"
    },
    info: {
      backgroundColor: infoColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4)"
    },
    success: {
      backgroundColor: "#ff9f43",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)"
    },
    danger: {
      backgroundColor: dangerColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)"
    },
    warning: {
      backgroundColor: warningColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
    },
    primary: {
      backgroundColor: primaryColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)"
    },
    badgeIcon: {
      [theme.breakpoints.down("sm")]: {
        width: "15px",
        height: "15px",
        marginTop:"-27px"
      },
      width: "24px",
      height: "24px",
      marginTop:"-7px"
    },
    timelinePanel: {
      paddingTop:0,
      [theme.breakpoints.down("sm")]: {
        float: "right !important",
        width: "86% !important",
        paddingRight:"0px"
        // "&:before": {
        //   borderLeftWidth: "0 !important",
        //   borderRightWidth: "15px !important",
        //   left: "-15px !important",
        //   right: "auto !important"
        // },
        // "&:after": {
        //   borderLeftWidth: "0 !important",
        //   borderRightWidth: "14px !important",
        //   left: "-14px !important",
        //   right: "auto !important"
        // }
      },
      width: "45%",
      float: "left",
      padding: "20px",
      marginBottom: "20px",
      position: "relative",
      borderRadius: "6px",
      color: "rgba(0, 0, 0, 0.87)",
      background: "#fff",
      // "&:before": {
      //   position: "absolute",
      //   top: "26px",
      //   right: "-15px",
      //   display: "inline-block",
      //   borderTop: "15px solid transparent",
      //   borderLeft: "15px solid #e4e4e4",
      //   borderRight: "0 solid #e4e4e4",
      //   borderBottom: "15px solid transparent",
      //   content: '" "'
      // },
      // "&:after": {
      //   position: "absolute",
      //   top: "27px",
      //   right: "-14px",
      //   display: "inline-block",
      //   borderTop: "14px solid transparent",
      //   borderLeft: "14px solid #FFFFFF",
      //   borderRight: "0 solid #FFFFFF",
      //   borderBottom: "14px solid transparent",
      //   content: '" "'
      // }
    },
    timelineSimplePanel: {
      width: "86%"
    },
    timelinePanelInverted: {
      [theme.breakpoints.up("sm")]: {
        float: "right",
        backgroundColor: "#FFFFFF",
        // "&:before": {
        //   borderLeftWidth: "0",
        //   borderRightWidth: "15px",
        //   left: "-15px",
        //   right: "auto"
        // },
        // "&:after": {
        //   borderLeftWidth: "0",
        //   borderRightWidth: "14px",
        //   left: "-14px",
        //   right: "auto"
        // }
      }
    },
    timelineHeading: {
      marginBottom: "15px"
    },
    timelineBody: {
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.SMALL,
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "justify",
        color: "#000000",
        marginBottom: 15,
        marginTop: 15,
    },
    timelineFooter: {
      zIndex: "1000",
      position: "relative",
      float: "left"
    },
    footerTitle: {
      color: "#333333",
      fontWeight: "400",
      margin: "10px 0px 0px"
    },
    footerLine: {
      marginBottom: "5px",
      borderTop: "1px solid #b7b7b7",
    },
    tag: {
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.LARGE,
      fontStyle: "normal",
      letterSpacing: 0,
      color: "#5a5a5a",
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    barLight: {
      width: '30%',
      borderTop: "2px solid #b7b7b7",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    center: {
      textAlign: 'center',
    },
    textAction: {
        fontFamily: FONTS.PRIMARY,
        fontSize: FONT_SIZES.LARGE,
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: "#212B36",
        fontWeight: 'bold',
        textTransform: "capitalize"
    },
    textWho: {
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.SMALL,
      fontStyle: "normal",
      letterSpacing: 0,
      textAlign: "left",
      fontWeight: '600',
      color: "#ff9f43"
    },
    textTime: {
      fontFamily: "Muli",
      fontSize: FONT_SIZES.SMALL,
      fontStyle: "normal",
      letterSpacing: 0,
      textAlign: "left",
      color: "#a2a2a2",
      fontWeight: 'bold',
      paddingTop:'3px',
      width:'100%',
    },
    btChitiet: {
      backgroundColor: "#e2ffdb",
      textTransform:'none',
      fontFamily: "Muli",
      fontSize: FONT_SIZES.SMALL,
      fontWeight: "bold",
      fontStyle: "normal",
      letterSpacing: 0,
      textAlign: "left",
      color: "#4ca750",
      height:'25px',
      paddingTop:0,
      paddingBottom:0,
      paddingLeft:5,
      paddingRight:5,
      "&:hover,&:focus": {
          outline: 0,
          boxShadow: "none!important",
      },
    },
    row2: {
      display: 'inline-flex',
      width:'100%',
      justifyContent: 'flex-end',
    },
    textBlock: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.SMALL,
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: 0,
      color: "#a2a2a2",
      paddingRight:"10px !important"
    },
    textTx: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: FONTS.PRIMARY,
      fontSize: FONT_SIZES.SMALL,
      fontWeight: "400",
      fontStyle: "normal",
      letterSpacing: 0,
      color: "#007bff",
    },
    xuatxutag:{
      fontFamily: "Muli",
      fontSize: "25px",
      fontWeight: "700"
    },
  });
  
  export default timelineStyle;
  