export const GET_OVERVIEW = "GET_OVERVIEW"
export const GET_OVERVIEW_SUCCESS = "GET_OVERVIEW_SUCCESS"
export const GET_OVERVIEW_FAILURE = "GET_OVERVIEW_FAILURE"

export const GET_DIARY = "GET_DIARY"
export const GET_DIARY_SUCCESS = "GET_DIARY_SUCCESS"
export const GET_DIARY_FAILURE = "GET_DIARY_FAILURE"

export const GET_DISTRIBUTOR = "GET_DISTRIBUTOR"
export const GET_DISTRIBUTOR_SUCCESS = "GET_DISTRIBUTOR_SUCCESS"
export const GET_DISTRIBUTOR_FAILURE = "GET_DISTRIBUTOR_FAILURE"

export const GET_MANUFACTURER_SUCCESS = "GET_MANUFACTURER_SUCCESS"

export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS"

export const GET_MAP_LOCATION = "GET_MAP_LOCATION"
export const GET_MAP_LOCATION_SUCCESS = "GET_MAP_LOCATION_SUCCESS"
export const GET_MAP_LOCATION_FAILURE = "GET_MAP_LOCATION_FAILURE"