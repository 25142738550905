import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import jsQR from "jsqr";
import styles from "./styles.js"
class Scan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delay: 500,
      result: 'Đưa camera hướng về phía mã QR của sản phẩm',
    }

    this.handleScan = this.handleScan.bind(this);
  }

  async componentDidMount() {
    let OSName = "";
    if(navigator.appVersion.indexOf("iPhone") !== -1){
      OSName = "iOS"
    }
    if(navigator.appVersion.indexOf("iPad") !== -1){
      OSName = "iOS"
    }
   // console.log("OSName", OSName)
    var video = document.createElement("video");
    video.setAttribute('id', "videoStream")
    var canvasElement = document.getElementById("canvas");
    canvasElement.setAttribute("height", "400px")
    canvasElement.setAttribute("width", "400px")
    var canvas = canvasElement.getContext("2d");
    var loadingMessage = document.getElementById("loadingMessage");
    // var outputContainer = document.getElementById("output");
    // var outputMessage = document.getElementById("outputMessage");
    // var outputData = document.getElementById("outputData");

    const drawLine = (begin, end, color) => {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    }
    const tick = () => {
      loadingMessage.innerText = "⌛ Đang tải..."
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        loadingMessage.hidden = true;
        canvasElement.hidden = false;
        //outputContainer.hidden = false;

        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
          drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
          drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
          drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
          // outputMessage.hidden = true;
          // outputData.parentElement.hidden = false;
          // outputData.innerText = code.data;
          handleData(code.data);
        } else {
          // outputMessage.hidden = false;
          // outputData.parentElement.hidden = true;
        }
      }
      requestAnimationFrame(tick);
    }
    const handleData = (result) => {
      if (result) {
        var pathCheck = "";
        if(result.indexOf("profile") !== -1){
          pathCheck = "profile"
        }
        if(result.indexOf("certificate") !== -1){
          pathCheck = "certificate"
        }
        if(result.indexOf("lookup") !== -1){
          pathCheck = "lookup"
        }
        if(result.indexOf("profile"))
        var i = 0;
        var index = 0;
        for (i; i < result.length; i++) {
          if (result[i] === '/') {
            index = i;
          }
        }
        if (index > 0) {
          var finalresult = result.slice(index + 1, result.length);
          if(  pathCheck === "profile"){
            let path = "https://portal.agridential.vn/profile/" + finalresult;
            window.location.replace(path);
          }
          if(  pathCheck === "certificate"){
            let path = "https://portal.agridential.vn/certificate/" + finalresult;
            window.location.replace(path);
          }
          if(  pathCheck === "lookup"){
            let path = "https://portal.agridential.vn/lookup/" + finalresult;
            window.location.replace(path);
          }
        } else {
          let path = "https://portal.agridential.vn/lookup/" + result;
          window.location.replace(path);
        }

      }
    }
    const handleUserMediaSuccess = (stream) => {

      video.srcObject = stream;
      video.play()
      requestAnimationFrame(tick);
    }
    const handleUserMediaError = (error) => {
      console.log("error", error)
    }
    if(OSName !== "iOS"){
 
   
  
      var DEVICES = [];
      var final = null;
  
      navigator.mediaDevices.enumerateDevices()
        .then(function (devices) {
          var arrayLength = devices.length;
          for (var i = 0; i < arrayLength; i++) {
            var tempDevice = devices[i];
            if (tempDevice.kind === "videoinput") {
              DEVICES.push(tempDevice);
              if (tempDevice.facingMode === "environment" || tempDevice.label.indexOf("facing back") >= 0) { final = tempDevice; }
            }
          }
  
          var totalCameras = DEVICES.length;
          if (final == null) {
            final = DEVICES[totalCameras - 1];
          };
          var constraints = {
            audio: false,
            video: {
              deviceId: { exact: final.deviceId }
            }
          };
          //console.log("final", final.deviceId)
          if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
            navigator.getUserMedia(constraints, handleUserMediaSuccess, handleUserMediaError);
          } else {
            navigator.mediaDevices.getUserMedia(constraints)
              .then(function (stream) {
                handleUserMediaSuccess(stream)
              })
              .catch(err => {
                handleUserMediaError(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }else{
       
    var constraints = {
      audio: false,
      video: { facingMode: "environment" } 
    };
   // console.log("final", final.deviceId)
    if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
      navigator.getUserMedia(constraints, handleUserMediaSuccess, handleUserMediaError);
    } else {
      navigator.mediaDevices.getUserMedia(constraints)
        .then(function (stream) {
          handleUserMediaSuccess(stream)
        })
        .catch(err => {
          handleUserMediaError(err)
        })
    }
    }
   
  }

  handleScan(result) {
    if (result) {
      var i = 0;
      var index = 0;
      for (i; i < result.length; i++) {
        if (result[i] === '/') {
          index = i;
        }
      }
      if (index > 0) {
        var finalresult = result.slice(index + 1, result.length);
        this.setState({ finalresult });
        // let path = 'search/'+result;
        let path = "https://portal.agridential.vn/lookup/" + result;
        //this.props.history.push(path);
        window.location.href = path;
      } else {
        this.setState({ result });
        // let path = 'search/'+result;
        let path = "https://portal.agridential.vn/lookup/" + result;
        //this.props.history.push(path);
        window.location.href = path;
      }

    }
  }

  handleError(err) {
    console.error(err)
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <link rel="stylesheet" type="text/css" href="./scan.css" />
        <div className={classes.header}>
          <img alt="logoVBC" src={"https://res.cloudinary.com/agridential/image/upload/v1583393051/images/logo_vbc_bn8cog.png"} className={classes.logo} />
        </div>
        <div style={{ width: "100%", textAlign: "center", display: "flex", flexFlow: "column", alignItems: "center" }}>
          <p className={classes.title1}>Gateway for connecting</p>
          <p className={classes.title2}>to VBC Blockchain ecosystem</p>
          <div id="loadingMessage">Không thể truy cập camera!</div>
         
            <canvas id="canvas" hidden></canvas>
         
          {/* <Button color="success" size="sm" onClick={this.handleRemove}>Remove</Button> */}
          <div className={classes.link}>
            <a href="https://vietnamblockchain.asia/" className={classes.address}>www.vietnamblockchain.asia</a>
          </div>
        </div>


        {/* <div id="output" hidden>
          <div id="outputMessage">Không tìm thấy mã QR!</div>
          <div hidden><b>Dữ liệu:</b> <span id="outputData"></span></div>
        </div> */}

      </div>
    )
  }
}

export default withStyles(styles)(withRouter(Scan));