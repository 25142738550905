import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import strings from 'constants/strings';
import React from 'react';
import Timeline from "../../components/Timeline/TimelineEducation.jsx";
import Loader from "./ContentLoader"
import { connect } from 'react-redux';


class Journey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tx: "",
      loader: true,
    }
    this.data = this.props.data;
  }
  componentDidMount(){
    if(this.props.diary){
      if(this.props.diary.stories){
        if(Array.isArray(this.props.diary.stories)){
          if(this.props.diary.stories.length > 0 || this.props.diary.stories.length === 0){
            this.setState({
              loader: false
            })
          }
        }
        
      }
    }
  }
  componentDidUpdate(preProps){
    if(this.props.diary !== preProps.diary){
      if(this.props.diary){
        if(this.props.diary.stories){
          if(Array.isArray(this.props.diary.stories)){
          if(this.props.diary.stories.length > 0 || this.props.diary.stories.length === 0){
            this.setState({
              loader: false
            })
          }
        }
        }
      }
    }
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  showBlockhainAlert = (tx) => {
    this.setState({ open: true, tx: tx });
  }

  render() {

    return (
      <div>
        {/* <Timeline gln={gln} page={page} total={total} stories={resultList} tag={strings.tracerbility_journey} onConfirm={this.showBlockhainAlert} video={video} /> */}
        {this.state.loader ? 
        <div style={{padding: "50px"}}>
        <Loader/> 
        <Loader/> 
        </div>
        :<Timeline onConfirm={this.showBlockhainAlert}  />}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: '10px', paddingBottom: '70px', paddingTop: '70px', zIndex: 3000 }}
          fullScreen={true}
        >
          <DialogContent style={{ padding: '0px' }}>
            <iframe title="Blockchain" style={{ height: '100vh', width: '100%' }} src={'https://blockchain.agridential.vn/tx/' + this.state.tx} />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button style={{ color: 'white', background: 'black' }} onClick={this.handleClose} color="primary" autoFocus>
              {strings.close}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
	return {
		diary: state.diary
	}
}
export default connect(mapStateToProps, null)(Journey);