import reducer from "reducers";
//import { persistReducer, persistStore } from 'redux-persist';
//import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
//import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "saga/index.js";
let redux = require('redux');
// const persistConfig = {
//     key: 'root',
//     storage: storage,
//     stateReconciler: autoMergeLevel2,
//     timeout: null,
//    };
//const pReducer = persistReducer(persistConfig, reducer);
const sagaMiddleware = createSagaMiddleware()
export const store = redux.createStore(reducer, redux.applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga)
//export const persistor = persistStore(store);

