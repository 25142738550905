import {  Dialog, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.jsx';
import strings from "constants/strings";
import React from "react";
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import style from "./introduceStyle";
import OverviewGroup from "./overviewGroup.jsx";
import Taste from './taste';
import { Feedback } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LINK_API } from 'constants/API';
import { IMG_LOAD } from 'constants/index.js';

const axios = require('axios');

function Introduce(...props){
    const overviewData = props[0].data;
    const classes = props[0].classes;
    const [openDialog, setopenDialog] = React.useState(false)
    const [alert, setalert] = React.useState(null)
    const [feedbackContent, setfeedbackContent] = React.useState("")
    const [multiline, setmultiline] = React.useState("")
    const [noData, setnoData] = React.useState("")
    const [productInfo, setDetail] = React.useState({
        description: "",
        dynamicContent: "",
        infoDetail: [],
        boxInfo: [],
        typeObject: "",
        checkDetail: "",
    })

    const handleOpenDialog = () => {
		setopenDialog(true)
	};
	const handleCloseDialog = () => {
		setopenDialog(false)
    };
    
    const handleChange = (variable) => (event) => {
        if(variable === "feedbackContent"){
            setfeedbackContent(event.target.value)
        }
        else {
            setmultiline(event.target.value)
        }
	};

    const hideAlert = () => {
		setalert(null)
    } 
    
    const sendFeedBack = async () => {
		handleCloseDialog();
		const data = {
			uuidOfProduct: productInfo.idCode,
			message: `${feedbackContent} ${multiline}`,
			email: "test@gmail.com",
			phone: "0342788099"
		};

        setalert(
            <SweetAlert
                // success
                style={{ display: 'block' }}
                title={strings.feedback_processing}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + ' ' + classes.success}
                showConfirm={false}
            >
                <div style={{ textAlign: 'center' }}>
                    <img alt="{name}" src={IMG_LOAD} style={{ height: '100px' }} />
                </div>
            </SweetAlert>
        )

		axios
			.post(LINK_API.SEND_FEEDBACK, data)
			.then(async () => {
                setalert(
                    <SweetAlert
                        success
                        style={{ display: 'block', marginTop: '-270px' }}
                        title={strings.feedback_success}
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnCssClass={classes.button + ' ' + classes.success}
                    />
                )
			})
			.catch(() => {
                setalert(
                    <SweetAlert
                        error
                        style={{ display: 'block' }}
                        title={strings.feedback_failed}
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnCssClass={classes.button + ' ' + classes.success}
                    />
                )
			});
	}

    const handleLoadingInfo = data => {
        if(data.data.productInfo.description || data.detailInfo || data.dynamicContent !== "<p></p>\n" || data.boxInfo.length > 0 || data.typeObject === "FRUIT"){
            setDetail({
                description: data.data.productInfo.description ? data.data.productInfo.description : "",
                infoDetail: data.detailInfo ? data.detailInfo : {},
                dynamicContent: data.dynamicContent,
                checkGroupDetail: data.checkGroupDetail,
                boxInfo: data.boxInfo,
                typeObject: data.data.type,
                isSampleGLN: data.sample,
                idCode: data.showId,
            })
        }else{
            setnoData(true)
        }
       
    }
    React.useEffect(()=>{
        handleLoadingInfo(overviewData)
    }, [overviewData])

    
    return(
        <div style={{ textAlign: 'justify', display: "flex", justifyContent: "center", width: "100%", flexFlow: "row" , marginTop: "20px"}}>
            {!noData ? 
        <GridItem xs={12} md={10} sm={12} style={{marginBottom: "25px"}} >
        <div className="flexcenter" style={{flexFlow: "column"}}>
         
              <Typography className={classes.xuatxutag}>{strings.introduction_product}</Typography>
              <hr className="LineBreakDes"></hr>
        
            </div>
            {productInfo.description ? (
                <div>
                    <p className="detailtitle">{strings.introduction_product}</p>
                    {productInfo.description.split('\n').map((item) => {
                        return (
                            <Typography className="textdes">
                                {item}
                            </Typography>
                        );
                    })}
                    <hr className="LineBreakDes" />
                </div>
            ) : null}
            {
               productInfo.checkGroupDetail === 0 ? null :
               productInfo.checkGroupDetail === 1 ? (
                        <div>
                            {Object.keys(productInfo.infoDetail).map((overview, index) => (
                                <div key={index}>
                                    <p className="detailtitle">{overview}</p>
                                    <Typography className="textdes" style={{ marginTop: '10px' }}>
                                        {productInfo.infoDetail[overview]}
                                    </Typography>
                                    {productInfo.typeObject === "FRUIT" && index === (Object.keys(productInfo.infoDetail).length - 1) ?
                                    <div className={classes.paddingTaste}>
                                                    <Taste />
                                                    </div>
                                              : null}
                                    {(index !== (Object.keys(productInfo.infoDetail).length- 1)) ? <hr className="LineBreakDes" /> : null}
                                </div>
                            ))}
                        </div>
                    ) : productInfo.checkGroupDetail === 2 ?
                            <OverviewGroup overview={productInfo.infoDetail} /> : (
                                <div>
                                    {productInfo.infoDetail.map((overview, index) => (
                                        <div key={index}>
                                            <p className="detailtitle">{overview.key}</p>
                                            <Typography className="textdes" style={{ marginTop: '10px' }}>
                                                {overview.value}
                                            </Typography>
                                            {index !== productInfo.infoDetail.length - 1 ? <hr className="LineBreakDes" /> : null}
                                        </div>
                                    ))}
                                        </div>
                                    )
            }
            {
                productInfo.dynamicContent && productInfo.dynamicContent !== "<p></p>\n" ?
                    <div>
                        {renderHTML(productInfo.dynamicContent)}
                        <hr className="LineBreakDes" />
                    </div>
                    :
                    null
            }
            {productInfo.boxInfo.map((box, indexBox) => (
                <div key={indexBox}>
                    <p className="detailtitle">{box.key}</p>
                    <Typography className="textdes" style={{ marginTop: '10px' }}>
                        {box.value}
                    </Typography>

                    <hr className="LineBreakDes" />
                </div>
            ))}

            {((productInfo.typeObject === "PRODUCT" && !productInfo.isSampleGLN) || (productInfo.typeObject === "OBJECT" && !productInfo.isSampleGLN)) ?
                <GridContainer style={{ display: "flex", justifyContent: "center", borderRadius: "5px" }}>
                    <GridItem xs={12} md={10} className={classes.textStart} style={{ justifyContent: "left", display: "contents" }}>
                        <div style={{ width: "100%", textAlign: "start" }}>
                            <p className={classes.detailtitle}>{strings.product_feedback}</p>
                        </div>

                    </GridItem>
                    <GridItem xs={12} md={10} >
                        <GridContainer style={{ display: "flex", justifyContent: "center", margin: "0" }}>
                            <GridItem xs={4} sm={3} md={3} lg={3} style={{ display: "flex", justifyContent: "center" }} >
                                <div className={classes.paddingEmoji}>
                                    <img src={"https://res.cloudinary.com/agridential/image/upload/v1590045744/images/smiling-face_x5dfx3.png"} alt="smile"
                                        id="imageSmile"
                                        onClick={() => handleOpenDialog("Hài lòng")}
                                        className="iconHover" />
                                </div>
                            </GridItem>

                            <GridItem xs={4} sm={3} md={3} lg={3} style={{ display: "flex", justifyContent: "center" }}>
                                <div className={classes.paddingEmoji}>
                                    <img src={"https://res.cloudinary.com/agridential/image/upload/v1590045744/images/expressionless_-face_fqlsqm.png"} alt="normal"
                                        id="imageNormal"
                                        onClick={() => handleOpenDialog("Bình thường")}
                                        className="iconHover" />
                                </div>
                            </GridItem>

                            <GridItem xs={4} sm={3} md={3} lg={3} style={{ display: "flex", justifyContent: "center" }}>
                                <div className={classes.paddingEmoji}>
                                    <img src={"https://res.cloudinary.com/agridential/image/upload/v1590045744/images/angry-face_u78wne.png"} alt="angry"
                                        id="imageAngry"
                                        onClick={() => handleOpenDialog("Không hài lòng")}
                                        className="iconHover" />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer> : null}
        </GridItem>
        :(
            <GridItem xs={12} className="flexcenter" style={{display: "flex", flexFlow: "column"}}>
            <div style={{width: "100%", textAlign: "center"}}>
            <img alt="notfound" src="https://res.cloudinary.com/agridential/image/upload/c_scale,w_300/v1589864439/images/No_data_w9gh2h.gif" />
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
            <p className={classes.noData}>{strings.noInfo}</p>
            </div>
          </GridItem>
        )}

    <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
            classes: {
                root: classes.paper
            }
        }}
        >
        <Card>
            <CardHeader color="success" icon>
                <CardIcon color="success">
                    <Feedback />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{strings.product_feedback}</h4>
            </CardHeader>
            <CardBody style={{ textAlign: 'justify' }}>
                <h6 className={classes.textField}> {strings.product_feedback_description}</h6>
                <TextField
                    id="standard-multiline-flexible"
                    label={strings.product_feedback_content}
                    value={feedbackContent}
                    onChange={handleChange('feedbackContent')}
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    id="standard-multiline-flexible"
                    label={strings.product_feedback_content_more}
                    multiline
                    rowsMax="4"
                    value={multiline}
                    onChange={handleChange('multiline')}
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                />
                <Button color="success" onClick={sendFeedBack}>
                    {strings.send}
                </Button>
            </CardBody>
        </Card>
        </Dialog>
        {alert}
    </div>
    )
}

const mapStateToProps = (state) => {
	return {
		data: state.overview
	}
}

export default withStyles(style)(connect(mapStateToProps, null)(Introduce))