import React from 'react';
// import './index.css';
import { render } from 'react-dom'
import {  store } from 'store/store.js';
import App from './App'
import cookies from 'react-cookies';

import * as serviceWorker from './serviceWorker';

window.addEventListener("load", (ev) => 
{  
    ev.preventDefault();
    if(cookies.load('save')==="false") { 
        cookies.remove('token',{ path: '/' });
    }
});

render(<App store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();