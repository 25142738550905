const HOST = process.env.REACT_APP_ENV === 'dev' ?  'https://portal.agridential.vn' : 'https://portal.agridential.vn' ;
const axios = require('axios');

const LINK_API = {
    REGISTER: HOST+'/api/user/register/',
    SIGN_IN: HOST+'/api/user/authen/',
    SIGN_OUT: HOST+'/api/user/logout',
    CHANGE_PWD: HOST+'/api/user/changepassword',
    EDIT_PROFILE:  HOST+'/api/user/editprofile',
    CREATE_PRODUCT: HOST+'/api/products',
    GET_ALL_PRODUCTS: HOST+'/api/products',
    STOP_PRODUCTION: HOST+'/api/products/stopProduction',
    GET_ONE_PRODUCT_BY_INDEX: HOST+'/api/products',
    GET_PRODUCE_DIARY: HOST+'/api/cooperatives/diary',
    ADD_NEW_FARM: HOST+'/api/cooperatives/farms',
    GET_FARM_LIST: HOST+'/api/cooperatives/farms',
    GET_FARM_INFO: HOST+'/api/farms',
    ADD_DIARY: HOST+'/api/farms/diary',
    CREATE_TREE: HOST+'/api/trees',
    GET_TREE_LIST: HOST+'/api/trees',
    GET_TREE: HOST+'/api/trees/info',
    GET_TREE_NEW: HOST + '/api/v1/diaries', // count 1
    CREATE_STAMP: HOST+'/api/stamps',
    GET_STAMP_HISTORY: HOST+'/api/stamps',
    SUBSCRIBE: HOST+'/api/user/subscribe',
    VERIFY_TOKEN: HOST + '/api/token',
    SEND_FEEDBACK: HOST + '/api/v1/feedback',
    GET_DISTRIBUTOR: HOST + '/api/v1/diaries/distributors',
    GET_PRODUCT: HOST + '/api/v1/ecommerce?language=vi',
    GET_LOCATION: HOST + '/api/v1/statistics/recordaccessionhistory'
}   

const ERR_CODE = {
    SUCCESS:1,
    ERROR:0,
}

const register = (data) => {
    axios.post(HOST + LINK_API.REGISTER, data)
    .then(response => {
        return {
            code:response.errorCode,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const signIn = (data) => {
    axios.post(HOST + LINK_API.SIGN_IN, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const createProduct = (data) => {
    axios.post(HOST + LINK_API.CREATE_PRODUCT, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getAllProduct = (gln) => {
    axios.get(HOST + LINK_API.GET_ALL_PRODUCTS, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getOneProductByIndex = (index) => {
    axios.get(HOST + LINK_API.GET_ONE_PRODUCT_BY_INDEX, {
        params: {
            index
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getDiary = (gln) => {
    axios.get(HOST + LINK_API.GET_DIARY, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const addNewFarm = (data) => {
    axios.post(HOST + LINK_API.ADD_NEW_FARM, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getFarmList = (gln) => {
    axios.get(HOST + LINK_API.GET_FARM_LIST, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getFarmInfo = (gln) => {
    axios.get(HOST + LINK_API.GET_FARM_INFO, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const createTree = (data) => {
    axios.post(HOST + LINK_API.CREAT_TREE, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getTreeList = (gln) => {
    axios.get(HOST + LINK_API.GET_TREE_LIST, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const createStamp = (data) => {
    axios.post(HOST + LINK_API.CREATE_STAMP, data)
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

const getStampHistory = (gln) => {
    axios.get(HOST + LINK_API.GET_STAMP_HISTORY, {
        params: {
          gln
        }
      })
    .then(response => {
        return {
            code:ERR_CODE.SUCCESS,
            content: response.request.response
        }
    })
    .catch(err => {
        return {
            code:ERR_CODE.ERROR,
            content: err
        }
    });
}

export {
    register,
    signIn,
    createProduct,
    getAllProduct,
    getOneProductByIndex,
    getDiary,
    addNewFarm,
    getFarmList,
    getFarmInfo,
    createTree,
    getTreeList,
    createStamp,
    getStampHistory,
    LINK_API,
};

export default {
    register,
    signIn,
    createProduct,
    getAllProduct,
    getOneProductByIndex,
    getDiary,
    addNewFarm,
    getFarmList,
    getFarmInfo,
    createTree,
    getTreeList,
    createStamp,
    getStampHistory,
    LINK_API,
};