import React from "react"
import { connect } from 'react-redux';
import ReactPannellum from "react-pannellum";
// let Viewer;
// const changeSceneSecond = (name) => {
// 	Viewer.loadScene(name);	
// }

function Image360(...props) {
	const listImage= props[0].overview.data.objectInfo.images360 ? props[0].overview.data.objectInfo.images360 : [];
	const [stateImage, setImage] = React.useState("");
	//console.log("listImage",props[0])
    // const handlePano = () => {
	// 	if(listImage.length === 1){
	// 		Viewer = window.pannellum.viewer('panorama', {
	// 			"type": "equirectangular",
	// 			"default": {
	// 				"firstScene": "first"
	// 			},
	// 			"scenes": {
	// 				"first": {
	// 					"panorama": listImage[0].secure_url,
	// 					"hotSpots": []
	// 				}
	// 			},
	// 			"autoLoad": true,
	// 			"showControls": false,
	// 			"orientationOnByDefault": true,
	// 		});
	// 	}
	// 	if(listImage.length === 2){
	// 		Viewer = window.pannellum.viewer('panorama', {
	// 			"type": "equirectangular",
	// 			"default": {
	// 				"firstScene": "first"
	// 			},
	// 			"scenes": {
	// 				"first": {
	// 					"panorama": listImage[0].secure_url,
	// 					"hotSpots": []
	// 				},
	// 				"second": {
	// 					"panorama": listImage[1].secure_url,
	// 					"hotSpots": []
	// 				}
	// 			},
	// 			"autoLoad": true,
	// 			"showControls": false,
	// 			"orientationOnByDefault": true,
	// 		});
	// 	}
	// 	if(listImage.length === 3){
	// 		Viewer = window.pannellum.viewer('panorama', {
	// 			"type": "equirectangular",
	// 			"default": {
	// 				"firstScene": "first"
	// 			},
	// 			"scenes": {
	// 				"first": {
	// 					"panorama": listImage[0].secure_url,
	// 					"hotSpots": []
	// 				},
	// 				"second": {
	// 					"panorama": listImage[1].secure_url,
	// 					"hotSpots": []
	// 				},
	// 				"third": {
	// 					"panorama": listImage[2].secure_url,
	// 					"hotSpots": [],
	// 				}
	
	// 			},
	// 			"autoLoad": true,
	// 			"showControls": false,
	// 			"orientationOnByDefault": true,
	// 		});
	// 	}
	// 	if(listImage.length !== 0){
	// 		document.getElementById('pan-up').addEventListener('click', function () {
	// 			Viewer.setPitch(Viewer.getPitch() + 10);
	// 		});
	// 		document.getElementById('pan-down').addEventListener('click', function () {
	// 			Viewer.setPitch(Viewer.getPitch() - 10);
	// 		});
	// 		document.getElementById('pan-left').addEventListener('click', function () {
	// 			Viewer.setYaw(Viewer.getYaw() - 10);
	// 		});
	// 		document.getElementById('pan-right').addEventListener('click', function () {
	// 			Viewer.setYaw(Viewer.getYaw() + 10);
	// 		});
	// 		if(listImage.length > 0){
	// 			document.getElementById('image-1').addEventListener('click', () => changeSceneSecond("first"));
	// 		}
	// 		if(listImage.length > 1){
	// 			document.getElementById('image-2').addEventListener('click', () => changeSceneSecond("second"));
	// 		}
	// 		if(listImage.length > 2){
	// 			document.getElementById('image-3').addEventListener('click', () => changeSceneSecond("third"));
	// 		}
	// 		document.getElementById('zoom-out').addEventListener('click', function () {
	// 			Viewer.setHfov(Viewer.getHfov() + 10);
	// 		});
	// 		document.getElementById('zoom-in').addEventListener('click', function () {
	// 			Viewer.setHfov(Viewer.getHfov() - 10);
	// 		});
	// 		document.getElementById('fullscreen').addEventListener('click', function () {
	// 			Viewer.toggleFullscreen();
	// 		});
	// 	}
	// }
	React.useEffect(()=>{
		if(listImage.length > 0){
			setImage(listImage[0].secure_url)
		}
	},[listImage])
	const config = {
		autoLoad: true,
	  };
    return (
        <div style={{ width: "100%" }}>
            {/* <div id="panorama">
                <div id="controls">
                    <div className="ctrl" id="pan-up">&#9650;</div>
                    <div className="ctrl" id="pan-down">&#9660;</div>
                    <div className="ctrl" id="pan-left">&#9664;</div>
                    <div className="ctrl" id="pan-right">&#9654;</div>
                    {listImage.length > 0 ? <div className="ctrl" id="image-1">1</div> :null}
					{listImage.length > 1 ? <div className="ctrl" id="image-2">2</div> :null}
                    {listImage.length > 2 ? <div className="ctrl" id="image-3">3</div> :null}
                    <div className="ctrl" id="zoom-in">&#43;</div>
                    <div className="ctrl" id="zoom-out">&minus;</div>
                    <div className="ctrl" id="fullscreen">&#x2922;</div>
                </div>
            </div> */}
 		{stateImage ? <ReactPannellum
          id="1"
          sceneId="firstScene"
		  imageSource={stateImage}
		  config={config}
         style={{
			
				width: "100%",
				height: "400px",
				background: "#000000"
			  
		 }}
        />:null}
        </div>
    )
}
const mapStateToProps = (state) => {
	return {
		overview: state.overview
	}
}
export default connect(mapStateToProps, null)(Image360)