import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import strings from 'constants/strings';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import "../../components/ImageSlider/stylecss.css";
import "../../components/ImageSlider/stylescss.scss";
// import kimchi from "assets/img/products/kimchi.png";
import styles from './overviewStyle';

const allStyle = {
	...styles,
	hotSpotBlack: {
		backgroundColor: "black !important",
		borderRadius: "50%"
	}
}
class Overview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			open: false,
			tx: '',
			message: '',
			status: "",
			tomato: false,
			image: require('../../../../assets/img/products/cachuabivang.png'),
			shirts: "",
			text: ' ',
			selectedShirt: "",
			uuid: "",
			background: { rgb: { r: 255, g: 255, b: 255 } },
			gs1State: '',
			listLogo: [],
			checkBox: "",
			titleId: "",
			activation_date: "",
			date: "",
			isSampleGLN: false,
			checkGroupDetail: 0,
			infoDetail: "",
			boxInfo: [],
			statusCT: 1,

			//Panorama Image
			panoImage: "https://res.cloudinary.com/agridential/image/upload/c_scale,w_3952/v1581301384/Portal/20200202_KhuBietthuQuan2_A_u6eo23.jpg",
			image1: "https://res.cloudinary.com/agridential/image/upload/c_scale,w_3952/v1581301384/Portal/20200202_KhuBietthuQuan2_A_u6eo23.jpg",
			image2: "https://res.cloudinary.com/agridential/image/upload/c_scale,w_3991/v1581303711/Portal/20200202_KhuBietthuQuan2_B_xj93sr.jpg",
			checkPanorama: false,
			changeImage: false,
			yaw: 340,
			pitch: 25,
			pitchInfo: 40,
			data: {}
		};
		this.data = this.props.data;
		this.handleLoadingInfo = this.handleLoadingInfo.bind(this);
	}

	handleClose = () => {
		this.setState({ open: false });
	};

	async componentDidMount() {
		// console.log("props",this.props);
		if (this.props.data) {
			this.handleLoadingInfo(this.props.data)
		}
	}

	async handleLoadingInfo(data) {
		this.setState({
			data: data.data

		})
	}

	showBlockhainAlert = (tx) => {
		this.setState({ open: true, tx: tx });
	}

	handleBtCt = () => {
		if (this.state.statusCT) {
			document.getElementById('btCt').innerHTML = strings.view_detail_overview;
			document.getElementById('detailProduct').style = 'display: none; width:100%';
			this.setState({
				statusCT: 0
			});
		} else {
			document.getElementById('btCt').innerHTML = strings.collapse;
			document.getElementById('detailProduct').style = 'display: flex;  justify-content: center ; width: 100%; flex-flow: row; text-align: justify;';
			this.setState({
				statusCT: 1
			});
		}
	};

	handleRentOpen = () => {
		this.setState({ rent: true });
	};

	handleRentClose = () => {
		this.setState({ rent: false });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	showBlockhainAlert = (tx) => {
		this.setState({ open: true, tx: tx });
	};
	selectShirt(thumb) {
		this.setState({ selectedShirt: thumb }, (_) => this.forceUpdate());
	}

	render() {
		// console.log(Sample_Certificate_Info);
		const { classes } = this.props;
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<link rel="stylesheet" type="text/css" href="./css/pano.css" />
				<GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }} className={classes.noPadding}>
					<Card style={{ padding: '0px 20px' }}>
						<CardBody>
							<GridContainer
								style={{
									paddingTop: '10px',
									paddingBottom: '10px',
									display: 'flex',
									justifyContent: 'center',

								}}
							>
								{
									this.state.data.productInfo && (this.state.data.productInfo.image ||  this.state.data.productInfo.image !== "")?(
										<div className="styleBackgroundOverview"  ><img style={{ width: '400px' }} src={this.state.data.productInfo.image} alt="product" /></div>

									):null
								}


								<GridItem xs={12} md={12} sm={12} id="text" className={classes.textCenter}>
									<Typography gutterBottom variant='h1' className="pructName">
										{this.state.data.productInfo ? this.state.data.productInfo.name : null}
									</Typography>
								</GridItem>
							
								<GridItem xs={12} id="text" className={classes.textCenter}>

									<div>

										<Typography variant='h1' className={classes.textLight}>
											{strings.ID_code}
										</Typography>
										<div
											style={{
												display: 'flex',
												flexFlow: 'row',
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											<Typography style={{wordBreak: "break-all"}} variant='h1' className={classes.textCode}>
												{this.state.data.productInfo?this.state.data.productInfo.gtin:null}
											</Typography>
										</div>
									</div>

								</GridItem>
								{
									this.state.data.productInfo ? <GridItem xs={12}>

										<Typography
											variant='h1'
											className={classes.textLight}
											style={{ marginTop: '10px' }}
										>
											{strings.activation_date}
										</Typography>
										<Typography variant='h1' className={classes.textCode}>
											{moment(this.state.data.productInfo.create_date).format('DD/MM/YYYY')}
										</Typography>
									</GridItem> : null
								}

								<div id="detailProduct" style={{ textAlign: 'justify', display: "flex", justifyContent: "center", width: "100%", flexFlow: "row" }}>
									<GridItem xs={12} md={10} sm={12} >
										{this.state.data.productInfo && this.state.data.productInfo.description? (
											<div>
												<p className="detailtitle">{strings.introduction_product}</p>
												{this.state.data.productInfo.description.split('\n').map((item, i) => {
													return (
														<Typography className="textdes">
															{item}
														</Typography>
													);
												})}
												<hr className="LineBreakDes" />
											</div>
										) : null}

									</GridItem>
								</div>
								<div id="detailProduct" style={{ textAlign: 'justify', display: "flex", justifyContent: "center", width: "100%", flexFlow: "row" }}>
									<GridItem xs={12} md={10} sm={12} >
										{this.state.data.productInfo ? (
											<div>
												<p className="detailtitle">{strings.blockchain_info}</p>
												<Typography className="textdesTx" onClick={() => this.showBlockhainAlert(this.state.data.productInfo.blockchain)}> {this.state.data.productInfo.blockchain} </Typography>
												<hr className="LineBreakDes" />
											</div>
										) : null}

									</GridItem>
								</div>
								<div id="detailProduct" style={{ textAlign: 'justify', display: "flex", justifyContent: "center", width: "100%", flexFlow: "row" }}>
									<GridItem xs={12} md={10} sm={12} >
										{this.state.data.productInfo && this.state.data.productInfo.options? (
											<div>
												{this.state.data.productInfo.options.map((item, i) => {
													return (
														<div>
														<p className="detailtitle">{item.key}</p>
														{item.value.map((itemx, j)=> {
															return(
																<Typography className="textdes">
															{itemx}
														</Typography>
															)
														})}
														<hr className="LineBreakDes" />
														</div>
													);
												})}
											</div>
										) : null}

									</GridItem>
								</div>
							</GridContainer>
							{this.state.data.productInfo?(
								<Dialog
								open={this.state.open}
								onClose={this.handleClose}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								style={{ padding: '10px', paddingBottom: '70px', paddingTop: '70px', zIndex: 3000 }}
								fullScreen={true}
							>
								<DialogContent style={{ padding: '0px' }}>
									<iframe title="Blockchain" style={{ height: '100vh', width: '100%' }} src={'https://blockchain.agridential.vn/tx/' + this.state.data.productInfo.blockchain} />
								</DialogContent>
								<DialogActions style={{ justifyContent: 'center' }}>
									<Button style={{ color: 'white', background: 'black' }} onClick={this.handleClose} color="primary" autoFocus>
										{strings.close}
									</Button>
								</DialogActions>
							</Dialog>
							):null}
							
							{/* <GridContainer justify="center">

								<Button id="btCt" size="sm" color="success" onClick={this.handleBtCt} className="btnfbEdu">
									{strings.collapse}
								</Button>
							</GridContainer> */}
						</CardBody>
					</Card>

				</GridItem>
				{this.state.alert}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		data: state.overview,
		manufacturer: state.manufacturer
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(allStyle)(Overview));
