import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { store } from 'store/store.js';
import LookupPageV2 from './layouts/lookupPagev2';
import ScanPage from './layouts/ScanPage';
//import { PersistGate } from 'redux-persist/lib/integration/react';
class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        {/* <PersistGate persistor={persistor}> */}
          <Router>
            <Switch>
              <Route exact path="/scan" component={withRouter(ScanPage)} />
              {/* <Route exact path="/home" component={withRouter(Homepage)} /> */}
              {/* <Route exact path="/lookup/:id" component={withRouter(LookupPageV2)} />*/}
              <Route exact path="/profile/:id" component={withRouter(LookupPageV2)} /> 
              <Route exact path="/cert/:id" component={withRouter(LookupPageV2)} />
              <Route exact path="/general/:id" component={withRouter(LookupPageV2)} />
              {/* <Route path="/lookupv2/:id" component={withRouter(LookupPageV2)} /> */}
              <Route path='/' component={withRouter(ScanPage)} />
            </Switch>
          </Router>
        {/* </PersistGate> */}
      </Provider>
    )
  }
}
App.propTypes = {
  store: PropTypes.object.isRequired
}

export default hot(App);